import React, { useState, useCallback, useEffect, useRef } from 'react';
import {X, Check, Play, Clock, Activity, XIcon} from 'lucide-react';
import '../../css/WebImageViewer.css';
import { getImage, setImage } from '../../utils/idbHelpers';
import {useProject} from "../../utils/ProjectContext";

const WebImageViewer = ({
                            images = [],
                            onClose,
                            dataName = '',
                            onFeedback,
                            projectData,
                            projectId,
                            token,
                            BASE_URL,
                            planColor,
                        }) => {
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [activityStepUrls, setActivityStepUrls] = useState({});
    const stripRef = useRef(null);
    const [selectedActivities, setSelectedActivities] = useState({});
    const [shownActivityId, setShownActivityId] = useState(null);
    const [completedSteps, setCompletedSteps] = useState(new Set());
    const [imageSize, setImageSize] = useState(80); // Default max size
    const [columns, setColumns] = useState(1);
    const {showInputs, setShowInputs} = useProject()
    const [gridLayout, setGridLayout] = useState({
        columns: 1,
        imageSize: 80
    });

    const updateGridStyles = () => {
        const container = stripRef.current;
        if (container) {
            const totalImages = images.length;
            const maxImageWidth = 140; // Set maximum image width
            container.style.gridTemplateColumns = `repeat(${totalImages}, minmax(0, ${maxImageWidth}px))`;
        }
    };

    const calculateImageSize = () => {
        if (stripRef.current) {
            const containerWidth = stripRef.current.offsetWidth; // Get container width
            const gap = 16; // Gap between images
            const maxImageWidth = 150; // Maximum size for an image
            const minImageWidth = 50; // Minimum size for an image
            const padding = 16; // Total padding for the grid

            // Calculate the number of columns based on max image size
            const availableWidth = containerWidth - padding;
            let columns = Math.floor((availableWidth + gap) / (maxImageWidth + gap));
            columns = Math.max(1, Math.min(columns, images.length)); // Ensure at least 1 column and no more than the number of images

            // Calculate dynamic image size based on columns
            const imageWidth = (availableWidth - (columns - 1) * gap) / columns;

            // Constrain image width between min and max values
            const constrainedImageWidth = Math.min(Math.max(imageWidth, minImageWidth), maxImageWidth);


            // Update state
            setImageSize(constrainedImageWidth);
            setColumns(columns);
        }
    };
    const calculateLayout = () => {
        if (!stripRef.current) return;

        const container = stripRef.current;
        const containerWidth = container.offsetWidth;
        const minImageSize = 150;
        const maxImageSize = 180;
        const gap = 16;

        const columnsBasedOnMin = Math.floor((containerWidth + gap) / (minImageSize + gap));
        const columnsBasedOnMax = Math.ceil((containerWidth + gap) / (maxImageSize + gap));

        let columns = Math.max(1, Math.min(columnsBasedOnMin, images.length));
        columns = Math.max(columns, columnsBasedOnMax);

        const availableWidth = containerWidth - (gap * (columns - 1));
        const imageSize = Math.floor(availableWidth / columns);

        setGridLayout({

            imageSize: Math.min(Math.max(imageSize, minImageSize), maxImageSize)
        });
        console.log('Container width:', container.offsetWidth);
    };




    useEffect(() => {
        // Trigger layout calculation

        calculateImageSize();
        calculateLayout();
        updateGridStyles();


    }, [images, stripRef, shownActivityId]);




    // Load activity step images
    useEffect(() => {
        const fetchActivityStepImages = async () => {
            const imageIds = Object.keys(selectedActivities);
            for (const imageId of imageIds) {
                const activity = selectedActivities[imageId];
                if (activity?.steps) {
                    const fetchedImages = await Promise.all(
                        activity.steps.map(async (step) => {
                            const imageDetails = projectData.images.find((img) => img._id === step.imageId);
                            if (imageDetails) {
                                let cachedImage = await getImage(projectId, imageDetails.name);
                                if (cachedImage) {
                                    return {
                                        imageId: step.imageId,
                                        url: cachedImage,
                                        description: step.description,
                                        order: step.order,
                                    };
                                } else {
                                    try {
                                        const response = await fetch(
                                            `${BASE_URL}/project/${projectId}/image/${imageDetails.name}`,
                                            {
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                },
                                            }
                                        );
                                        const data = await response.json();
                                        await setImage(projectId, imageDetails.name, data.imageUrl);
                                        return {
                                            imageId: step.imageId,
                                            url: data.imageUrl,
                                            description: step.description,
                                            order: step.order,
                                        };
                                    } catch (error) {
                                        console.error('Error fetching image:', error);
                                        return null;
                                    }
                                }
                            } else {
                                console.error(`Image with id ${step.imageId} not found`);
                                return null;
                            }
                        })
                    );

                    const urlsMap = fetchedImages
                        .filter((img) => img !== null)
                        .reduce((acc, img) => {
                            acc[img.imageId] = img;
                            return acc;
                        }, {});

                    setActivityStepUrls((prevUrls) => ({
                        ...prevUrls,
                        [imageId]: urlsMap,
                    }));
                }
            }
        };

        if (Object.keys(selectedActivities).length > 0) {
            fetchActivityStepImages();
        }
    }, [selectedActivities, projectId, token, BASE_URL, projectData.images]);


    useEffect(() => {
        calculateImageSize();
        window.addEventListener('resize', calculateImageSize); // Recalculate on resize
        return () => window.removeEventListener('resize', calculateImageSize);
    }, [images]);


    useEffect(() => {
        updateGridStyles();
        window.addEventListener('resize', updateGridStyles);
        return () => window.removeEventListener('resize', updateGridStyles);
    }, [images]);



    const handleImageClick = useCallback((image) => {
        setSelectedIds((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(image.id)) {
                newSet.delete(image.id);
            } else {
                newSet.add(image.id);
            }
            return newSet;
        });
    }, []);

    const handleNameClick = useCallback((e, image) => {
        e.stopPropagation(); // Prevent triggering image selection
        if (image.activityId) {
            if (shownActivityId === image.id) {
                // If clicking the same activity, hide it
                setShownActivityId(null);
                setSelectedActivities(prevActivities => {
                    const newActivities = { ...prevActivities };
                    delete newActivities[image.id];
                    return newActivities;
                });
                setActivityStepUrls(prevUrls => {
                    const newUrls = { ...prevUrls };
                    delete newUrls[image.id];
                    return newUrls;
                });
            } else {
                // Show new activity and hide previous one
                const activity = projectData.activities?.find(a => a._id === image.activityId);
                if (activity) {
                    // Clear previous activity if exists
                    if (shownActivityId) {
                        setSelectedActivities(prevActivities => {
                            const newActivities = { ...prevActivities };
                            delete newActivities[shownActivityId];
                            return newActivities;
                        });
                        setActivityStepUrls(prevUrls => {
                            const newUrls = { ...prevUrls };
                            delete newUrls[shownActivityId];
                            return newUrls;
                        });
                    }

                    // Set new activity
                    setShownActivityId(image.id);
                    setSelectedActivity(activity);
                    setSelectedActivities(prevActivities => ({
                        ...prevActivities,
                        [image.id]: activity,
                    }));
                }
            }
        }
    }, [projectData.activities, shownActivityId]);

    const handleStepClick = useCallback((stepId) => {
        setCompletedSteps(prev => {
            const newSet = new Set(prev);
            if (newSet.has(stepId)) {
                newSet.delete(stepId);
            } else {
                newSet.add(stepId);
            }
            return newSet;
        });
    }, []);

    return (
        <div className="viewer-overlay">
            <div className="viewer-header" style={{backgroundColor: planColor}}>
                <h2 className="viewer-title">{dataName}</h2>
                <div className="viewer-controls">
                    <button className="viewer-button" onClick={onClose} title="Close Viewer">
                        <text>x</text>
                    </button>
                </div>
            </div>

            <div className="viewer-content">
                <div
                    className="viewer-grid"
                    ref={stripRef}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${gridLayout.columns}, minmax(150px, 1fr))`,
                        gap: '16px',
                        padding: '16px',
                        justifyContent: 'center',
                    }}
                >
                    {images.map((image) => (
                        <div key={image.id} className="viewer-column" >
                            <div
                                className={`image-card ${selectedIds.has(image.id) ? 'selected' : ''} ${shownActivityId === image.id ? 'active' : ''}`}
                                style={{
                                    width: '100%',
                                    maxWidth: `${gridLayout.imageSize}px`
                                }}
                            >
                                <div
                                    className={`image-wrapper ${selectedIds.has(image.id) ? 'selected' : ''}`}
                                    onClick={() => handleImageClick(image)}
                                    style={{
                                        width: '100%',
                                        paddingBottom: '100%', // Create a square aspect ratio
                                        position: 'relative'
                                    }}
                                >
                                    <img
                                        src={image.url}
                                        alt={image.name}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <div className="image-overlay">
                                        {selectedIds.has(image.id) && <Check className="checkmark" size={48} />}
                                    </div>
                                </div>
                                <div
                                    className={`nameandsuch ${image.activityId ? 'has-activity' : ''} ${shownActivityId === image.id ? 'active' : ''}`}
                                    onClick={(e) => handleNameClick(e, image)}
                                >
                                    <div className="image-name">{image.name}</div>
                                    {image.activityId && (
                                        <div className="activity-info" title="Click to view activity">
                                            <Activity size={16} />
                                            <span className="activity-info-name">
                                                {projectData.activities?.find(a => a._id === image.activityId)?.name || 'Activity'}
                                            </span>
                                        </div>
                                    )}
                                    {!image.activityId && (
                                        <div className="activity-info" title="Click to view activity">
                                            <Activity size={16} />
                                            <span className="activity-info-name">
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Activity Steps - now uses shownActivityId to determine visibility */}
                            {shownActivityId === image.id && image.activityId && (
                                <>
                                    <div className="activity-header" style={{ backgroundColor: selectedActivities[image.id]?.color }}>
                                        <div className="activity-row">
                                            <h3 className="activity-title">
                                                {selectedActivities[image.id]?.name || 'Activity Steps'}
                                            </h3>

                                            {showInputs && (
                                                <div className="activity-duration">
                                                    <Clock size={8}/>
                                                    <span>&nbsp;{selectedActivities[image.id]?.duration} min</span>
                                                </div>
                                            )}
                                        </div>
                                        {showInputs && selectedActivities[image.id]?.description && (
                                            <p className="activity-description">
                                                {selectedActivities[image.id].description}
                                            </p>
                                        )}
                                    </div>
                                    <div className="activity-steps">
                                        {selectedActivities[image.id]?.steps
                                            .sort((a, b) => a.order - b.order)
                                            .map((step) => {
                                                const stepImage = activityStepUrls[image.id]?.[step.imageId];
                                                return stepImage ? (
                                                    <div
                                                        key={step._id}
                                                        className={`activity-step ${completedSteps.has(step._id) ? 'completed' : ''}`}
                                                        onClick={() => handleStepClick(step._id)}
                                                    >
                                                        <div className="step-image-container">
                                                            <img
                                                                src={stepImage.url}
                                                                alt={step.description}
                                                                className="step-image"
                                                            />
                                                            {image.activityId && showInputs && (
                                                            <div className="step-metadata-overlay">
                                                                <div className="step-metadata-content">
                                                                    {step.instruction || "No Instruction provided yet."}
                                                                </div>
                                                            </div>
                                                                )}
                                                            <div className="step-overlay">
                                                                {completedSteps.has(step._id) &&
                                                                    <Check className="step-checkmark" size={32} />
                                                                }
                                                            </div>
                                                            <div className="step-numbers">{step.order + 1}</div>
                                                        </div>
                                                        <div className="step-details">
                                                            <div className="step-name">{step.description}</div>
                                                            {step.duration > 0 && (
                                                                <div className="step-duration">{step.duration} minutes</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : null;
                                            })}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WebImageViewer;