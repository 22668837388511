import React from 'react';
import Spinner from '../utils/Spinner';
import '../css/LoadingSpinnerOverlay.css';

const LoadingSpinner = ({ children }) => (
    <div className="loading-spinner-overlay">
        <div className="loading-content">
            <Spinner />
            {children}
        </div>
    </div>
);

export default LoadingSpinner;
