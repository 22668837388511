import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../../css/DraggablePlanGrid.css';

const ItemTypes = {
    ITEM: 'item',
};

const DraggableItem = ({ item, index, moveItem }) => {
    const [, ref] = useDrag({
        type: ItemTypes.ITEM,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemTypes.ITEM,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <div ref={(node) => ref(drop(node))} className="draggable">
            {item.content}
        </div>
    );
};

const DraggablePlanGrid = ({ items, onMoveEnd }) => {
    const [gridItems, setGridItems] = useState(items);

    useEffect(() => {
        setGridItems(items);
    }, [items]);

    const moveItem = (fromIndex, toIndex) => {
        const updatedItems = [...gridItems];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        setGridItems(updatedItems);
        onMoveEnd(updatedItems); // Notify the parent component of the new order
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="droppable">
                {gridItems.map((item, index) => (
                    <DraggableItem key={item.id} index={index} item={item} moveItem={moveItem} />
                ))}
            </div>
        </DndProvider>
    );
};

export default DraggablePlanGrid;
