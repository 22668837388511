import React, { useState } from 'react';
import '../css/ProjectFormModal.css';

const ProjectFormModal = ({ onSubmit, onClose }) => {
    const [projectData, setProjectData] = useState({ name: '', age: '', projectPic: null });

    const handleProjectDataChange = (e) => {
        setProjectData({ ...projectData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProjectData({ ...projectData, projectPic: file, projectPicPreview: reader.result });
            };
            reader.readAsDataURL(file);
        } else {
            setProjectData({ ...projectData, projectPic: null, projectPicPreview: '' });
        }
    };

    return (
        <div className="project-form-modal">
            <form onSubmit={(e) => onSubmit(e, projectData)}>
                <h2>Create New Project</h2>
                <div className="form-group">
                    <label>Child's Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={projectData.name}
                        onChange={handleProjectDataChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Age:</label>
                    <input
                        type="number"
                        name="age"
                        value={projectData.age}
                        onChange={handleProjectDataChange}
                        required
                    />
                </div>
                <div className="form-group file-input-wrapper">
                    <input
                        type="file"
                        id="projectPic"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="projectPic" className="file-input-label">
                        {projectData.projectPic ? projectData.projectPic.name : 'Upload Profile Picture'}
                    </label>
                    {projectData.projectPicPreview && (
                        <img src={projectData.projectPicPreview} alt="Profile Preview" className="image-preview" />
                    )}
                </div>
                <button type="submit" className="submit-button">Submit</button>
                <button type="button" onClick={onClose} className="close-button">Close</button>
            </form>
        </div>
    );
};

export default ProjectFormModal;
