import React from 'react';
import Modal from 'react-modal';
import '../css/ImagePreviewModal.css';

Modal.setAppElement('#root');

const ImagePreviewModal = ({ isOpen, onRequestClose, imageUrl }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal"
            overlayClassName="overlay"
        >
            <div className="modal-content">
                <button onClick={onRequestClose} className="close-button">&times;</button>
                <img src={imageUrl} alt="Preview" className="modal-image" />
            </div>
        </Modal>
    );
};

export default ImagePreviewModal;
