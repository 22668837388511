import React, { useState, useEffect } from 'react';
import '../../css/ChoiceEditorBox.css';
import ImageSelectionModal from '../../modals/ImageSelectionModal';
import { getImage, setImage } from '../../utils/idbHelpers';
import { useProject } from "../../utils/ProjectContext";
import {Loader2} from "lucide-react";


const ChoiceEditorBox = ({ currentChoice, setCurrentChoice, projectId }) => {
    const [choiceName, setChoiceName] = useState(currentChoice.name);
    const { choices, setChoices, projectData, setProjectData, fetchProjectDetails, BASE_URL } = useProject();
    const [choiceImages, setChoiceImages] = useState([]);
    const token = localStorage.getItem('token');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const MAX_IMAGES = 4;
    const [showIndicators, setShowIndicators] = useState(false);

    useEffect(() => {
        // Add scroll container width check
        const container = document.querySelector('.choice-images-list');
        if (container) {
            const hasOverflow = container.scrollWidth > container.clientWidth;
            setShowIndicators(hasOverflow);
        }
    }, [choiceImages]);

    useEffect(() => {
        const fetchChoiceImages = async () => {
            const fetchedImages = await Promise.all(
                currentChoice.images.map(async (imageId) => {
                    const imageDetails = projectData.project.data.images.find(image => image._id === imageId);
                    if (imageDetails) {
                        let cachedImage = await getImage(projectId, imageDetails.name);
                        if (cachedImage) {
                            return { _id: imageId, url: cachedImage };
                        } else {
                            try {
                                const response = await fetch(`${BASE_URL}/project/${projectData.project._id}/image/${imageDetails.name}`, {
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                    },
                                });
                                const data = await response.json();
                                await setImage(projectId, imageDetails.name, data.imageUrl);
                                return { _id: imageId, url: data.imageUrl };
                            } catch (error) {
                                console.error('Error fetching image:', error);
                                return null;
                            }
                        }
                    } else {
                        console.error(`Image with id ${imageId} not found`);
                        return null;
                    }
                })
            );
            setChoiceImages(fetchedImages.filter(img => img !== null));
        };

        if (currentChoice.images) {
            fetchChoiceImages();
        }
    }, [currentChoice.images, projectData.project._id, token, projectData.project.data.images]);

    useEffect(() => {
        setChoiceName(currentChoice.name);
        const fetchChoiceImages = async () => {
            const fetchedImages = await Promise.all(
                currentChoice.images.map(async (imageId) => {
                    const imageDetails = projectData.project.data.images.find(image => image._id === imageId);
                    if (imageDetails) {
                        let cachedImage = await getImage(projectId, imageDetails.name);
                        if (cachedImage) {
                            return { _id: imageId, url: cachedImage };
                        } else {
                            try {
                                const response = await fetch(`${BASE_URL}/project/${projectData.project._id}/image/${imageDetails.name}`, {
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                    },
                                });
                                const data = await response.json();
                                await setImage(projectId, imageDetails.name, data.imageUrl);
                                return { _id: imageId, url: data.imageUrl };
                            } catch (error) {
                                console.error('Error fetching image:', error);
                                return null;
                            }
                        }
                    } else {
                        console.error(`Image with id ${imageId} not found`);
                        return null;
                    }
                })
            );
            setChoiceImages(fetchedImages.filter(img => img !== null));
        };
        fetchChoiceImages();
    }, [currentChoice]);

    const handleNameChange = (e) => {
        setChoiceName(e.target.value);

        // Update the current choice with the new name
        const updatedCurrentChoice = { ...currentChoice, name: e.target.value };
        setCurrentChoice(updatedCurrentChoice);

        // Update the choices array
        const updatedChoices = choices.map(choice =>
            choice._id === currentChoice._id ? updatedCurrentChoice : choice
        );
        setChoices(updatedChoices);

        // Update the project data
        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    choices: updatedChoices
                }
            }
        };
        setProjectData(updatedProjectData);
    };

    const handleAddImageClick = () => {
        setIsModalOpen(true);
    };

    const handleDeleteImage = (imageId) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const updatedImages = choiceImages.filter(image => image._id !== imageId);

            // Update the current choice with the deleted image removed
            const updatedCurrentChoice = { ...currentChoice, images: updatedImages.map(image => image._id) };

            // Update the choices array
            const updatedChoices = choices.map(choice =>
                choice._id === currentChoice._id ? updatedCurrentChoice : choice
            );

            // Update the project data
            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        choices: updatedChoices
                    }
                }
            };
            setChoiceImages(updatedImages);
            setCurrentChoice(updatedCurrentChoice);
            setChoices(updatedChoices);
            setProjectData(updatedProjectData);
        }

    };

    const handleSelectImage = async (imageId) => {
        setIsLoading(true);
        try {
            const data = await new Promise(resolve => setTimeout(() => resolve(fetchProjectDetails(projectId)), 2000));
            const foundImage = data.project.data.images.find(image => image._id === imageId);

            if (foundImage) {
                const updatedImages = [...choiceImages, foundImage];
                const updatedCurrentChoice = {
                    ...currentChoice,
                    images: updatedImages.map(image => image._id)
                };

                const updatedChoices = choices.map(choice =>
                    choice._id === currentChoice._id ? updatedCurrentChoice : choice
                );

                const updatedProjectData = {
                    ...data,
                    project: {
                        ...data.project,
                        data: {
                            ...data.project.data,
                            choices: updatedChoices
                        }
                    }
                };

                setChoiceImages(updatedImages);
                setCurrentChoice(updatedCurrentChoice);
                setChoices(updatedChoices);
                setProjectData(updatedProjectData);
                setIsModalOpen(false);
            } else {
                console.error('Image not found in project data:', imageId);
            }
        } catch (error) {
            console.error('Error selecting image:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="choice-editor-box">
            <div className="choice-editor-bg">
                <h2>Currently Editing: <span style={{ fontFamily: "sans-serif" }}>{currentChoice.name}</span></h2>
                <div className="editor-field">
                    <label>Edit Name:</label>
                    <input
                        type="text"
                        value={choiceName}
                        onChange={handleNameChange}
                    />
                </div>
                <div className="editor-field relative">
                    <label>Edit Images:</label>
                    <div className="choice-images-wrapper">
                        {showIndicators && <div className="scroll-indicator-left" />}
                        <div className="choice-images-list">
                            <div className="images-scroll-content">
                                {isLoading && (
                                    <div className="absolute inset-0 bg-black/10 flex items-center justify-center backdrop-blur-sm rounded-lg">
                                        <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
                                    </div>
                                )}
                                {choiceImages.map((image, index) => (
                                    <div
                                        key={image._id}
                                        className={`image-item ${hoveredIndex === index ? 'hovered' : ''}`}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <img
                                            src={image.url}
                                            alt={`Choice ${index}`}
                                        />
                                        <button
                                            onClick={() => handleDeleteImage(image._id)}
                                            className="delete-button"
                                            disabled={isLoading}
                                        >
                                            X
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {showIndicators && <div className="scroll-indicator-right" />}
                    </div>
                </div>
                <div>
                    <button
                        className="choice-editor-add-button"
                        onClick={handleAddImageClick}
                        disabled={isLoading || choiceImages.length >= MAX_IMAGES}
                    >
                        {choiceImages.length >= MAX_IMAGES ? 'Max Images Reached' : 'Add Image'}
                    </button>
                    <ImageSelectionModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onSelectImage={handleSelectImage}
                        projectId={projectData.project._id}
                        token={token}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChoiceEditorBox;
