import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProject } from '../../utils/ProjectContext';
import PlanEditorBox from './PlanEditorBox';
import DraggablePlanGrid from './DraggablePlanGrid';
import '../../css/PlannerEditor.css';
import { getImage, setImage } from '../../utils/idbHelpers';
import Spinner from "../../utils/Spinner";
import BottomNavBar from "../navigation/BottomNavBar";
import ActivityEditorModal from "./ActivityEditorModal";

const PlannerEditor = () => {
    const { projectId } = useParams();
    const { fetchProjectDetails, BASE_URL, projectData, setProjectData, projectName } = useProject();
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [images, setImages] = useState([]);
    const token = localStorage.getItem('token');
    const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageEntry, setSelectedImageEntry] = useState(null);

    const handleActivityEdit = (image, planImageEntry, activity) => {
        setSelectedImage(image);
        setSelectedImageEntry(planImageEntry);
        setSelectedActivity(activity);
        setIsActivityModalOpen(true);
    };

    const handleActivitySave = (activity) => {
        // ... activity save logic ...
        setIsActivityModalOpen(false);
        setSelectedImage(null);
        setSelectedImageEntry(null);
        setSelectedActivity(null);
    };

    const renderPlanImages = (plan, images) => {
        return plan.images.map((planImage, imgIndex) => {
            // Support both old and new schema formats
            const imageId = typeof planImage === 'string' ? planImage : planImage.imageId;
            const image = images.find(img => img._id === imageId);

            if (!image) return null;

            return (
                <div className="plan-thumb-surround" key={imgIndex}>
                    <img
                        src={image.imageUrl}
                        alt={`Plan Image ${imgIndex}`}
                        className="plan-image"
                    />
                    {planImage.name && (
                        <div className="image-name-overlay">
                            {planImage.name}
                        </div>
                    )}
                </div>
            );
        });
    };

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const data = await fetchProjectDetails(projectId);
                setPlans(data.project.data.plans || []);

                // Add detailed logging here
                console.log('=== Fetched Plans Data ===');
                console.log('Total Plans:', data.project.data.plans?.length || 0);

                // Log structure of first plan if it exists
                if (data.project.data.plans?.[0]) {
                    console.log('First Plan Structure:', {
                        id: data.project.data.plans[0]._id,
                        name: data.project.data.plans[0].name,
                        color: data.project.data.plans[0].color,
                        imagesCount: data.project.data.plans[0].images?.length || 0,
                        firstImage: data.project.data.plans[0].images?.[0],
                        allImages: data.project.data.plans[0].images
                    });
                }

                // Log all plans in a cleaner format
                data.project.data.plans?.forEach((plan, index) => {
                    console.log(`\nPlan ${index + 1}:`, {
                        id: plan._id,
                        name: plan.name,
                        imagesCount: plan.images?.length || 0,
                        images: plan.images?.map(img => {
                            if (typeof img === 'string') {
                                return { type: 'legacy', imageId: img };
                            }
                            return {
                                type: 'new',
                                imageId: img.imageId,
                                name: img.name,
                                hasActivity: !!img.activityId
                            };
                        })
                    });
                });

                setIsLoading(false);
                fetchProjectImages(data.project.data.images);
                if (data.project.data.plans && data.project.data.plans.length > 0) {
                    setSelectedPlan(data.project.data.plans[0]);
                }
            } catch (error) {
                console.error('Error fetching project data:', error);
                setIsLoading(false);
            }
        };

        const fetchProjectImages = async (imageDetails) => {
            const fetchedImages = await Promise.all(
                imageDetails.map(async (image) => {
                    let cachedImage = await getImage(projectId, image.name);
                    if (cachedImage) {
                        return { ...image, imageUrl: cachedImage };
                    } else {
                        try {
                            const response = await fetch(`${BASE_URL}/project/${projectId}/image/${image.name}`, {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                },
                            });
                            const data = await response.json();
                            await setImage(projectId, image.name, data.imageUrl);
                            return { ...image, imageUrl: data.imageUrl };
                        } catch (error) {
                            console.error('Error fetching image:', error);
                            return null;
                        }
                    }
                })
            );
            setImages(fetchedImages.filter(image => image !== null));
        };

        fetchProjectData();

    }, []);

    const handlePlanSelect = (planId) => {
        const plan = plans.find(plan => plan._id === planId);
        setSelectedPlan(plan);
    };

    const handleUpdatePlan = (updatedPlan) => {
        const updatedPlans = plans.map(plan =>
            plan._id === updatedPlan._id ? updatedPlan : plan
        );
        setPlans(updatedPlans);
        setSelectedPlan(updatedPlan);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const reorderedPlans = Array.from(plans);
        const [movedPlan] = reorderedPlans.splice(result.source.index, 1);
        reorderedPlans.splice(result.destination.index, 0, movedPlan);
        setPlans(reorderedPlans);
    };

    if (isLoading) {
        return (
            <div className="spinner-wrapper">
                <Spinner />
            </div>
        );
    }

    const onMoveEnd = (updatedItems) => {
        const reorderedPlans = updatedItems.map(item => {
            const originalPlan = plans.find(plan => plan._id === item.id);
            return originalPlan ? originalPlan : item;
        });

        setPlans(reorderedPlans);

        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    plans: reorderedPlans
                }
            }
        };

        setProjectData(updatedProjectData);
    };

    const handlePlanDelete = (planId) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const updatedPlans = plans.filter(plan => plan._id !== planId);
            setPlans(updatedPlans);

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        plans: updatedPlans
                    }
                }
            };
            setProjectData(updatedProjectData);

            // If the deleted plan was the selected one, select the first plan in the list
            if (selectedPlan && selectedPlan._id === planId) {
                setSelectedPlan(updatedPlans.length > 0 ? updatedPlans[0] : null);
            }
        }

    };

    const randomColor = () => {
        // Define the range of colors you want to use
        const colorRange = [
            '#FF7F50',      // Bright and visible, complements primary
            '#8FBC8F',       // Natural green that works with background
            '#DDA0DD',       // Soft purple that contrasts with greens
            '#DAA520',  // Rich yellow that complements accent
            '#87CEEB',    // Light blue for contrast
            '#E2725B', // Earthier version of primary
            '#E6E6FA',   // Light purple for subtle items
            '#40808C'        // Deep blue-green that works with secondary
        ]

        // Generate a random index
        const randomIndex = Math.floor(Math.random() * colorRange.length);

        // Return a random color from the colorRange array
        return colorRange[randomIndex];
    };

    const handleAddNewPlan = async () => {
        const newPlanName = prompt('Enter the name for the new plan:');
        if (newPlanName) {
            const response = await fetch(`${BASE_URL}/generate-objectid`);
            const data = await response.json();
            const newId = data.objectId;

            const newPlan = {
                _id: newId,
                name: newPlanName,
                color: randomColor(),
                images: []
            };

            const updatedPlans = [...plans, newPlan];

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        plans: updatedPlans
                    }
                }
            };

            setPlans(updatedPlans);
            setProjectData(updatedProjectData);
            setSelectedPlan(newPlan);
        }
    };


    return (
        <>
        <div className="container">
            <div className="topSurround">
                <h2 className="title">{projectName}'s Activity Schedules</h2>
                <button onClick={handleAddNewPlan}>Add New Schedule</button>
            </div>
            <DraggablePlanGrid
                onMoveEnd={onMoveEnd}
                className={'draggable-card'}
                items={plans.map((plan, index) => ({
                    id: plan._id,
                    draggableId: plan._id,
                    content: (
                        <div>
                            <button className="plan-delete-btn" onClick={() => handlePlanDelete(plan._id)}>X</button>
                            <div
                                className={`plan-card ${selectedPlan && selectedPlan._id === plan._id ? 'selected' : 'plan-card'}`}
                                onClick={() => handlePlanSelect(plan._id)}
                                style={{ backgroundColor: plan.color }}
                            >
                                <div>
                                    <h3>{plan.name}</h3>
                                </div>
                                <div className="images-container">
                                    {plan.images.map((planImage, imgIndex) => {
                                        // Handle both string (old format) and object (new format)
                                        const imageId = typeof planImage === 'string' ? planImage : planImage.imageId;
                                        const image = images.find(img => img._id === imageId);

                                        if (!image) return null;

                                        return (
                                            <div className="plan-thumb-surround" key={imgIndex}>
                                                <img
                                                    src={image.imageUrl}
                                                    alt={`Plan ${index} Image ${imgIndex}`}
                                                    className="plan-image"
                                                />
                                                {/*{typeof planImage === 'object' && planImage.name && (*/}
                                                {/*    <div className="image-name-overlay">*/}
                                                {/*        {planImage.name}*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                                {/*{typeof planImage === 'object' && planImage.activityId && (*/}
                                                {/*    <div className="activity-indicator">*/}
                                                {/*        <span className="activity-icon">📋</span>*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ),
                }))}
                onDragEnd={handleDragEnd}
            />
            {selectedPlan && (
                <PlanEditorBox
                    plan={selectedPlan}
                    projectId={projectId}
                    token={token}
                    onUpdatePlan={handleUpdatePlan}
                    projectData={projectData}
                    setProjectData={setProjectData}
                    onActivityEdit={handleActivityEdit}  // Pass this down
                />
            )}
            <BottomNavBar projectId={projectId} />
        </div>
    </>
    );
};

export default PlannerEditor;
