import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { useProject } from "../../utils/ProjectContext";
import {
    homeOutline,
    gitCompareOutline,
    trailSignOutline,
    calendarOutline, homeSharp, gitCompareSharp, shareSocial,
} from 'ionicons/icons';
import '../../css/BottomNavBar.css';

const BottomNavBar = ({ projectId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedPage, setSelectedPage] = useState(location.pathname);
    const { isEditMode } = useProject();

    if (!projectId) {
        console.error('Project ID is missing');
        return;
    }

    const handleNavigation = (path) => {
        if (path === 'home') {
            navigate(`/${path}`);
        } else {
            navigate(`/${path}/${projectId}`);
        }
        setSelectedPage(`/${path}`);
    };

    return (
        <div className={`bottom-nav-bar ${isEditMode ? 'edit-mode' : ''}`}>
            <button
                onClick={() => handleNavigation('home')}
                className={selectedPage === `/home` ? 'selected' : ''}
            >
                <IonIcon icon={homeSharp} />
                Home
            </button>
            <button
                onClick={() => handleNavigation('firstThenEditor')}
                className={selectedPage === `/firstThenEditor/${projectId}` ? 'selected' : ''}
            >
                <IonIcon icon={gitCompareSharp} />
                First Then Editor
            </button>
            <button
                onClick={() => handleNavigation('choiceEditor')}
                className={selectedPage === `/choiceEditor/${projectId}` ? 'selected' : ''}
            >
                <IonIcon icon={trailSignOutline} />
                Choices Editor
            </button>
            <button
                onClick={() => handleNavigation('planEditor')}
                className={selectedPage === `/planEditor/${projectId}` ? 'selected' : ''}
            >
                <IonIcon icon={calendarOutline} />
                Schedule Editor
            </button>
            <button
                onClick={() => handleNavigation('socialstories')}
                className={selectedPage === `/socialstories/${projectId}` ? 'selected' : ''}
            >
                <IonIcon icon={shareSocial} />
                Social Stories
            </button>
        </div>
    );
};


export default BottomNavBar;
