import React from 'react';
import '../css/ConfirmationModal.css'; // Add your styles here

const ConfirmationModal = ({ show, onClose, onConfirm, message }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Confirmation</h2>
                <p>{message}</p>
                <div className="modal-actions">
                    <button onClick={onConfirm} className="confirm-button">Yes</button>
                    <button onClick={onClose} className="cancel-button">No</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
