import React from 'react';
import '../../css/ChoiceBox.css';
import DraggableChoice from './DraggableChoice';
import { useProject } from "../../utils/ProjectContext";

const ChoiceBox = ({
                       selectedChoiceId,
                       setSelectedChoiceId,
                       onChoiceSelect,

                       projectId,
                   }) => {
    const { choices, setChoices, projectData, setProjectData, BASE_URL, projectName } = useProject();

    const moveChoice = (fromIndex, toIndex) => {
        const updatedChoices = [...choices];
        const [movedChoice] = updatedChoices.splice(fromIndex, 1);
        updatedChoices.splice(toIndex, 0, movedChoice);
        setChoices(updatedChoices);
    };

    const handleSelect = (choiceId) => {
        setSelectedChoiceId(choiceId);
        onChoiceSelect(choiceId);
    };

    const handleDeleteChoice = (choiceId) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const updatedChoices = choices.filter(choice => choice._id !== choiceId);
            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        choices: updatedChoices
                    }
                }
            };

            setChoices(updatedChoices);
            setProjectData(updatedProjectData);
        }



    };

    const handleAddNewChoice = async () => {
        const response = await fetch(`${BASE_URL}/generate-objectid`);
        const data = await response.json();
        const newId = data.objectId;
        const newChoiceName = prompt('Enter the name for the new choice:');
        if (newChoiceName) {
            const newChoice = {
                _id: newId,
                name: newChoiceName,
                images: [],
            };

            const updatedChoices = [...choices, newChoice];

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        choices: updatedChoices
                    }
                }
            };

            setChoices(updatedChoices);
            setProjectData(updatedProjectData);
            setSelectedChoiceId(newId);
            onChoiceSelect(newId);
        }
    };

    return (
        <div className="choice-box">
            <h2>{projectName}'s Choices</h2>
            <button onClick={handleAddNewChoice}>Add New Choice</button>
            <div className="choice-list">
                {choices.map((choice, index) => (
                    <DraggableChoice
                        projectId={projectId}
                        BASE_URL={BASE_URL}
                        projectData={projectData}
                        key={choice._id}
                        index={index}
                        choice={choice}
                        moveChoice={moveChoice}
                        onChoiceSelect={handleSelect}
                        isSelected={choice._id === selectedChoiceId}
                        onDelete={() => handleDeleteChoice(choice._id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ChoiceBox;
