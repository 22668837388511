import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProject } from '../../utils/ProjectContext';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableSocialGrid from './DraggableSocialGrid';
import '../../css/SocialStoriesEditor.css';
import { getImage, setImage } from '../../utils/idbHelpers';
import Spinner from "../../utils/Spinner";
import BottomNavBar from "../navigation/BottomNavBar";
import ImageSelectionModal from '../../modals/ImageSelectionModal';

const ITEM_TYPE = 'CHAPTER';




const colorRange = [
    '#79b2b2', '#a2ae52', '#f4895f', '#d5bc81',
    '#f1d2b9', '#fb88af', '#9656a2', '#cbabd1',
];

const Chapter = ({ chapter, index, moveChapter, handleChapterUpdate, handleChapterImageUpdate, handleDeleteChapterImage, handleOpenImageModal, images, handleDeleteChapter }) => {
    const [, ref] = useDrag({
        type: ITEM_TYPE,
        item: { index }
    });

    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        hover: (item) => {
            if (item.index !== index) {
                moveChapter(item.index, index);
                item.index = index;
            }
        }
    });

    const image = images.find(img => img._id === chapter.image);

    return (
        <div ref={(node) => ref(drop(node))} className="chapter-edit">
            <textarea
                value={chapter.story}
                onChange={(e) => handleChapterUpdate(index, { ...chapter, story: e.target.value })}
            />
            <div className="chapter-image-edit">
                {chapter.image ? (
                    <>
                        <img
                            src={image?.imageUrl || ''}
                            alt={`Chapter ${index} Image`}
                            className="chapter-image"
                        />
                        <button
                            className="chapter-image-delete-button"
                            onClick={() => handleDeleteChapterImage(index)}
                        >
                            X
                        </button>
                    </>
                ) : (
                    <button onClick={() => handleOpenImageModal(index)}>Choose Image</button>
                )}
            </div>
            <button
                className="chapter-delete-button"
                onClick={() => handleDeleteChapter(index)}
            >
                Delete Chapter
            </button>
        </div>
    );
};

const SocialStoriesEditor = () => {
    const { projectId } = useParams();
    const { fetchProjectDetails, BASE_URL, projectData, setProjectData, projectName } = useProject();
    const [stories, setStories] = useState([]);
    const [selectedStory, setSelectedStory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentChapterIndex, setCurrentChapterIndex] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); // State for delete confirmation modal
    const [deleteAction, setDeleteAction] = useState(null); // State to store the delete action
    const [deleteMessage, setDeleteMessage] = useState(''); // State to store the delete message
    const token = localStorage.getItem('token');

    useEffect(() => {
        const initializeData = async () => {
            let data = projectData;

            if (!data || data.project._id !== projectId) {
                data = await fetchProjectDetails(projectId);
                setProjectData(data);
            }

            setStories(data.project.data.social || []);
            setIsLoading(false);
            fetchProjectImages(data.project.data.images);

            if (data.project.data.social && data.project.data.social.length > 0) {
                setSelectedStory(data.project.data.social[0]);
            }
        };

        const fetchProjectImages = async (imageDetails) => {
            const fetchedImages = await Promise.all(
                imageDetails.map(async (image) => {
                    let cachedImage = await getImage(projectId, image.name);
                    if (cachedImage) {
                        return { ...image, imageUrl: cachedImage };
                    } else {
                        try {
                            const response = await fetch(`${BASE_URL}/project/${projectId}/image/${image.name}`, {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                },
                            });
                            const data = await response.json();
                            await setImage(projectId, image.name, data.imageUrl);
                            return { ...image, imageUrl: data.imageUrl };
                        } catch (error) {
                            console.error('Error fetching image:', error);
                            return null;
                        }
                    }
                })
            );
            setImages(fetchedImages.filter(image => image !== null));
        };

        initializeData();
    }, [projectId]);

    const handleStorySelect = (storyId) => {
        const story = stories.find(story => story._id === storyId);
        setSelectedStory(story);
    };

    const handleUpdateStory = (updatedStory) => {
        const updatedStories = stories.map(story =>
            story._id === updatedStory._id ? updatedStory : story
        );
        setStories(updatedStories);
        setSelectedStory(updatedStory);

        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    social: updatedStories
                }
            }
        };

        setProjectData(updatedProjectData);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const reorderedStories = Array.from(stories);
        const [movedStory] = reorderedStories.splice(result.source.index, 1);
        reorderedStories.splice(result.destination.index, 0, movedStory);
        setStories(reorderedStories);
    };

    const handleColorChange = (color) => {
        if (selectedStory) {
            const updatedStory = { ...selectedStory, color };

            const updatedStories = stories.map(story =>
                story._id === selectedStory._id ? updatedStory : story
            );

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        social: updatedStories
                    }
                }
            };

            setSelectedStory(updatedStory);
            setStories(updatedStories);
            setProjectData(updatedProjectData);
        }
    };

    if (isLoading) {
        return (
            <div className="spinner-wrapper">
                <Spinner />
            </div>
        );
    }

    const onMoveEnd = (updatedItems) => {
        const reorderedStories = updatedItems.map(item => {
            const originalStory = stories.find(story => story._id === item.id);
            return originalStory ? originalStory : item;
        });
        setStories(reorderedStories);
        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    social: reorderedStories
                }
            }
        };
        setProjectData(updatedProjectData);
    };

    const handleStoryDelete = (storyId) => {
        setDeleteAction(() => () => {
            const updatedStories = stories.filter(story => story._id !== storyId);
            setStories(updatedStories);

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        social: updatedStories
                    }
                }
            };
            setProjectData(updatedProjectData);

            if (selectedStory && selectedStory._id === storyId) {
                setSelectedStory(updatedStories.length > 0 ? updatedStories[0] : null);
            }
            setDeleteModalOpen(false);
        });
        setDeleteMessage('Are you sure you want to delete this story?');
        setDeleteModalOpen(true);
    };

    const randomColor = () => {
        const colorRange = [
            '#83f663', '#FFADAD', '#f874a8', '#6359b7',
            '#9BF6FF', '#55a844', '#FDFFB6', '#8e6ab9',
        ];
        const randomIndex = Math.floor(Math.random() * colorRange.length);
        return colorRange[randomIndex];
    };

    const handleAddNewStory = async () => {
        const newStoryName = prompt('Enter the name for the new story:');
        if (newStoryName) {
            const response = await fetch(`${BASE_URL}/generate-objectid`);
            const data = await response.json();
            const newId = data.objectId;

            const newStory = {
                _id: newId,
                name: newStoryName,
                color: randomColor(),
                chapters: []
            };

            const updatedStories = [...stories, newStory];

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        social: updatedStories
                    }
                }
            };

            setStories(updatedStories);
            setProjectData(updatedProjectData);
            setSelectedStory(newStory);
        }
    };

    const handleAddNewChapter = () => {
        const newChapter = {
            image: '', // Initially empty
            story: 'Default story text'
        };
        const updatedChapters = [...selectedStory.chapters, newChapter];

        const updatedStory = {
            ...selectedStory,
            chapters: updatedChapters
        };

        handleUpdateStory(updatedStory);
    };

    const handleChapterUpdate = async (index, updatedChapter) => {
        const updatedChapters = selectedStory.chapters.map((chapter, i) =>
            i === index ? updatedChapter : chapter
        );

        const updatedStory = {
            ...selectedStory,
            chapters: updatedChapters
        };

        const updatedStories = stories.map(story =>
            story._id === updatedStory._id ? updatedStory : story
        );

        setStories(updatedStories);

        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    social: updatedStories
                }
            }
        };

        setProjectData(updatedProjectData);

        // Ensure selectedStory is updated after projectData
        setSelectedStory(updatedStory);
    };

    const handleChapterImageUpdate = async (index, imageId) => {
        const updatedChapters = selectedStory.chapters.map((chapter, i) =>
            i === index ? { ...chapter, image: imageId } : chapter
        );

        const updatedStory = {
            ...selectedStory,
            chapters: updatedChapters
        };

        setSelectedStory(updatedStory);
        handleUpdateStory(updatedStory);
    };

    const handleOpenImageModal = (index) => {
        setCurrentChapterIndex(index);
        setIsModalOpen(true);
    };

    const handleSelectImage = async (imageId) => {
        const data = await fetchProjectDetails(projectId);
        setProjectData(data);
        const foundImage = data.project.data.images.find(image => image._id === imageId);
        if (foundImage) {
            const updatedImages = [...images, foundImage];

            const updatedChapters = selectedStory.chapters.map((chapter, i) =>
                i === currentChapterIndex ? { ...chapter, image: imageId } : chapter
            );

            const updatedStory = {
                ...selectedStory,
                chapters: updatedChapters
            };

            const updatedStories = stories.map(story =>
                story._id === updatedStory._id ? updatedStory : story
            );

            const updatedProjectData = {
                ...data,  // Use the latest data fetched
                project: {
                    ...data.project,
                    data: {
                        ...data.project.data,
                        social: updatedStories,
                        images: updatedImages // Ensure the new image is added to the images array
                    }
                }
            };

            setImages(updatedImages);
            setSelectedStory(updatedStory);
            setStories(updatedStories);
            setProjectData(updatedProjectData);
            setIsModalOpen(false);
        } else {
            console.error('Image not found in project data:', imageId);
        }
    };

    const handleDeleteChapterImage = (index) => {
        setDeleteAction(() => () => {
            const updatedChapters = selectedStory.chapters.map((chapter, i) => {
                if (i === index) {
                    return { ...chapter, image: '' };
                }
                return chapter;
            });

            const updatedStory = {
                ...selectedStory,
                chapters: updatedChapters
            };

            setSelectedStory(updatedStory);
            handleUpdateStory(updatedStory);
            setDeleteModalOpen(false);
        });
        setDeleteMessage('Are you sure you want to delete this chapter image?');
        setDeleteModalOpen(true);
    };

    const handleDeleteChapter = (index) => {
        setDeleteAction(() => () => {
            const updatedChapters = selectedStory.chapters.filter((chapter, i) => i !== index);

            const updatedStory = {
                ...selectedStory,
                chapters: updatedChapters
            };

            setSelectedStory(updatedStory);
            handleUpdateStory(updatedStory);
            setDeleteModalOpen(false);
        });
        setDeleteMessage('Are you sure you want to delete this chapter?');
        setDeleteModalOpen(true);
    };

    const moveChapter = (fromIndex, toIndex) => {
        const updatedChapters = Array.from(selectedStory.chapters);
        const [movedChapter] = updatedChapters.splice(fromIndex, 1);
        updatedChapters.splice(toIndex, 0, movedChapter);

        const updatedStory = {
            ...selectedStory,
            chapters: updatedChapters
        };

        handleUpdateStory(updatedStory);
    };

    const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
        if (!isOpen) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <h2>Confirmation</h2>
                    <p>{message}</p>
                    <div className="modal-actions">
                        <button onClick={onConfirm} className="confirm-button">Yes</button>
                        <button onClick={onClose} className="story-no-button">No</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="social-container">
                <div className="topSurround">
                    <h2 className="title">{projectName}'s Social Stories</h2>
                    <button onClick={handleAddNewStory}>Add New Story</button>
                </div>
                <DraggableSocialGrid
                    onMoveEnd={onMoveEnd}
                    className={'draggable-card'}
                    items={stories.map((story, index) => ({
                        id: story._id,
                        draggableId: story._id,
                        content: (
                            <div>
                                <button className="plan-delete-btn" onClick={() => handleStoryDelete(story._id)}>X</button>
                                <div
                                    className={`story-card ${selectedStory && selectedStory._id === story._id ? 'selected' : 'plan-card'}`}
                                    onClick={() => handleStorySelect(story._id)}
                                    style={{ backgroundColor: story.color }}
                                >
                                    <h3>{story.name}</h3>
                                    <div className="chapters-container">
                                        {story.chapters.map((chapter, chapIndex) => {
                                            const image = images.find(img => img._id === chapter.image);
                                            return (
                                                <div key={chapIndex} className="chapter">
                                                    {image ? (
                                                        <img
                                                            src={image.imageUrl}
                                                            alt={`oops`}
                                                            className="chapter-image"
                                                        />
                                                    ) : (
                                                        <button onClick={() => handleOpenImageModal(chapIndex)}>Choose Image</button>
                                                    )}
                                                    <p className={'top-chapter-story-text'}>{chapter.story.length > 50 ? `${chapter.story.substring(0, 47)}...` : chapter.story}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        ),
                    }))}
                    onDragEnd={handleDragEnd}
                />
                {selectedStory && (
                    <div className={'edit-container-container'}>
                    <div             className="story-edit-container"
                                     style={{ backgroundColor: selectedStory.color }}>
                        <h2>Currently Editing: <span style={{fontSize: 'larger'}}>{selectedStory.name}</span></h2>
                        <button onClick={handleAddNewChapter}>Add New Chapter</button>
                        {selectedStory.chapters.map((chapter, index) => (
                            <Chapter
                                handleDeleteChapter={handleDeleteChapter}
                                key={index}
                                index={index}
                                chapter={chapter}
                                moveChapter={moveChapter}
                                handleChapterUpdate={handleChapterUpdate}
                                handleChapterImageUpdate={handleChapterImageUpdate}
                                handleDeleteChapterImage={handleDeleteChapterImage}
                                handleOpenImageModal={handleOpenImageModal}
                                images={images}
                            />
                        ))}
                        <div className={'story-edit-set-color'} >Set Color</div>
                        <div className="color-buttons">
                            {colorRange.map(color => (
                                <button
                                    key={color}
                                    style={{ backgroundColor: color }}
                                    className="color-select-button"
                                    onClick={() => handleColorChange(color)}
                                />
                            ))}
                        </div>
                    </div>
                    </div>
                )}
                <BottomNavBar projectId={projectId} />
                <ImageSelectionModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSelectImage={handleSelectImage}
                    projectId={projectId}
                    token={token}
                />
                <DeleteConfirmationModal
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={deleteAction}
                    message={deleteMessage}
                />
            </div>
        </DndProvider>
    );
};

export default SocialStoriesEditor;
