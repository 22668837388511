import React, {useCallback, useEffect, useState} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ImageSelectionModal from '../../modals/ImageSelectionModal';
import '../../css/PlanEditorBox.css';
import { getImage, setImage } from '../../utils/idbHelpers';
import { useProject } from "../../utils/ProjectContext";
import ActivityEditorModal from './ActivityEditorModal';
import { Eye, EyeOff, RefreshCw } from 'lucide-react';
import WebImageViewer from '../viewer/WebImageViewer';

const ItemTypes = {
    IMAGE: 'image',
};

function removeImageExtension(imageName) {
    return imageName.replace(/\.(jpg|jpeg|png|gif|bmp)$/i, '');
}






const PlanEditorBox = ({ plan, projectId, token, onUpdatePlan }) => {
    const { projectData, setProjectData, fetchProjectDetails, BASE_URL, downloadedImages } = useProject();
    const [planName, setPlanName] = useState(plan.name);
    const [planColor, setPlanColor] = useState(plan.color);
    const [planImages, setPlanImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageEntry, setSelectedImageEntry] = useState(null);
    const [isEditingName, setIsEditingName] = useState(false);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewMode, setViewMode] = useState('swipeable');
    const [viewerOpen, setViewerOpen] = useState(false);
    const toggleViewer = () => setViewerOpen((prev) => !prev);


    const handleFeedback = useCallback((feedback) => {
        console.log('Feedback received:', feedback);
        // Implement feedback handling logic here
    }, []);

    const DraggablePlanImage = ({ image, index, moveImage, removeImage, plan, onUpdateName, onEditActivity }) => {
        const [isEditing, setIsEditing] = useState(false);
        const [displayName, setDisplayName] = useState(() => {
            const planImageEntry = plan.images[index];
            return typeof planImageEntry === 'object'
                ? planImageEntry.name || removeImageExtension(image.name)
                : removeImageExtension(image.name);
        });

        const [, ref] = useDrag({
            type: ItemTypes.IMAGE,
            item: { index },
        });

        const [, drop] = useDrop({
            accept: ItemTypes.IMAGE,
            hover: (draggedItem) => {
                if (draggedItem.index !== index) {
                    moveImage(draggedItem.index, index);
                    draggedItem.index = index;
                }
            },
        });

        const planImageEntry = plan.images[index];
        const hasActivity = typeof planImageEntry === 'object' && planImageEntry.activityId;

        // Fetch activity name
        const activityName = hasActivity ? getActivityName(planImageEntry.activityId) : '';

        return (
            <div ref={(node) => ref(drop(node))} className="dragg-plan-image">
                {isEditing ? (
                    <input
                        type="text"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        onBlur={() => {
                            setIsEditing(false);
                            onUpdateName(index, displayName);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setIsEditing(false);
                                onUpdateName(index, displayName);
                            }
                        }}
                        autoFocus
                        className="image-name-input"
                    />
                ) : (
                    <div onClick={() => setIsEditing(true)} className="image-name">
                        {displayName}
                    </div>
                )}
                <div className="plan-image-wrapper">
                    <img src={image.imageUrl} alt={displayName} className="plan-image" />
                    <div className="image-button-container">
                    <button className="image-delete-btn" onClick={() => removeImage(image._id)}>×</button>
                    <label className="activity-label-text">Activity:</label>
                        <button
                            className={`activity-btn ${hasActivity ? 'has-activity' : ''}`}
                            onClick={() => onEditActivity(image, planImageEntry)}
                            title={hasActivity ? "Edit Activity" : "Add Activity"}
                        >
                            {hasActivity ? ` 🏃 ${truncateText(activityName, 8)}` : "➕"}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const handleRefresh = async () => {
        console.log('REFRESH GOT FIRED');
        try {
            // Add a delay of 1 second
            await new Promise(resolve => setTimeout(resolve, 250));

            // Get the current plan from projectData after the delay
            const currentPlan = projectData.project.data.plans.find(p => p._id === plan._id);

            if (currentPlan) {
                console.log('Refreshing with plan:', currentPlan);
                setPlanName(currentPlan.name);
                setPlanColor(currentPlan.color);
                onUpdatePlan(currentPlan);

                // Re-fetch images for the plan from cache
                const fetchedImages = await Promise.all(
                    currentPlan.images.map(async (planImage) => {
                        const imageDetails = projectData.project.data.images.find(
                            img => img._id === planImage.imageId
                        );
                        if (!imageDetails) return null;

                        // Only try to get from cache
                        let cachedImage = await getImage(projectId, imageDetails.name);
                        if (cachedImage) {
                            return { ...imageDetails, imageUrl: cachedImage };
                        }
                        return null;
                    })
                );
                setPlanImages(fetchedImages.filter(img => img !== null));
            }
        } catch (error) {
            console.error('Error refreshing plan:', error);
        }
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    };

    function getActivityName(activityId) {
        // Assuming `projectData` contains an array of all activities
        const activity = projectData.project.data.activities.find(act => act._id === activityId);
        return activity ? activity.name : '';
    }


    useEffect(() => {
        setPlanName(plan.name);
        setPlanColor(plan.color);
    }, [plan]);

    useEffect(() => {
        console.log('Current plan:', {
            id: plan._id,
            name: plan.name,
            color: plan.color,
            imagesCount: plan.images?.length,
            images: plan.images
        });
    }, [plan]);


    const colorRange = [
        '#FF7F50',      // Bright and visible, complements primary
        '#8FBC8F',       // Natural green that works with background
        '#DDA0DD',       // Soft purple that contrasts with greens
        '#DAA520',  // Rich yellow that complements accent
        '#87CEEB',    // Light blue for contrast
        '#E2725B', // Earthier version of primary
        '#E6E6FA',   // Light purple for subtle items
        '#40808C'        // Deep blue-green that works with secondary
    ];

    useEffect(() => {
        const fetchPlanImages = async () => {
            const fetchedImages = await Promise.all(
                plan.images.map(async (planImage) => {
                    // Assuming planImage is now an object with imageId
                    const imageDetails = projectData.project.data.images.find(img => img._id === planImage.imageId);
                    if (!imageDetails) {
                        console.error(`Image with id ${planImage.imageId} not found`);
                        return null;
                    }
                    let cachedImage = await getImage(projectId, imageDetails.name);
                    if (cachedImage) {
                        return { ...imageDetails, imageUrl: cachedImage };
                    }

                    try {
                        const response = await fetch(`${BASE_URL}/project/${projectId}/image/${imageDetails.name}`, {
                            headers: { 'Authorization': `Bearer ${token}` },
                        });
                        const data = await response.json();
                        await setImage(projectId, imageDetails.name, data.imageUrl);
                        return { ...imageDetails, imageUrl: data.imageUrl };
                    } catch (error) {
                        console.error('Error fetching image:', error);
                        return null;
                    }
                })
            );
            setPlanImages(fetchedImages.filter(img => img !== null));
        };

        fetchPlanImages();
    }, [plan.images, projectId, token, projectData.project.data.images]);


    const handleActivitySave = (activity, options = {}) => {
        console.log("Saving activity:", activity);

        const updatedImages = plan.images.map((img, index) => {
            if (index === planImages.findIndex(pi => pi._id === selectedImage._id)) {
                return {
                    imageId: typeof img === 'string' ? img : img.imageId,
                    name: typeof img === 'object' ? img.name : removeImageExtension(selectedImage.name),
                    activityId: activity._id
                };
            }
            return img;
        });

        // Update the activities array in projectData
        const existingActivityIndex = projectData.project.data.activities?.findIndex(a => a._id === activity._id);
        let updatedActivities = [...(projectData.project.data.activities || [])];

        if (existingActivityIndex >= 0) {
            // Update existing activity
            updatedActivities[existingActivityIndex] = activity;
        } else {
            // Add new activity
            updatedActivities.push(activity);
        }

        // Update plan with new images
        const updatedPlan = {
            ...plan,
            images: updatedImages
        };

        // Update project data with both new plan and activities
        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    plans: projectData.project.data.plans.map(p =>
                        p._id === plan._id ? updatedPlan : p
                    ),
                    activities: updatedActivities
                }
            }
        };

        onUpdatePlan(updatedPlan);
        setProjectData(updatedProjectData);

        // Only close the modal if keepOpen is false
        if (!options.keepOpen) {
            setIsActivityModalOpen(false);
            setSelectedImage(null);
            setSelectedImageEntry(null);
            setSelectedActivity(null);
        }
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setPlanName(newName);

        const updatedPlan = { ...plan, name: newName };
        onUpdatePlan(updatedPlan);

        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    plans: projectData.project.data.plans.map(p =>
                        p._id === plan._id ? updatedPlan : p
                    )
                }
            }
        };
        setProjectData(updatedProjectData);
    };

    const handleColorChange = (color) => {
        setPlanColor(color);
        onUpdatePlan({ ...plan, color });

        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    plans: projectData.project.data.plans.map(p =>
                        p._id === plan._id ? { ...plan, color } : p
                    )
                }
            }
        };
        setProjectData(updatedProjectData);
    };

    const handleUpdateImageName = (index, newName) => {
        const updatedPlanImages = [...plan.images]; // This creates a shallow copy of plan.images
        if (updatedPlanImages[index] && typeof updatedPlanImages[index] === 'object') {
            updatedPlanImages[index] = { ...updatedPlanImages[index], name: newName }; // Update the name
        } else {
            // If the entry is not an object, it might be a string or some other structure depending on your data model
            updatedPlanImages[index] = newName; // Assuming it's just a string
        }

        // Updating planImages state to reflect the change
        const newPlanImagesState = planImages.map((item, idx) => {
            if (idx === index) {
                return { ...item, name: newName };
            }
            return item;
        });

        setPlanImages(newPlanImagesState); // Update the planImages state to trigger re-render

        // Propagate changes up to the parent component or global state if necessary
        const updatedPlan = { ...plan, images: updatedPlanImages };
        onUpdatePlan(updatedPlan); // This might be a prop function to update the state in a parent component

        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    plans: projectData.project.data.plans.map(p =>
                        p._id === plan._id ? updatedPlan : p
                    )
                }
            }
        };
        setProjectData(updatedProjectData); // This updates your global project state
    };

    const updateProjectData = (updatedPlan) => {
        setProjectData(prev => ({
            ...prev,
            project: {
                ...prev.project,
                data: {
                    ...prev.project.data,
                    plans: prev.project.data.plans.map(p => {
                        if (p._id === plan._id) return updatedPlan;
                        return p;
                    })
                }
            }
        }));
    };

    const handleSelectImage = async (imageId) => {
        const data = await fetchProjectDetails(projectId);
        const foundImage = data.project.data.images.find(image => image._id === imageId);

        if (foundImage) {
            if (plan.images.some(img =>
                (typeof img === 'string' ? img === imageId : img.imageId === imageId)
            )) return;

            const newImageEntry = {
                imageId: foundImage._id,
                name: removeImageExtension(foundImage.name),
                activityId: null
            };

            const updatedImages = [...planImages, foundImage];
            setPlanImages(updatedImages);

            const updatedPlan = {
                ...plan,
                images: [...plan.images, newImageEntry]
            };

            onUpdatePlan(updatedPlan);

            const updatedProjectData = {
                ...data,
                project: {
                    ...data.project,
                    data: {
                        ...data.project.data,
                        plans: data.project.data.plans.map(p =>
                            p._id === plan._id ? updatedPlan : p
                        )
                    }
                }
            };

            setProjectData(updatedProjectData);
        }
    };

    const handleEditActivity = (image, planImageEntry) => {
        console.log("Editing activity for image:", image);
        console.log("Plan image entry:", planImageEntry);

        setSelectedImage(image);
        setSelectedImageEntry(planImageEntry);

        // If there's an activity ID, find the activity in projectData
        const activityId = planImageEntry?.activityId;
        if (activityId) {
            console.log("Looking for activity with ID:", activityId);
            const activity = projectData.project.data.activities?.find(a => a._id === activityId);
            if (activity) {
                console.log("Found activity:", activity);
                setSelectedActivity(activity);
            } else {
                console.warn("Activity not found for ID:", activityId);
                setSelectedActivity(null);
            }
        } else {
            setSelectedActivity(null);
        }

        setIsActivityModalOpen(true);
    };

    const handleRemoveImage = (imageId) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const updatedPlanImages = planImages.filter(image => image._id !== imageId);
            setPlanImages(updatedPlanImages);

            // Update the plan.images array to maintain the correct structure
            const updatedImages = plan.images.filter(img =>
                typeof img === 'object' ? img.imageId !== imageId : img !== imageId
            );

            const updatedPlan = {
                ...plan,
                images: updatedImages
            };

            onUpdatePlan(updatedPlan);

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        plans: projectData.project.data.plans.map(p =>
                            p._id === plan._id ? updatedPlan : p
                        )
                    }
                }
            };
            setProjectData(updatedProjectData);
        }
    };

    const getActivityForImage = (imageId) => {
        // Look through all activities' steps to find which activity contains this image
        return projectData.project.data.activities.find(activity =>
            activity.steps.some(step => step.imageId === imageId)
        );
    };

    const moveImage = (fromIndex, toIndex) => {
        const updatedImages = Array.from(planImages);
        const [movedImage] = updatedImages.splice(fromIndex, 1);
        updatedImages.splice(toIndex, 0, movedImage);

        // Also update the plan.images array to maintain the same order
        const updatedPlanImages = Array.from(plan.images);
        const [movedPlanImage] = updatedPlanImages.splice(fromIndex, 1);
        updatedPlanImages.splice(toIndex, 0, movedPlanImage);

        setPlanImages(updatedImages);

        const updatedPlan = {
            ...plan,
            images: updatedPlanImages
        };

        onUpdatePlan(updatedPlan);

        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    plans: projectData.project.data.plans.map(p =>
                        p._id === plan._id ? updatedPlan : p
                    )
                }
            }
        };
        setProjectData(updatedProjectData);
    };

    return (
        <div className="plan-editor-wrapper">
            <div className="plan-editor-box">
                <div className="plan-header">
                    <h2>Currently Editing: <span style={{ fontFamily: "sans-serif" }}>{planName}</span></h2>
                    <div className="flex items-center gap-4 ml-4">
                        <button
                            onClick={() => setIsViewerOpen(!isViewerOpen)}
                            className="inline-flex gap-2 px-3 py-1 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >View Now
                        </button>
                        {isViewerOpen && (
                            <WebImageViewer
                                images={planImages.map(image => {
                                    const planImageEntry = plan.images.find(pi =>
                                        typeof pi === 'object' ? pi.imageId === image._id : pi === image._id
                                    );

                                    return {
                                        id: image._id,
                                        url: image.imageUrl,
                                        name: (planImageEntry && typeof planImageEntry === 'object' ?
                                            planImageEntry.name :
                                            removeImageExtension(image.name)),
                                        // Get the activityId from the plan image entry if it exists
                                        activityId: planImageEntry?.activityId || null
                                    };
                                })}
                                onClose={() => setIsViewerOpen(false)}
                                dataName={planName}
                                onFeedback={handleFeedback}
                                projectData={projectData.project.data}
                                downloadedImages={downloadedImages}
                            />
                        )}
                    </div>
                </div>
                <div className="plan-card" style={{ backgroundColor: planColor }}>
                    <label>Edit Schedule Name:</label>

                    {isEditingName ? (
                        <input
                            type="text"
                            value={planName}
                            onChange={(e) => setPlanName(e.target.value)}
                            onBlur={() => {
                                setIsEditingName(false);
                                handleNameChange({ target: { value: planName } });
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    setIsEditingName(false);
                                    handleNameChange({ target: { value: planName } });
                                }
                            }}
                            autoFocus
                            className="plan-name-input"
                        />
                    ) : (
                        <div
                            onClick={() => setIsEditingName(true)}
                            className="plan-name"
                        >
                            {planName}
                        </div>
                    )}
                    <button
                        onClick={handleRefresh}
                        className="refresh-button"
                        title="Refresh plan"
                    >
                        <RefreshCw size={20} />
                    </button>
                    <div className="plan-images-container">
                        <DndProvider backend={HTML5Backend}>
                            <div className="images-list">
                                {planImages.map((image, index) => (
                                    <DraggablePlanImage
                                        key={image._id}
                                        index={index}
                                        image={image}
                                        moveImage={moveImage}
                                        removeImage={handleRemoveImage}
                                        plan={plan}
                                        onUpdateName={handleUpdateImageName}
                                        onEditActivity={handleEditActivity}
                                    />
                                ))}
                            </div>
                            <div className="add-btn-container">
                            <div className='add-image-btn' onClick={() => setIsModalOpen(true)}>Add Image</div>
                            <div className="editor-field">
                                <label>Select Color:</label>
                                <div className="color-buttons">
                                    {colorRange.map(color => (
                                        <button
                                            key={color}
                                            style={{ backgroundColor: color }}
                                            className="color-select-button"
                                            onClick={() => handleColorChange(color)}
                                        />
                                    ))}
                                </div>
                            </div>
                            </div>
                        </DndProvider>
                    </div>

                </div>
                {isViewerOpen && (
                    <WebImageViewer
                        key={`${planName}-${projectId}-${isViewerOpen}`}
                        images={planImages.map(image => ({
                            id: image._id,
                            url: image.imageUrl,
                            name: plan.images.find(pi =>
                                typeof pi === 'object' ? pi.imageId === image._id : pi === image._id
                            )?.name || removeImageExtension(image.name),
                            activityId: plan.images.find(pi =>
                                typeof pi === 'object' ? pi.imageId === image._id : pi === image._id
                            )?.activityId
                        }))}
                        onClose={() => setIsViewerOpen(false)}
                        dataName={planName}
                        onFeedback={handleFeedback}
                        projectData={projectData.project.data}
                        projectId={projectId}
                        token={token}
                        BASE_URL={BASE_URL}
                        planColor={planColor}
                    />
                )}
                <ImageSelectionModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSelectImage={handleSelectImage}
                    projectId={projectId}
                    token={token}
                />
                {isActivityModalOpen && (

                    <ActivityEditorModal
                        plan={plan}
                        isVisible={isActivityModalOpen}
                        onClose={(hasUnsavedChanges) => {
                            if (hasUnsavedChanges) {
                                if (window.confirm("You have unsaved changes. Are you sure you want to close without saving?")) {
                                    setIsActivityModalOpen(false);
                                    setSelectedImage(null);
                                    setSelectedImageEntry(null);
                                    setSelectedActivity(null);
                                }
                            } else {
                                setIsActivityModalOpen(false);
                                setSelectedImage(null);
                                setSelectedImageEntry(null);
                                setSelectedActivity(null);
                            }
                        }}
                        handleRefresh={handleRefresh}
                        activity={selectedActivity}
                        selectedImage={selectedImage}
                        onSave={handleActivitySave}
                        projectData={projectData}
                        setProjectData={setProjectData}
                    />
                )}
            </div>
        </div>
    );
};

export default PlanEditorBox;