import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useAuth} from './AuthContext'; // Import the useAuth hook

const ProjectContext = createContext();
const BASE_URL = 'https://www.mookimoo.com/mookmanage';
// const BASE_URL = 'http://192.168.200.184:3005';
const AI_URL = 'https://www.mookimoo.com/mookai';
// const AI_URL = 'http://192.168.200.184:3010';

export const ProjectProvider = ({ children }) => {
    const { user } = useAuth(); // Use the useAuth hook to get the user
    const [projects, setProjects] = useState([]);
    const [projectData, setProjectData] = useState(null);
    const [choices, setChoices] = useState([]);
    const [isEditMode, setIsEditMode] = useState(true);
    const [projectId, setProjectId] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [cropInfo, setCropInfo] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (isUpdating) {
            console.log('ITs UPDATING')
        } else {
            console.log('ITs No Longer UPDATING')
        }

    }, [isUpdating]);

    const setProjectDataWithTrace = (data) => {
        console.log('setProjectData called with:', data);
        console.trace('setProjectData call stack');
        setProjectData(data);
    };

    useEffect(() => {
        if (projectData) {
            console.log('SOMETHING CHANGED', projectData.project.data.images)
        }
    }, [projectData]);
    const addImageToProject = async (imageData) => {
        const { imageId, imageName, s3Url } = imageData;

        // Create new image entry
        const newImage = {
            _id: imageId,
            name: imageName,
            url: s3Url
        };

        // Update project data atomically
        setProjectData(prevData => {
            if (!prevData || !prevData.project) return prevData;

            const updatedImages = [...prevData.project.data.images, newImage];

            return {
                ...prevData,
                project: {
                    ...prevData.project,
                    data: {
                        ...prevData.project.data,
                        images: updatedImages
                    }
                }
            };
        });

        // Return the new image data
        return newImage;
    };


    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    useEffect(() => {
        const initializeProjectContext = async () => {
            const lastSelectedProjectId = localStorage.getItem('selectedProject');
            if (lastSelectedProjectId) {
                // Fetch the project details
                const projectDetails = await fetchProjectDetails(lastSelectedProjectId);
                setProjectId(lastSelectedProjectId);
                setProjectName(projectDetails.name);  // Set project name from fetched details
            }
        };

        if (!projectId || !projectName) {
            initializeProjectContext();
        }
    }, []);

    const [showInputs, setShowInputs] = useState(() => {
        const saved = localStorage.getItem('activityEditorShowInputs');
        return saved ? JSON.parse(saved) : false;
    });

    const fetchObjectId = async () => {
        const response = await fetch(`${BASE_URL}/generate-objectid`);
        return await response.json();
    }

    const uploadImageWithCrop = async (file, cropInfo, projectName) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('cropInfo', JSON.stringify(cropInfo));

        try {
            const response = await fetch(`${BASE_URL}/uploadWithCrop/${projectName}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to upload image');
            }

            const imagedata = await response.json();

            const newImage = {
                _id: imagedata.imageId,
                name: imagedata.imageName,
                url: imagedata.s3Url
            };

            console.log('NEW IMAGE FROM UPLOAD WITH CROP', newImage);

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        images: [...projectData.project.data.images, newImage]
                    }
                }
            };

            setProjectData(updatedProjectData);

            console.log('CONTEXT UPDATE DATA', updatedProjectData.project.data.images)

            return {
                s3Url: imagedata.s3Url,
                imageId: imagedata.imageId,
                imageName: imagedata.imageName
            };
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };



    const updateProjectDataInDB = async (updatedProjectData) => {
        setIsUpdating(true); // Set to true when update starts
        console.log('Feeding the DB', updatedProjectData.project.data.images);
        const token = localStorage.getItem('token');
        try {
            const dataToSend = { ...updatedProjectData.project.data };
            delete dataToSend.feedback;
            console.log('TRYING TO SEND');
            const response = await fetch(`${BASE_URL}/projectUpdate/${updatedProjectData.project._id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ project: { data: dataToSend } }),
            });
            console.log('TRIED TO SEND');
            if (!response.ok) {
                throw new Error('Failed to update project data');
            }
        } catch (error) {
            console.error('Error updating project data:', error);
        } finally {
            setIsUpdating(false); // Set to false when update completes
        }
    };


    const debouncedUpdateProjectData = useCallback(
        debounce(async (updatedProjectData) => {
            await updateProjectDataInDB(updatedProjectData);
        }, 2000),
        []
    );

    useEffect(() => {
        if (projectData) {
            console.log('Bouncey Bouncey');
            debouncedUpdateProjectData(projectData);
        }
    }, [projectData]);


    const fetchProjectDetails = async (projectId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${BASE_URL}/api/projects/${projectId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const project = await response.json();
            setProjectData(project);
            setChoices(project.project.data.choices);
            setProjectName(project.project.name);
            return project;
        } catch (error) {
            console.error('Error fetching project data:', error);
            throw error;
        }
    };

    return (
        <ProjectContext.Provider
            value={{
                fetchProjectDetails,
                updateProjectDataInDB,
                projects,
                setProjects,
                BASE_URL,
                AI_URL,
                projectData,
                setProjectData: setProjectDataWithTrace,
                choices,
                setChoices,
                isEditMode,
                setIsEditMode,
                projectId,
                setProjectId,
                projectName,
                setProjectName,
                user,
                setCropInfo,
                uploadImageWithCrop,
                isUpdating,
                fetchObjectId,
                showInputs,
                setShowInputs
            }}>
            {children}
        </ProjectContext.Provider>
    );
};

export const useProject = () => useContext(ProjectContext);