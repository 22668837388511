import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {getImage, setImage} from '../../utils/idbHelpers';

const ItemTypes = {
    THIS_THEN: 'thisThen',
};

const DraggableThisThenItem = ({ item, index, moveItem, onSelect, isSelected, projectData, BASE_URL, token, setImage, onDelete, projectId }) => {
    const [, ref] = useDrag({
        type: ItemTypes.THIS_THEN,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemTypes.THIS_THEN,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const [isHovered, setIsHovered] = useState();
    const [thisImages, setThisImages] = useState([]);
    const [thenImages, setThenImages] = useState([]);

    const ensureImageObject = (img) => {
        if (!img) {
            return {
                id: '',
                altName: '',
                name: '',
                localPath: '',
                url: ''
            };
        }
        if (typeof img === 'string') {
            return {
                id: img,
                altName: '',
                name: '',
                localPath: '',
                url: ''
            };
        }
        return {
            id: img.id || img._id || '',
            altName: img.altName || '',
            name: img.name || '',
            localPath: img.localPath || '',
            url: img.url || '',
            ...img // preserve any additional fields
        };
    };

    useEffect(() => {
        const fetchImages = async (images) => {
            if (!Array.isArray(images)) {
                console.warn('Images is not an array:', images);
                return [];
            }

            return await Promise.all(
                images.map(async (imageObj) => {
                    try {
                        // Ensure we have a proper image object
                        const normalizedImg = ensureImageObject(imageObj);
                        const imageId = normalizedImg.id;

                        const imageDetails = projectData?.project?.data?.images?.find(
                            image => image._id === imageId
                        );

                        if (!imageDetails) {
                            console.warn(`Image details not found for ID: ${imageId}`);
                            return null;
                        }

                        let cachedImage = await getImage(projectId, imageDetails.name);

                        if (cachedImage) {
                            return {
                                ...normalizedImg,
                                ...imageDetails,
                                imageUrl: cachedImage
                            };
                        }

                        const response = await fetch(
                            `${BASE_URL}/project/${projectData.project._id}/image/${imageDetails.name}`,
                            {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                },
                            }
                        );

                        if (!response.ok) {
                            throw new Error(`Failed to fetch image: ${response.statusText}`);
                        }

                        const data = await response.json();
                        await setImage(projectId, imageDetails.name, data.imageUrl);

                        return {
                            ...normalizedImg,
                            ...imageDetails,
                            imageUrl: data.imageUrl
                        };
                    } catch (error) {
                        console.error('Error processing image:', error);
                        return null;
                    }
                })
            );
        };


        fetchImages(item.this.images).then(setThisImages);
        fetchImages(item.then.images).then(setThenImages);
    }, [item.this.images, item.then.images, projectData, BASE_URL, token, setImage]);

    const handleDelete = (e) => {
        e.stopPropagation();
        if (window.confirm("Are you sure you want to delete this item?")) {
            onDelete(item._id);
        }
    };

    return (
        <div
            ref={(node) => ref(drop(node))}
            className={`this-then-item ${isHovered ? 'hovered' : ''} ${isSelected ? 'selected' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => onSelect(item._id)}
        >
            <div className={'this-then-item-group'}>
                <div style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '120px',
                    textAlign: "center"
                }}>
                    {item?.name || 'Unnamed'}
                </div>
                <div className="images-row">
                    {(!thisImages || thisImages.length === 0) && (
                        <div className="placeholder"></div>
                    )}
                    {thisImages.filter(Boolean).map((image, index) => (
                        <img
                            key={index}
                            src={image.imageUrl}
                            alt={image.altName || image.name}
                            className="this-then-image-thumbnail"
                        />
                    ))}
                    {(!thenImages || thenImages.length === 0) && (
                        <div className="placeholder"></div>
                    )}
                    {thenImages.filter(Boolean).map((image, index) => (
                        <img
                            key={index}
                            src={image.imageUrl}
                            alt={image.altName || image.name}
                            className="this-then-image-thumbnail"
                        />
                    ))}
                </div>
                <button className="delete-firstThen-btn" onClick={handleDelete}>X</button>
            </div>
        </div>
    );
};

export default DraggableThisThenItem;
