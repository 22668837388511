import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext';
import { useProject } from '../../utils/ProjectContext';
import '../../css/Home.css';
import BottomNavBar from "../navigation/BottomNavBar";

const MenuButton = ({ title, icon, onClick }) => (
    <button className="menu-button" onClick={onClick}>
        <i className={`fas ${icon}`}></i>
        <span>{title}</span>
    </button>
);

const Home = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { projectId } = useProject();

    console.log('USER', user)

    return (
        <div className="home-container">
            <div className="home-content">
                <h1 className="welcome-header">Welcome {user?.user?.name.split(' ')[0] || 'User'}</h1>

                <div className="menu-buttons">
                    <MenuButton
                        title="First/Then"
                        icon="fa-exchange-alt"
                        onClick={() => navigate(`/firstThenEditor/${projectId}`)}
                    />
                    <MenuButton
                        title="Choices"
                        icon="fa-random"
                        onClick={() => navigate(`/choiceEditor/${projectId}`)}
                    />
                    <MenuButton
                        title="Activity Schedules"
                        icon="fa-calendar"
                        onClick={() => navigate(`/planEditor/${projectId}`)}
                    />
                    <MenuButton
                        title="Social Stories"
                        icon="fa-book"
                        onClick={() => navigate(`/socialstories/${projectId}`)}
                    />
                    <MenuButton
                        title="Reports"
                        icon="fa-chart-bar"
                        onClick={() => navigate('/reports')}
                    />
                </div>
                <BottomNavBar projectId={projectId} />
            </div>
        </div>
    );
};

export default Home;
