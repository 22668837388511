import React from 'react';
import Modal from 'react-modal';
import { Scatter } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';

Chart.register(...registerables);

const LoginHistoryModal = ({ user, onClose }) => {
    // Prepare data for the chart
    const data = {
        datasets: [
            {
                label: 'Login Times',
                data: user.loginHistory.map(entry => ({
                    x: new Date(entry.date),
                    y: new Date(entry.date).getHours() + new Date(entry.date).getMinutes() / 60,
                })),
                backgroundColor: 'blue',
                pointRadius: 5,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'MMM d, yyyy',
                    displayFormats: {
                        day: 'MMM d',
                    },
                },
                title: {
                    display: true,
                    text: 'Date',
                },
                grid: {
                    display: true,
                    color: '#e0e0e0',
                },
            },
            y: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Time of Day (Hours)',
                },
                ticks: {
                    callback: function(value) {
                        const hour = Math.floor(value);
                        const minutes = Math.round((value - hour) * 60);
                        return `${hour}:${minutes < 10 ? '0' : ''}${minutes}`;
                    },
                },
                grid: {
                    display: true,
                    color: '#e0e0e0',
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const date = new Date(context.raw.x);
                        const hours = date.getHours();
                        const minutes = date.getMinutes();
                        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                    },
                },
            },
        },
    };

    return (
        <Modal isOpen={true} onRequestClose={onClose}>
            <h2>Login History for {user.name}</h2>
            <Scatter data={data} options={options} />
            <button onClick={onClose}>Close</button>
        </Modal>
    );
};

export default LoginHistoryModal;
