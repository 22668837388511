import React, { useEffect, useState, useRef, useMemo } from 'react';
import axios from 'axios';
import { useProject } from "../../utils/ProjectContext";
import {ArrowUpDown, Edit2, Trash2, Search, Plus, X, RotateCcw} from 'lucide-react';
import '../../css/Bugs.css';
import MessageStyleComments from '../../modals/MessageStyleComments';
import Modal from '../../modals/Modal'

const saveSettingsToLocalStorage = (settings) => {
    localStorage.setItem('bugTrackerSettings', JSON.stringify(settings));
};

const loadSettingsFromLocalStorage = () => {
    const settings = localStorage.getItem('bugTrackerSettings');
    return settings ? JSON.parse(settings) : null;
};

const Bugs = () => {
    const { BASE_URL, user } = useProject();
    const [commentModalOpen, setCommentModalOpen] = useState(false);
    const [currentFeedback, setCurrentFeedback] = useState(null);
    const [newComment, setNewComment] = useState('');
    const [feedbackList, setFeedbackList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [newSubBugs, setNewSubBugs] = useState([]);
    const [editing, setEditing] = useState(null);
    const [highlightedBug, setHighlightedBug] = useState(null);
    const [satisfactionCheck, setSatisfactionCheck] = useState(null);
    const bugRefs = useRef({});
    const [editingComment, setEditingComment] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [newAdminComment, setNewAdminComment] = useState('');
    const [showAdminCommentInput, setShowAdminCommentInput] = useState({});
    const [sortConfig, setSortConfig] = useState(() => {
        const savedSettings = loadSettingsFromLocalStorage();
        return savedSettings?.sortConfig || { key: null, direction: 'ascending' };
    });
    const defaultFilters = {
        status: {
            open: true,
            triage: true,
            'in-progress': true,
            complete: true,
            'feedback': true,
            backlog: true,
            'far-backlog': true,
        },
        showUserBugsOnly: false
    };
    const [selectedBug, setSelectedBug] = useState(null);

    const updateBugData = (updatedBug) => {
        setFeedbackList(prevList =>
            prevList.map(item => item._id === updatedBug._id ? updatedBug : item)
        );
        setSelectedBug(updatedBug);
    };
    const openConversation = (bug) => {
        console.log('BUGGER', bug);
        setSelectedBug(bug);
    };

    const [filters, setFilters] = useState(() => {
        const savedSettings = loadSettingsFromLocalStorage();
        return savedSettings?.filters || defaultFilters;
    });

    const statusColors = {
        open: '#ffeb3b',
        triage: '#ff9800',
        'in-progress': '#03a9f4',
        complete: '#4caf50',
        'feedback': '#9c22ff',
        backlog: '#c77451',
        'far-backlog': '#b94325'
    };

    useEffect(() => {
        if (commentModalOpen || selectedBug) {
            // Disable scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling
            document.body.style.overflow = 'auto';
        }

        // Clean up on unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [commentModalOpen, selectedBug]);

    useEffect(() => {
        const fetchFeedback = async () => {
            const response = await axios.get(`${BASE_URL}/feedback/get`);
            const feedbackWithDefaults = response.data.map(feedback => ({
                ...feedback,
                status: feedback.status || 'open',
                adminComment: feedback.adminComment || '',
                viewableByUser: feedback.viewableByUser !== undefined ? feedback.viewableByUser : true,
                subBugs: feedback.subBugs || [],
                parentBugIndex: feedback.parentBugIndex || null,
                userSatisfied: feedback.userSatisfied || 'not seen',
            }));
            setFeedbackList(feedbackWithDefaults);
            setFilteredList(feedbackWithDefaults);
        };
        fetchFeedback();
    }, [BASE_URL]);

    useEffect(() => {
        const applyFilters = () => {
            let result = feedbackList;
            console.log('Applying filters. Search term:', searchTerm);
            console.log('Show user bugs only:', filters.showUserBugsOnly);
            console.log('Current user:', user?.user);

            if (searchTerm) {
                result = result.filter(item =>
                    item.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }

            result = result.filter(item => filters.status[item.status]);

            if (filters.showUserBugsOnly && user?.user) {
                result = result.filter(item =>
                    item.userId === user.user._id ||
                    item.email === user.user.email ||
                    item.name === user.user.name
                );
            }

            if (sortConfig.key) {
                result.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                });
            }

            console.log('Filtered result count:', result.length);
            setFilteredList(result);
        };

        applyFilters();
        saveSettingsToLocalStorage({ filters, sortConfig });
    }, [feedbackList, filters, searchTerm, user, sortConfig]);

    const resetFilters = () => {
        setFilters(defaultFilters);
        setSearchTerm('');
        setSortConfig({ key: null, direction: 'ascending' });
        saveSettingsToLocalStorage({ filters: defaultFilters, sortConfig: { key: null, direction: 'ascending' } });
    };

    const handleAdminCommentChange = (e) => {
        setNewAdminComment(e.target.value);
    };

    const toggleAdminCommentInput = (feedbackId) => {
        setShowAdminCommentInput(prev => ({
            ...prev,
            [feedbackId]: !prev[feedbackId]
        }));
    };


    const openFeedbackModal = (feedback) => {
        setCurrentFeedback(feedback);
        setCommentModalOpen(true);
        setEditingComment(null);
    };

    const handleEditComment = (commentId, newMessage) => {
        setEditingComment({ id: commentId, message: newMessage });
    };

    const saveEditedComment = async () => {
        if (!editingComment || !currentFeedback) return;

        try {
            const response = await axios.put(`${BASE_URL}/feedback/editComment/${currentFeedback._id}/${editingComment.id}`, {
                message: editingComment.message
            });

            const updatedFeedback = response.data;
            updateFeedbackItem(currentFeedback._id, { feedbackComments: updatedFeedback.feedbackComments });
            setEditingComment(null);
        } catch (error) {
            console.error('Error editing comment:', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        if (!currentFeedback) return;

        try {
            const response = await axios.delete(`${BASE_URL}/feedback/deleteComment/${currentFeedback._id}/${commentId}`);
            const updatedFeedback = response.data;
            updateFeedbackItem(currentFeedback._id, { feedbackComments: updatedFeedback.feedbackComments });
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const renderFeedbackComments = (feedback) => {
        if (!feedback.feedbackComments || feedback.feedbackComments.length === 0) {
            return <p>No comments yet.</p>;
        }
        return (
            <ul className="feedback-comments-list">
                {feedback.feedbackComments.sort((a, b) => new Date(b.date) - new Date(a.date)).map((comment) => (
                    <li key={comment._id} className="feedback-comment">
                        {editingComment && editingComment.id === comment._id ? (
                            <div className="editing-comment">
                            <textarea
                                value={editingComment.message}
                                onChange={(e) => setEditingComment({ ...editingComment, message: e.target.value })}
                            />
                                <div className="edit-actions">
                                    <button onClick={saveEditedComment}>Save</button>
                                    <button onClick={() => setEditingComment(null)}>Cancel</button>
                                </div>
                            </div>
                        ) : (
                            <div className="comment-content">
                                <div className="comment-text-and-date">
                                    <p>{comment.message}</p>
                                    <small>{new Date(comment.date).toLocaleString()}</small>
                                </div>
                                {user?.role === 'admin' && (
                                    <div className="comment-actions">
                                        <button onClick={() => handleEditComment(comment._id, comment.message)} className="edit-comment-btn">
                                            <Edit2 size={10} />
                                        </button>
                                        <button onClick={() => handleDeleteComment(comment._id)} className="delete-comment-btn">
                                            <Trash2 size={10} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    const bugIdToIndexMap = useMemo(() => {
        const map = new Map();
        feedbackList.forEach(bug => {
            map.set(bug._id, bug.index);
        });
        return map;
    }, [feedbackList]);

    const handleStatusChange = async (id, newStatus, newSeverity) => {
        if (newStatus === 'complete') {
            await updateBugStatus(id, newStatus, null, newSeverity);
            setSatisfactionCheck(id);
        } else {
            await updateBugStatus(id, newStatus, null, newSeverity);
        }
    };

    const updateBugStatus = async (id, newStatus, userSatisfied = null, newSeverity = null) => {
        try {
            await axios.put(`${BASE_URL}/feedback/edit/${id}`, {
                status: newStatus,
                userSatisfied: userSatisfied,
                severity: newSeverity
            });
            updateFeedbackItem(id, { status: newStatus, userSatisfied: userSatisfied, severity: newSeverity });
            setSatisfactionCheck(null);
        } catch (error) {
            console.error('Error updating bug status:', error);
        }
    };

    const handleSatisfactionChange = async (feedback, newSatisfaction) => {
        if (newSatisfaction === 'not yet') {
            setCurrentFeedback(feedback);
            setCommentModalOpen(true);
        } else {
            let updatedSatisfaction;
            if (feedback.userSatisfied === newSatisfaction) {
                updatedSatisfaction = 'not seen';
            } else {
                updatedSatisfaction = newSatisfaction;
            }

            try {
                await axios.put(`${BASE_URL}/feedback/edit/${feedback._id}`, {
                    userSatisfied: updatedSatisfaction
                });
                updateFeedbackItem(feedback._id, { userSatisfied: updatedSatisfaction });
            } catch (error) {
                console.error('Error updating user satisfaction:', error);
            }
        }
    };


    const handleSatisfactionResponse = async (id, isSatisfied) => {
        if (isSatisfied) {
            await updateBugStatus(id, 'complete', 'yes');
        } else {
            const feedback = feedbackList.find(f => f._id === id);
            setCurrentFeedback(feedback);
            setCommentModalOpen(true);
        }
        setSatisfactionCheck(null);
    };



    const saveAdminChanges = async (id, newComment, newStatus, subBugs, newSeverity) => {
        await axios.put(`${BASE_URL}/feedback/edit/${id}`, {
            adminComment: newComment,
            status: newStatus,
            subBugs,
            severity: newSeverity
        });
        updateFeedbackItem(id, { adminComment: newComment, status: newStatus, subBugs, severity: newSeverity });
        setEditing(null);
    };

    const addSubBug = (parentBugIndex) => {
        const newBug = { message: '', status: 'open', isBug: true, parentBugIndex };
        setNewSubBugs([...newSubBugs, newBug]);
    };

    const handleSubBugChange = (index, field, value) => {
        const updatedSubBugs = [...newSubBugs];
        updatedSubBugs[index] = { ...updatedSubBugs[index], [field]: value };
        setNewSubBugs(updatedSubBugs);
    };

    const saveSubBugs = async (parentBugId) => {
        const validSubBugs = newSubBugs.filter(bug => bug.message.trim() !== '');
        if (validSubBugs.length === 0) {
            console.log('No valid sub-bugs to save');
            return;
        }

        try {
            const createdSubBugs = [];
            for (const subBug of validSubBugs) {
                const response = await axios.post(`${BASE_URL}/feedback/createSubBug`, {
                    parentBugId,
                    subBug
                });
                createdSubBugs.push(response.data.subBug);
            }

            const updatedFeedback = feedbackList.map(item =>
                item._id === parentBugId
                    ? { ...item, subBugs: [...item.subBugs, ...createdSubBugs] }
                    : item
            );
            setFeedbackList(updatedFeedback);
            setFilteredList(updatedFeedback);
            setNewSubBugs([]);
            setEditing(null);
        } catch (error) {
            console.error('Error saving sub-bugs:', error);
        }
    };

    const scrollToParentBug = (bugIndex) => {
        if (bugRefs.current[bugIndex]) {
            bugRefs.current[bugIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
            setHighlightedBug(bugIndex);
            setTimeout(() => setHighlightedBug(null), 2000);
        }
    };

    const sortData = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const newSortConfig = { key, direction };
        setSortConfig(newSortConfig);
        saveSettingsToLocalStorage({ filters, sortConfig: newSortConfig });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleStatusToggle = (status) => {
        const newFilters = {
            ...filters,
            status: {
                ...filters.status,
                [status]: !filters.status[status]
            }
        };
        setFilters(newFilters);
        saveSettingsToLocalStorage({ filters: newFilters, sortConfig });
    };

    const toggleUserBugs = () => {
        const newFilters = {
            ...filters,
            showUserBugsOnly: !filters.showUserBugsOnly
        };
        setFilters(newFilters);
        saveSettingsToLocalStorage({ filters: newFilters, sortConfig });

        // Log for debugging
        console.log('Toggled user bugs:', newFilters.showUserBugsOnly);
    };

    const cancelEdit = () => {
        setEditing(null);
        setNewSubBugs([]);
    };

    const renderStatusToggle = (status) => (
        <label className="status-toggle" key={status}>
            <input
                type="checkbox"
                checked={filters.status[status]}
                onChange={() => handleStatusToggle(status)}
            />
            <span className={`status-badge status-${status}`} style={{ backgroundColor: statusColors[status] }}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
        </label>
    );

    const submitAdminComment = async (feedbackId) => {
        if (newAdminComment.trim() === '') return;

        try {
            const response = await axios.post(`${BASE_URL}/feedback/addAdminComment/${feedbackId}`, {
                message: newAdminComment
            });

            const updatedFeedback = response.data;
            updateFeedbackItem(feedbackId, { adminComments: updatedFeedback.adminComments });

            setNewAdminComment('');
            setShowAdminCommentInput(prev => ({
                ...prev,
                [feedbackId]: false
            }));
        } catch (error) {
            console.error('Error submitting admin comment:', error);
        }
    };

    const submitFeedbackComment = async () => {
        if (newComment.trim() === '' || !currentFeedback) return;

        try {
            const response = await axios.post(`${BASE_URL}/feedback/addUserComment/${currentFeedback._id}`, {
                message: newComment
            });

            const updatedFeedback = response.data;
            updateFeedbackItem(currentFeedback._id, {
                userSatisfied: 'not yet',
                feedbackComments: updatedFeedback.feedbackComments
            });

            setNewComment('');
            setCommentModalOpen(false);
        } catch (error) {
            console.error('Error submitting feedback comment:', error);
        }
    };


    const updateFeedbackItem = (id, updates) => {
        const updatedList = feedbackList.map(item =>
            item._id === id ? { ...item, ...updates } : item
        );
        setFeedbackList(updatedList);
        // Filters and sorting will be reapplied in the useEffect hook
    };


    const SubBugIndexes = ({ subBugIds, scrollToParentBug }) => (
        <span className="sub-bug-indexes">
            {subBugIds.map((subBugId, index) => {
                const subBugIndex = bugIdToIndexMap.get(subBugId);
                return subBugIndex ? (
                    <a
                        key={index}
                        href={`#${subBugIndex}`}
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToParentBug(subBugIndex);
                        }}
                        className="sub-bug-index"
                    >
                        {subBugIndex}
                    </a>
                ) : null;
            })}
        </span>
    );

    const renderAdminComments = (feedback) => {
        if (!feedback.adminComments || feedback.adminComments.length === 0) {
            return <p>No admin comments yet.</p>;
        }

        return (
            <ul className="admin-comments-list">
                {feedback.adminComments.map((comment, index) => (
                    <li key={index} className="admin-comment">
                        <p>{comment.message}</p>
                        <small>{new Date(comment.date).toLocaleString()}</small>
                    </li>
                ))}
            </ul>
        );
    };


    const renderSatisfactionCheck = (id) => (
        <div className="satisfaction-check">
            <p>Are you satisfied with the resolution?</p>
            <button onClick={() => handleSatisfactionResponse(id, true)}>Yes</button>
            <button onClick={() => handleSatisfactionResponse(id, false)}>Not Yet</button>
        </div>
    );

    const renderFeedbackRow = (feedback) => {
        const isSubBug = feedback.parentBugIndex !== null;
        const hasValidNewSubBugs = newSubBugs.some(bug => bug.message.trim() !== '');

        return (
            <tr
                key={feedback._id}
                ref={el => bugRefs.current[feedback.index] = el}
                className={`
                ${feedback.status === 'complete' ? 'complete-row' : ''}
                ${isSubBug ? 'sub-bug-row' : ''}
                ${highlightedBug === feedback.index ? 'highlighted-row' : ''}
            `}
            >
                <td>{feedback.index}</td>
                <td>{new Date(feedback.date).toLocaleDateString()}</td>
                <td>{feedback.name}</td>
                <td>
                    {feedback.message}
                    {!isSubBug && feedback.subBugs && feedback.subBugs.length > 0 && (
                        <>
                            <span className="has-sub-bugs-indicator">Has Sub-Bugs🔽</span>
                            <SubBugIndexes subBugIds={feedback.subBugs} scrollToParentBug={scrollToParentBug} />
                        </>
                    )}
                    {isSubBug && (
                        <span className="sub-bug-indicator">
                        <span className="sub-bug-icon">🔼</span>
                        (Sub-bug of <a href={`#${feedback.parentBugIndex}`} onClick={(e) => {
                            e.preventDefault();
                            scrollToParentBug(feedback.parentBugIndex);
                        }}>{feedback.parentBugIndex}</a>)
                    </span>
                    )}
                </td>
                <td>
                    {user?.role === 'admin' && editing === feedback._id ? (
                        <select
                            value={feedback.status}
                            onChange={e => handleStatusChange(feedback._id, e.target.value, feedback.severity)}
                        >
                            <option value="open">Open</option>
                            <option value="triage">Triage</option>
                            <option value="in-progress">In Progress</option>
                            <option value="complete">Complete</option>
                            <option value="feedback">Feedback</option>
                            <option value="backlog">Backlog</option>
                            <option value="far-backlog">Far Backlog</option>

                        </select>
                    ) : (
                        <span className={`status-badge status-${feedback.status}`}>
                        {feedback.status}
                    </span>
                    )}
                </td>
                <td>
                    {user?.role === 'admin' && editing === feedback._id ? (
                        <select
                            value={feedback.severity}
                            onChange={e => handleStatusChange(feedback._id, feedback.status, parseInt(e.target.value))}
                        >
                            {[0, 1, 2, 3, 4, 5].map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    ) : (
                        <span className={`severity-badge severity-${feedback.severity}`}>
                            {feedback.severity}
                        </span>
                    )}
                </td>
                <td>
                    <div className="satisfaction-checkboxes">
                        <label>
                            <input
                                type="checkbox"
                                checked={feedback.userSatisfied === 'yes'}
                                onChange={() => handleSatisfactionChange(feedback, 'yes')}
                                disabled={feedback.status !== 'complete'}
                            />
                            Satisfied
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={feedback.userSatisfied === 'not yet'}
                                onChange={() => handleSatisfactionChange(feedback, 'not yet')}
                                disabled={feedback.status !== 'complete'}
                            />
                            Not Yet
                        </label>
                        {feedback.userSatisfied === 'not seen' && (
                            <span className="not-seen-indicator">Not Seen</span>
                        )}
                    </div>
                </td>
                <td>
                    {renderAdminComments(feedback)}
                    {user?.role === 'admin' && (
                        <div className="admin-comment-section">
                            <button
                                className="add-admin-comment-btn"
                                onClick={() => toggleAdminCommentInput(feedback._id)}
                            >
                                <Plus size={10} />
                            </button>
                            {showAdminCommentInput[feedback._id] && (
                                <div className="admin-comment-input">
                                    <textarea
                                        value={newAdminComment}
                                        onChange={handleAdminCommentChange}
                                        placeholder="Add admin comment"
                                    />
                                    <div className="admin-comment-actions">
                                        <button onClick={() => submitAdminComment(feedback._id)}>Submit</button>
                                        <button onClick={() => toggleAdminCommentInput(feedback._id)}>
                                            <X size={12} />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </td>
                <td>
                    <button
                        onClick={() => openConversation(feedback)}
                        className="view-comments-btn"
                    >
                        {feedback.feedbackComments?.length || 0}
                    </button>
                </td>
                {user?.role === 'admin' && (
                    <td>
                        {editing === feedback._id ? (
                            <div className="admin-edit-section">
                                <div className="admin-edit-row">
                                    <button
                                        className="save-btn"
                                        onClick={() => saveAdminChanges(feedback._id, feedback.adminComment, feedback.status, feedback.subBugs, feedback.severity)}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="cancel-btn"
                                        onClick={cancelEdit}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                {!isSubBug && (
                                    <div className="sub-bug-form">
                                        <button onClick={() => addSubBug(feedback.index)}>Add Sub-bug</button>
                                        {newSubBugs.map((subBug, index) => (
                                            <div key={index}>
                                                <input
                                                    type="text"
                                                    value={subBug.message}
                                                    onChange={e => handleSubBugChange(index, 'message', e.target.value)}
                                                    placeholder="Sub-bug description"
                                                />
                                            </div>
                                        ))}
                                        {newSubBugs.length > 0 && (
                                            <div className="sub-bug-actions">
                                                <button
                                                    onClick={() => saveSubBugs(feedback._id)}
                                                    disabled={!hasValidNewSubBugs}
                                                >
                                                    Save Sub-bugs
                                                </button>
                                                <button onClick={() => setNewSubBugs([])}>Clear Sub-bugs</button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <button className="edit-btn" onClick={() => setEditing(feedback._id)}>Edit</button>
                        )}
                    </td>
                )}


            </tr>
        );
    };

    return (
        <div className="bug-container">
            <h2 className="bug-header">Feedback / Bug Tracker</h2>
            <div className="filters-container">
                <div className="search-container">
                    <Search size={20} />
                    <input
                        type="text"
                        placeholder="Search bugs..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="status-filters">
                    {Object.keys(filters.status).map(renderStatusToggle)}
                    <button onClick={resetFilters} className="reset-filters-btn" title="Reset all filters">
                        <RotateCcw size={16} />
                    </button>
                </div>

                <button onClick={toggleUserBugs} className="toggle-user-bugs">
                    {filters.showUserBugsOnly ? "Show All Bugs" : "Show My Bugs"}
                </button>

            </div>
            <div className="bug-count">
                Displaying {filteredList.length} bug{filteredList.length !== 1 ? 's' : ''}
            </div>
            <table className="bug-table">
                <thead>
                <tr>
                    <th onClick={() => sortData('index')}>
                        Index <ArrowUpDown size={16} />
                    </th>
                    <th onClick={() => sortData('date')}>
                        Date Submitted <ArrowUpDown size={16} />
                    </th>
                    <th onClick={() => sortData('name')}>
                        Name <ArrowUpDown size={16} />
                    </th>
                    <th>Message</th>
                    <th onClick={() => sortData('status')}>
                        Status <ArrowUpDown size={16} />
                    </th>
                    <th onClick={() => sortData('severity')}>
                        Severity <ArrowUpDown size={16} />
                    </th>
                    <th onClick={() => sortData('userSatisfied')}>
                        User Satisfaction <ArrowUpDown size={16} />
                    </th>
                    <th onClick={() => sortData('adminComments')}>
                        Admin Comment <ArrowUpDown size={16} />
                    </th>
                    <th onClick={() => sortData('feedbackComments')}>
                        Feedback Count <ArrowUpDown size={16} />
                    </th>
                    {user?.role === 'admin' && <th>Actions</th>}

                </tr>
                </thead>
                <tbody>
                {filteredList.map(feedback => (
                    <React.Fragment key={feedback._id}>
                        {renderFeedbackRow(feedback)}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
            {satisfactionCheck && renderSatisfactionCheck(satisfactionCheck)}
            {commentModalOpen && currentFeedback && (
                <Modal onClose={() => setCommentModalOpen(false)}>
                    <h2>Feedback Comments</h2>
                    {renderFeedbackComments(currentFeedback)}
                    <h3>Add New Comment</h3>
                    <textarea
                        placeholder="Enter your feedback..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        rows="4"
                    />
                    <div className="modal-actions">
                        <button onClick={submitFeedbackComment}>Submit</button>
                        <button onClick={() => setCommentModalOpen(false)}>Close</button>
                    </div>
                </Modal>
            )}
            {selectedBug && (
                <div className="conversation-modal">
                        <MessageStyleComments
                            onClose={() => setSelectedBug(null)}
                            bug={selectedBug}
                            user={user}
                            updateBug={updateBugData}
                        />
                </div>
            )}
        </div>
    );
};
export default Bugs;