import React, { useState } from 'react';
import '../css/UserFormModal.css';

const UserFormModal = ({ onSubmit, onClose, setUploadedFileName }) => {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        profilePic: null,
        projects: [{ name: '', age: '', projectPic: null }],
    });

    const handleUserDataChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleProjectChange = (index, field, value) => {
        const updatedProjects = [...userData.projects];
        updatedProjects[index][field] = value;
        setUserData({ ...userData, projects: updatedProjects });
    };

    const handleAddProject = () => {
        setUserData({
            ...userData,
            projects: [...userData.projects, { name: '', age: '', projectPic: null }],
        });
    };

    const handleImageChange = (e, field) => {
        setUserData({ ...userData, [field]: e.target.files[0] });
    };

    const [selectedProfilePic, setSelectedProfilePic] = useState('');
    const [selectedProjectPic, setSelectedProjectPic] = useState('');
    const [profilePicPreview, setProfilePicPreview] = useState('');
    const [projectPicsPreviews, setProjectPicsPreviews] = useState({});

    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePicPreview(reader.result);
                setUserData({ ...userData, [field]: file });
            };
            reader.readAsDataURL(file);
        } else {
            setProfilePicPreview('');
            setUserData({ ...userData, [field]: null });
        }
    };

    const handleProjectFileChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newPreviews = { ...projectPicsPreviews, [index]: reader.result };
                setProjectPicsPreviews(newPreviews);

                // Update projects state with both the file and its name
                const updatedProjects = [...userData.projects];
                updatedProjects[index].projectPic = file;
                updatedProjects[index].projectPicName = file.name; // Store the file name
                setUploadedFileName(file.name);

                setUserData({ ...userData, projects: updatedProjects });
            };
            reader.readAsDataURL(file);
        } else {
            // Handle case when file is removed
            const newPreviews = { ...projectPicsPreviews, [index]: null };
            setProjectPicsPreviews(newPreviews);
            const updatedProjects = [...userData.projects];
            updatedProjects[index].projectPic = null;
            updatedProjects[index].projectPicName = ''; // Reset the file name

            setUserData({ ...userData, projects: updatedProjects });
        }
    };

    const handleRemoveProject = (index) => {
        const updatedProjects = userData.projects.filter((_, i) => i !== index);
        setUserData({ ...userData, projects: updatedProjects });
    };

    return (
        <div className="user-form-modal">
            {/* Modal Content */}
            <form onSubmit={(e) => onSubmit(e, userData)}>
                {/* User fields */}
                <input
                    type="text"
                    name="name"
                    placeholder="User Name"
                    value={userData.name}
                    onChange={handleUserDataChange}
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={userData.email}
                    onChange={handleUserDataChange}
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={userData.password}
                    onChange={handleUserDataChange}
                />
                {/* More user fields */}
                {/* Profile Pic Upload */}
                <div className="file-input-wrapper">
                    <input
                        type="file"
                        id="profilePic"
                        onChange={(e) => handleFileChange(e, 'profilePic')}
                    />
                    <label
                        htmlFor="profilePic"
                        className="file-input-label"
                    >
                        {selectedProfilePic || "Profile Pic"}
                    </label>
                    {profilePicPreview && (
                        <img src={profilePicPreview} alt="Profile Preview" className="image-preview" />
                    )}
                </div>
                {/* Dynamic Project Fields */}
                {userData.projects.map((project, index) => (
                    <div key={index} className="project-section">
                        <div className="project-section-header">Child {index + 1}
                            <button
                            type="button"
                            className="delete-project-button"
                            onClick={() => handleRemoveProject(index)}
                            >
                                X
                            </button>
                        </div>

                        <input
                            className="project-input"
                            type="text"
                            name={`projectName${index}`}
                            placeholder="Childs Name"
                            onChange={(e) => handleProjectChange(index, 'name', e.target.value)}
                        />
                        <input
                            className="project-input"
                            type="number"
                            name={`projectAge${index}`}
                            placeholder="Age"
                            onChange={(e) => handleProjectChange(index, 'age', e.target.value)}
                        />
                        <div className="file-input-wrapper">
                            <input
                                type="file"
                                id={`projectPic${index}`} // Unique ID for each project
                                onChange={(e) => handleProjectFileChange(e, index)}
                                className="hidden-file-input"
                            />
                            <label htmlFor={`projectPic${index}`} className="file-input-label">
                                {project.selectedProjectPic || "Project Profile Pic"}
                            </label>
                            {projectPicsPreviews[index] && (
                                <img src={projectPicsPreviews[index]} alt={`Project ${index + 1} Preview`} className="image-preview" />
                            )}
                        </div>
                    </div>
                ))}

                <button type="button" onClick={handleAddProject} className="add-project-button">Add Another Child</button>
                <button type="submit">Submit</button>
            </form>
            <button onClick={onClose}>Close</button>
        </div>
    );
};

export default UserFormModal;
