import { openDB } from 'idb';

const DB_NAME = 'imageCache';
const DB_VERSION = 1;

async function openDatabase() {
    return openDB(DB_NAME, DB_VERSION, {
        upgrade(db, oldVersion, newVersion, transaction) {
        },
    }).catch(error => {
        if (error.name === 'VersionError') {
            return openDB(DB_NAME);
        }
        throw error;
    });
}

async function getObjectStore(db, storeName, mode = 'readonly') {
    if (!db.objectStoreNames.contains(storeName)) {
        // If the object store doesn't exist, create it
        const version = db.version + 1;
        db.close();
        return openDB(DB_NAME, version, {
            upgrade(db) {
                db.createObjectStore(storeName);
            },
        }).then(db => db.transaction(storeName, mode).objectStore(storeName));
    }
    return db.transaction(storeName, mode).objectStore(storeName);
}

export async function setImage(projectId, imageName, imageUrl) {
    const STORE_NAME = `${projectId}-images`;
    const key = `${imageName}`;

    try {
        // Fetch the image as a Blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        const db = await openDatabase();
        const store = await getObjectStore(db, STORE_NAME, 'readwrite');
        await store.put(blob, key);  // Store the Blob in IDB
    } catch (error) {
        console.error('Error setting image in IDB:', error);
    }
}

export async function getImage(projectId, imageName) {
    const STORE_NAME = `${projectId}-images`;
    const key = `${imageName}`;

    try {
        const db = await openDatabase();
        const store = await getObjectStore(db, STORE_NAME);
        const blob = await store.get(key);

        if (blob) {
            return URL.createObjectURL(blob);  // Convert Blob to a URL for use in <img>
        }

        return null;
    } catch (error) {
        console.error('Error getting image from IDB:', error);
        return null;
    }
}

export async function clearImageCache(storeName) {
    try {
        const db = await openDatabase();
        const store = await getObjectStore(db, storeName, 'readwrite');
        await store.clear();
    } catch (error) {
        console.error('Error clearing image cache in IDB:', error);
    }
}
