import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        school: '',
        message: '',
    });

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/contact'
            });
        }
    }, []);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/contact'
            });
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'formInteraction',
                formField: name,
                action: 'input'
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await axios.post(`https:/www.mookimoo.com/mookmanage/contact`, formData);
            setIsSubmitted(true);

            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'formSubmission',
                    formName: 'contactForm',
                    status: 'success'
                });
            }
        } catch (error) {
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'formSubmission',
                    formName: 'contactForm',
                    status: 'error',
                    errorMessage: error.message
                });
            }
            console.error('Error submitting contact form:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isSubmitted) {
        return <div className="contact-form-success">Thank you for your message. We will get back to you soon.</div>;
    }

    return (
        <div className="contact-page">
            <div className="intro-text">
                <h1>Interested in MookiMoo?</h1>
                <p>
                    We are in early testing of the concept, and we are running a closed Beta. If you are interested in trying out
                    MookiMoo Drop us a note and we will be in touch.
                </p>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <h2>Contact Us</h2>
                <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                <textarea name="message" placeholder="What interests you about MookiMoo?" value={formData.message} onChange={handleChange} required></textarea>
                <button type="submit" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</button>
            </form>
        </div>
    );
};

export default ContactForm;
