import React, { useState } from 'react';
import '../../css/ComingSoon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {useProject} from "../../utils/ProjectContext";

const ComingSoon = () => {
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [isFormCollapsed, setIsFormCollapsed] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const { BASE_URL} = useProject();
    const [submitStatus, setSubmitStatus] = useState(null);
    const [showThankYouModal, setShowThankYouModal] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${BASE_URL}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: formData.name.split(' ')[0],
                    lastName: formData.name.split(' ')[1] || '',
                    email: formData.email,
                    message: formData.message
                })
            });

            if (response.ok) {
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
                setShowThankYouModal(true);
                toggleForm(); // Hide the form
                setTimeout(() => {
                    setShowThankYouModal(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const toggleForm = () => {
        setIsFormCollapsed(!isFormCollapsed);
    };

    return (
        <div className="coming-soon-page">
            {!videoLoaded && <div className="coming-soon-background-image"></div>}
            <video
                className={`coming-soon-video ${videoLoaded ? 'loaded' : ''}`}
                autoPlay
                loop
                muted
                playsInline
                onCanPlayThrough={handleVideoLoad}
            >
                <source src={process.env.PUBLIC_URL + '/cow.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div
                className={`contact-form-overlay ${isFormCollapsed ? 'collapsed' : ''}`}
                onClick={() => isFormCollapsed && toggleForm()}
            >
                {!isFormCollapsed && (
                    <button
                        className="close-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleForm();
                        }}
                    >
                        ✕
                    </button>
                )}
                <h3>
                    {isFormCollapsed ? (
                        <>
                            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
                            Contact Us
                        </>
                    ) : (
                        'Get in Touch'
                    )}
                </h3>
                <div className={`contact-form-content ${isFormCollapsed ? 'hidden' : ''}`}>
                    <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <textarea
                            name="message"
                            placeholder="Your Message"
                            value={formData.message}
                            onChange={handleInputChange}
                            rows="3"
                            required
                        />
                        <button type="submit">Send Message</button>
                    </form>

                </div>
            </div>
            {showThankYouModal && (
                <>
                    <div className="modal-overlay" />
                    <div className="thank-you-modal">
                        <div className="modal-content">
                            <h3>Thank You! 🎉</h3>
                            <p>We super appreciate you taking your time to get in touch.</p>
                            <p>Have a great day!</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ComingSoon;
