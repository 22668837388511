import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useProject } from "../utils/ProjectContext";
import { ChevronLeft } from 'lucide-react';
import '../css/MessageStyleComments.css';

const MessageStyleComments = ({ bug, user, updateBug, onClose }) => {
    const { BASE_URL } = useProject();
    const [newComment, setNewComment] = useState('');
    const messagesEndRef = useRef(null);

    const allComments = React.useMemo(() => {
        return [
            { type: 'bug', message: bug.message, date: bug.date, name: bug.name },
            ...(bug.adminComments || []).map(comment => ({ ...comment, type: 'admin' })),
            ...(bug.feedbackComments || []).map(comment => ({ ...comment, type: 'user' }))
        ].sort((a, b) => new Date(a.date) - new Date(b.date));
    }, [bug]);

    const isCurrentUser = (name) => name === user.name;
    const isAdmin = user.role === 'admin';

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newComment.trim()) return;
        const commentType = isAdmin ? 'admin' : 'user';
        const endpoint = isAdmin
            ? `${BASE_URL}/feedback/addAdminComment/${bug._id}`
            : `${BASE_URL}/feedback/addUserComment/${bug._id}`;
        try {
            const response = await axios.post(endpoint, { message: newComment });
            const updatedBug = response.data;
            updateBug(updatedBug);
            setNewComment('');
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [allComments]);

    return (
        <div className="message-container message-style-comments">
            <header className="message-header">
                <button className="back-button" onClick={onClose}>
                    <ChevronLeft size={24} />
                </button>
                <h2 className="message-title">Conversation for Bug #{bug.index}</h2>
            </header>
            <div className="message-content-area">
                {allComments.map((comment, index) => (
                    <div
                        key={index}
                        className={`message ${comment.type === 'admin' ? 'admin-message' : 'user-message'} ${isCurrentUser(comment.name) && comment.type !== 'admin' ? 'current-user' : ''}`}
                    >
                        <div className="message-content">
                            {comment.message}
                        </div>
                        <div className="message-info">
                            <span className="message-name">{comment.name}</span>
                            <span className="message-date">{format(new Date(comment.date), 'MMM d, yyyy HH:mm')}</span>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>

            <footer className="message-footer">
                <form onSubmit={handleSubmit} className="new-comment-form">
                    <input
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="Type a new comment..."
                        className="message-style-new-comment-input"
                    />
                    <button type="submit" className="send-comment-btn">Send</button>
                </form>
            </footer>
        </div>
    );
};

export default MessageStyleComments;