import React, { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import axios from 'axios';
import { addDays, subDays, differenceInDays } from 'date-fns';
import { useProject } from '../../utils/ProjectContext';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import WordCloud from 'react-wordcloud';
import '../../css/ReportsPage.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useAuth} from "../../utils/AuthContext";

const ReportsPage = () => {
    const {user } = useAuth();

    const chatTopics = ["Generate Summary", "Get Suggestions"];
    const timeframes = [
        { label: 'Day', value: 'Day' },
        { label: 'Week', value: 'Week' },
        { label: 'Month', value: 'Month' },
        { label: '6 Months', value: '6 Months' },
        { label: '12 Months', value: '12 Months' },
    ];
    const token = localStorage.getItem('token');
    const { BASE_URL, AI_URL, projectId, projectData, projectName, fetchProjectDetails, setProjectId } = useProject();
    const [pieChartData, setPieChartData] = useState(null);
    const [barChartData, setBarChartData] = useState(null);
    const [projectStats, setProjectStats] = useState(null);
    const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date() });
    const [currentOffset, setCurrentOffset] = useState(0);
    const [currentTimeframe, setCurrentTimeframe] = useState('Day');
    const [feedbackSummary, setFeedbackSummary] = useState('');
    const [isLoadingSummary, setIsLoadingSummary] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [copySuccess, setCopySuccess] = useState('');
    const [lineChartData, setLineChartData] = useState(null);
    const [wordCloudData, setWordCloudData] = useState([]);
    const [calendarRange, setCalendarRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
    const [isManualSelection, setIsManualSelection] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [userQuery, setUserQuery] = useState("");  // State to store user query
    const [aiResponse, setAiResponse] = useState("");
    const [isLoadingQuery, setIsLoadingQuery] = useState(false);
    const [sessionDates, setSessionDates] = useState(new Set());


    useEffect(() => {
        if (!projectId) {
            const lastSelectedProjectId = localStorage.getItem('selectedProject');
            if (lastSelectedProjectId) {
                setProjectId(lastSelectedProjectId);
                fetchProjectDetails(lastSelectedProjectId);
            }
        }
    }, []);



    useEffect(() => {
        if (projectId) {
            fetchFeedbackStats(projectId);
        }
    }, [projectId, currentTimeframe]);

    useEffect(() => {
        if (projectId) {
            fetchSessionDates();  // Fetch dates with sessions
        }
    }, [projectId]);

    const fetchSessionDates = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/logs/${projectId}/session-dates`);
            const dates = new Set(response.data.map(session => new Date(session).toISOString().split('T')[0]));
            setSessionDates(dates);
        } catch (error) {
            console.error('Error fetching session dates:', error);
        }
    };

    const fetchSessions = async () => {
        if (projectId) {
            try {
                const response = await axios.get(`${BASE_URL}/api/logs/${projectId}/sessions`, {
                    params: {
                        startDate: dateRange.startDate.toISOString(),
                        endDate: dateRange.endDate.toISOString(),
                    },
                });
                console.log('Sessions:', response.data);
                if (!response.data || response.data.length === 0) {
                    console.warn('No sessions received from the API.');
                    setSessions([]);
                    return;
                }

                setSessions(response.data); // Store sessions
            } catch (error) {
                console.error('Error fetching sessions:', error);
            }
        } else {
            console.warn('No project selected.');
        }
    };


    const handleUserQueryChange = (e) => {
        // This will update a local state if you need to show the current input
        setUserQuery(e.target.value);
    };


    const handleUserQuerySubmit = async () => {
        if (!userQuery.trim()) return; 
        setIsLoadingQuery(true);
        try {
            const response = await axios.post(`${AI_URL}/api/feedback/query`, {
                projectId,
                startDate: dateRange.startDate.toISOString(),
                endDate: dateRange.endDate.toISOString(),
                userQuery,
            });

            let html = DOMPurify.sanitize(marked(response.data.answer));
            setFeedbackSummary(html);
        } catch (error) {
            console.error("Error querying AI:", error);
            setFeedbackSummary("An error occurred while processing your request.");
        } finally {
            setIsLoadingQuery(false);
        }
    };

    const copyToClipboard = () => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = feedbackSummary;
        const text = tempElement.textContent || tempElement.innerText;

        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(text).then(
                () => setCopySuccess('Copied!'),
                (err) => setCopySuccess('Failed to copy.')
            );
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.position = 'fixed';
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.width = '2em';
            textArea.style.height = '2em';
            textArea.style.padding = '0';
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            textArea.style.background = 'transparent';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                setCopySuccess(successful ? 'Copied!' : 'Failed to copy.');
            } catch (err) {
                setCopySuccess('Failed to copy.');
            }

            document.body.removeChild(textArea);
        }

        setTimeout(() => setCopySuccess(''), 2000);
    };

    const resetToToday = () => {
        setCurrentOffset(0);
        updateDateRange('Day');
        setCurrentTimeframe('Day');
    };

    const getFeedbackSummary = async (projectId, startDate, endDate, summaryType) => {
        setIsLoadingSummary(true);
        try {
            const response = await axios.post(`${AI_URL}/api/feedback/summary`, {
                projectId,
                startDate,
                endDate,
                summaryType,
            });

            const html = DOMPurify.sanitize(marked(response.data.summary));
            setFeedbackSummary(html);
        } catch (error) {
            console.error('Error fetching summary:', error);
        } finally {
            setIsLoadingSummary(false);
        }
    };

    const feedbackChartData = {
        labels: ['Positive', 'Negative', 'Neutral'],
        datasets: [
            {
                label: 'Feedback Types',
                data: [projectStats?.positiveFeedback, projectStats?.negativeFeedback, projectStats?.neutralFeedback],
                backgroundColor: ['rgba(119,190,99,0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(255, 206, 86, 0.6)'],
                borderWidth: 1,
            },
        ],
    };

    const fetchFeedbackStats = (projectId) => {
        fetch(`${BASE_URL}/api/projects/${projectId}/feedback-stats`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setProjectStats(data.aggregatedFeedback);

                // Prepare data for charts
                const pieData = {
                    labels: ['Positive', 'Negative', 'Neutral'],
                    datasets: [
                        {
                            data: [
                                data.aggregatedFeedback.positiveCount,
                                data.aggregatedFeedback.negativeCount,
                                data.aggregatedFeedback.neutralCount,
                            ],
                            backgroundColor: ['#75CBB2', '#FF6384', '#FFCE56'],
                            hoverBackgroundColor: ['#4CAF50', '#FF6384', '#FFCE56'],
                        },
                    ],
                };
                setPieChartData(pieData);

                // If you need to create a bar chart, process the data similarly
                const barData = {
                    labels: ['Positive', 'Negative', 'Neutral'],
                    datasets: [
                        {
                            label: 'Feedback Types',
                            data: [
                                data.aggregatedFeedback.positiveCount,
                                data.aggregatedFeedback.negativeCount,
                                data.aggregatedFeedback.neutralCount,
                            ],
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                    ],
                };
                setBarChartData(barData);
            })
            .catch((error) => console.error('Error fetching feedback stats:', error));
    };

    const updateDateRange = (timeframe) => {
        if (isManualSelection) {
            setIsManualSelection(false); // Reset the flag after handling the manual selection
            return; // Skip the update if a manual selection was made
        }

        const endDate = new Date();
        let startDate = new Date();

        switch (timeframe) {
            case 'Day':
                startDate.setDate(endDate.getDate() - 1);
                break;
            case 'Week':
                startDate.setDate(endDate.getDate() - 7);
                break;
            case 'Month':
                startDate.setDate(endDate.getDate() - 30);
                break;
            case '6 Months':
                startDate.setDate(endDate.getDate() - 178);
                break;
            case '12 Months':
                startDate.setDate(endDate.getDate() - 365);
                break;
            default:
                break;
        }

        setDateRangeWithLog({ startDate, endDate }, "updateDateRange");
    };

    const setDateRangeWithLog = (range, fromFunction) => {
        setDateRange(range);
    };

    const handleSelect = (ranges) => {
        setIsManualSelection(true);
        const { startDate, endDate } = ranges.selection;
        setCalendarRange([ranges.selection]);
        setDateRangeWithLog({ startDate, endDate }, "handleSelect");
    };

    const handlePrevious = () => {
        const rangeDifference = differenceInDays(dateRange.endDate, dateRange.startDate) + 1;
        const newStartDate = subDays(dateRange.startDate, rangeDifference);
        const newEndDate = subDays(dateRange.endDate, rangeDifference);

        setDateRangeWithLog({ startDate: newStartDate, endDate: newEndDate }, "handlePrevious");
        setCalendarRange([{ startDate: newStartDate, endDate: newEndDate, key: 'selection' }]);
    };

    const handleNext = () => {
        const rangeDifference = differenceInDays(dateRange.endDate, dateRange.startDate) + 1;
        const newStartDate = addDays(dateRange.startDate, rangeDifference);
        const newEndDate = addDays(dateRange.endDate, rangeDifference);

        setDateRangeWithLog({ startDate: newStartDate, endDate: newEndDate }, "handleNext");
        setCalendarRange([{ startDate: newStartDate, endDate: newEndDate, key: 'selection' }]);
    };

    const processAttentionDataForLineChart = (attentionData) => {
        const timestamps = attentionData.map(item => new Date(item.timestamp).toLocaleTimeString()); // Extract and format timestamps
        const attentionValues = attentionData.map(item => parseFloat(item.value)); // Extract and convert values to float

        return {
            labels: timestamps,  // Timestamps for the x-axis
            datasets: [
                {
                    label: 'Attention Over Time',
                    data: attentionValues,  // Attention values for the y-axis
                    fill: false,
                    borderColor: 'rgba(75, 192, 192, 1)',  // Line color
                    tension: 0.1  // Line tension
                }
            ]
        };
    };

    useEffect(() => {
        if (dateRange.startDate && dateRange.endDate) {
            fetchFeedbackData();
            fetchSessions();
        }
    }, [dateRange]);

    const fetchFeedbackData = async () => {
        if (projectId) {
            try {
                const response = await axios.get(`${BASE_URL}/api/logs/${projectId}/user-sessions`, {
                    params: {
                        startDate: dateRange.startDate.toISOString(),
                        endDate: dateRange.endDate.toISOString(),
                    },
                });

                if (!response.data || response.data.length === 0) {
                    console.warn('No data received from the API.');
                    return;
                }

                // Process the data
                const fullData = response.data;
                const { feedbackResults, attentionResults, emotionResults, arousalValenceResults } = response.data;

                const transformedPieData = transformData(fullData);
                setPieChartData(transformedPieData);

                if (attentionResults && attentionResults.length > 0) {
                    const attentionLineChartData = processAttentionDataForLineChart(attentionResults);
                    setLineChartData(attentionLineChartData);  // Set the line chart data state

                } else {
                    console.warn('No attention data received.');
                }

                if (emotionResults && emotionResults.length > 0) {
                    const emotionBarChartData = processEmotionDataForBarChart(emotionResults);
                    setBarChartData(emotionBarChartData);
                } else {
                    console.warn('No emotion data received.');
                }

                if (arousalValenceResults && arousalValenceResults.length > 0) {
                    const wordCloudData = processArousalValenceDataForWordCloud(arousalValenceResults);
                    setWordCloudData(wordCloudData);  // Corrected to setWordCloudData
                } else {
                    console.warn('No Arousal Valence data received.');
                }
            } catch (error) {
                console.error('Error fetching feedback data:', error);
            }
        } else {
            console.warn('No project selected.');
        }
    };

    const wordCloudOptions = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontSizes: [10, 90],
        fontFamily: 'Impact',
        enableTooltip: true,
    };

    const transformData = (data) => {
        let positiveCount = 0;
        let negativeCount = 0;
        let neutralCount = 0;

        data.feedbackResults.forEach(item => {
            switch (item.type) {
                case 'positive':
                    positiveCount = item.count;
                    break;
                case 'negative':
                    negativeCount = item.count;
                    break;
                case 'neutral':
                    neutralCount = item.count;
                    break;
                default:
                    break;
            }
        });

        return {
            labels: ['Positive', 'Negative', 'Neutral'],
            datasets: [
                {
                    data: [positiveCount, negativeCount, neutralCount],
                    backgroundColor: ['#75CBB2', '#FF6384', '#FFCE56'],
                    hoverBackgroundColor: ['#4CAF50', '#FF6384', '#FFCE56']
                }
            ]
        };
    };

    const handleSessionSelect = (session) => {
        if (!session || !session.feedbacks) {
            console.warn("No feedback data available for the selected session.");
            return;
        }

        const sessionRange = {
            startDate: new Date(session.start),
            endDate: new Date(session.end)
        };

        setDateRange(sessionRange);  // Update date range to selected session
        setCalendarRange([{ ...sessionRange, key: 'selection' }]);

        // Filter the data based on the selected session
        const feedbacks = session.feedbacks || [];
        const attentionResults = feedbacks.filter(feedback => feedback.type === "attention");
        const emotionResults = feedbacks.filter(feedback => feedback.type === "emotion");
        const arousalValenceResults = feedbacks.filter(feedback => feedback.type === "arousal_valence");

        // Update the chart data for the selected session
        if (attentionResults.length > 0) {
            const attentionLineChartData = processAttentionDataForLineChart(attentionResults);
            setLineChartData(attentionLineChartData);  // Set the line chart data state
        } else {
            console.warn("No attention data available for the selected session.");
            setLineChartData(null);
        }

        if (emotionResults.length > 0) {
            const emotionBarChartData = processEmotionDataForBarChart(emotionResults);
            setBarChartData(emotionBarChartData);  // Set the bar chart data state
        } else {
            console.warn("No emotion data available for the selected session.");
            setBarChartData(null);
        }

        if (arousalValenceResults.length > 0) {
            const wordCloudData = processArousalValenceDataForWordCloud(arousalValenceResults);
            setWordCloudData(wordCloudData);  // Set the word cloud data state
        } else {
            console.warn("No Arousal Valence data available for the selected session.");
            setWordCloudData([]);
        }

        if (feedbacks.length > 0) {
            const transformedPieData = transformData(feedbacks);
            setPieChartData(transformedPieData);  // Set the pie chart data state
        } else {
            console.warn("No feedback data available for the selected session.");
            setPieChartData(null);
        }
    };

    const getSessionStyle = (session) => {
        const lastFeedback = session.feedbacks[session.feedbacks.length - 1]; // Get the last feedback
        switch (lastFeedback.value) {
            case 'positive':
                return { backgroundColor: '#75CBB2' }; // Positive - Green
            case 'neutral':
                return { backgroundColor: '#FFCE56' }; // Neutral - Yellow
            case 'negative':
                return { backgroundColor: '#FF6384' }; // Negative - Red
            default:
                return {}; // Default style (no color change)
        }
    };

    const processArousalValenceDataForWordCloud = (arousalValenceResults) => {
        // Create an array of words with their counts
        const wordData = arousalValenceResults.map(item => ({
            text: item.emotion, // Emotion name
            value: item.count   // Count of occurrences
        }));

        return wordData; // Return the array to be used in the word cloud
    };

    const processEmotionDataForBarChart = (emotionData) => {
        // Create a dictionary to count occurrences of each emotion
        const emotionCounts = {};

        emotionData.forEach(item => {
            const emotion = item.emotion;
            if (emotionCounts[emotion]) {
                emotionCounts[emotion] += 1;
            } else {
                emotionCounts[emotion] = 1;
            }
        });

        // Extract labels (emotions) and values (counts) from the dictionary
        const labels = Object.keys(emotionCounts);
        const values = Object.values(emotionCounts);

        return {
            labels: labels,  // Emotions as labels for the x-axis
            datasets: [
                {
                    label: 'Emotions',
                    data: values,  // Counts of each emotion
                    backgroundColor: 'rgba(255, 159, 64, 0.6)',  // Customize as needed
                    borderColor: 'rgba(255, 159, 64, 1)',
                    borderWidth: 1
                }
            ]
        };
    };

    const processFeedbackDataForBarChart = (data) => {
        const labels = [];
        const feedbackValues = [];

        data.feedbackResults.forEach(item => {
            const time = new Date(item.date).toLocaleTimeString();
            labels.push(time);

            let value;
            switch (item.type) {
                case 'positive':
                    value = 3;
                    break;
                case 'negative':
                    value = 1;
                    break;
                case 'neutral':
                    value = 2;
                    break;
                default:
                    value = 0;
            }
            feedbackValues.push(value);
        });

        return {
            labels,
            datasets: [
                {
                    label: 'Feedback Types',
                    data: feedbackValues,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                }
            ]
        };
    };

    const handleChatTopicPress = async (topic) => {
        let summaryType;
        if (topic === "Generate Summary") {
            summaryType = 'Overview';
        } else if (topic === "Get Suggestions") {
            summaryType = 'Suggestion';
        }

        if (summaryType === 'Overview') {
            await getFeedbackSummary(projectId, dateRange.startDate.toISOString(), dateRange.endDate.toISOString(), summaryType);
        }
    };

    const handleTimeframeChange = (selectedOption) => {
        setCurrentTimeframe(selectedOption.value);
        updateDateRange(selectedOption.value);
    };

    const chartOptions = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        size: 14,
                    },
                    boxWidth: 20,
                    padding: 20,
                    color: '#333333',
                },
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                titleFont: {
                    size: 16,
                },
                bodyFont: {
                    size: 14,
                },
                borderColor: '#dddddd',
                borderWidth: 1,
            },
        },
        width: '100%',
    };

    const calculateDuration = (start, end) => {
        const startTime = new Date(start);
        const endTime = new Date(end);
        const durationMs = endTime - startTime;
        const minutes = Math.floor((durationMs / 1000) / 60);
        const seconds = Math.floor((durationMs / 1000) % 60);
        return `${minutes}m ${seconds}s`;
    };


    return (
        <div className="reports-page">
            <h2 className="page-title">Reports for: {projectName}</h2>
            <div className="calendar-sessions" >
            <div>
                <DateRangePicker
                ranges={calendarRange}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                editableDateInputs={true}
                showPreview={true}
                direction="horizontal"
                months={2}
                dayClassNames={(date) => {
                    const dateString = date.toISOString().split('T')[0];
                    return sessionDates.has(dateString) ? "session-day" : "";
                }}
            />
                <div className="calendar-buttons">
                    <button onClick={handlePrevious}>Previous</button>
                    <button onClick={handleNext}>Next</button>
                </div>
            </div>
                <div>
                <h3>Sessions</h3>
                <div className="session-list">

                    {sessions.length > 0 ? (
                        <ul>
                            {sessions.map((session, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleSessionSelect(session)}
                                    style={getSessionStyle(session)}
                                >
                                    {`Session ${index + 1} - ${new Date(session.start).toLocaleString()} ${calculateDuration(session.start, session.end)}`}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No sessions available for this date range.</p>
                    )}
                </div>
                </div>

            </div>
            <div className="chart-row">
                <div className="chart-wrapper">
                    {barChartData && (
                        <Bar data={barChartData} options={{ responsive: true }} />
                    )}
                </div>
                <div className="chart-wrapper">
                    {lineChartData && (
                        <Line data={lineChartData} options={{ responsive: true }} />
                    )}
                </div>

                <div className="word-cloud-wrapper">
                    {wordCloudData.length > 0 ? (
                        <WordCloud words={wordCloudData} options={wordCloudOptions} />
                    ) : (
                        <p>No Arousal Valence data available for this time range.</p>
                    )}
                </div>

                {pieChartData && (
                    <div className="chart-wrapper">
                        <h3 style={{ textAlign: 'center' }} >Parental Assessment</h3>
                        <Pie data={pieChartData} options={chartOptions} />
                    </div>
                )}
            </div>
            {/* Chat topics and AI summary */}
            <div className="summary-container">
                <h4 className="summary-title">Chat with AI Reports Bot</h4>
                <div className="timeframe-buttons">
                    {chatTopics.map((topic, index) => (
                        <button key={index} onClick={() => handleChatTopicPress(topic)}>
                            {topic}
                        </button>
                    ))}
                </div>
                <div className="user-query-container">
                    <div className="input-with-button">
                        <input
                            type="text"
                            placeholder="Ask your question here..."
                            value={userQuery}
                            onChange={handleUserQueryChange}
                        />
                        <button onClick={handleUserQuerySubmit}>Send</button>
                    </div>
                </div>
                {isLoadingSummary ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <div className="summary-content" dangerouslySetInnerHTML={{ __html: feedbackSummary }} />
                        <button className="copy-button" onClick={copyToClipboard}>Copy to Clipboard</button>
                        {copySuccess && <p className="copy-feedback">{copySuccess}</p>}
                    </>
                )}
            </div>


        </div>
    );
};

export default ReportsPage;