import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import '../../css/DraggableChoice.css';
import { getImage, setImage } from '../../utils/idbHelpers';
import {useProject} from "../../utils/ProjectContext";

const ItemTypes = {
    CHOICE: 'choice',
};

const DraggableChoice = ({ choice, index, moveChoice, onChoiceSelect, isSelected, onDelete, projectData  }) => {
    const {projectId, BASE_URL} = useProject();
    const [, ref] = useDrag({
        type: ItemTypes.CHOICE,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemTypes.CHOICE,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveChoice(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const [isHovered, setIsHovered] = useState();
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            const fetchedImages = await Promise.all(
                choice.images.map(async (imageId) => {
                    // Handle dummy images with placeholder URL
                    if (imageId.startsWith('dummy')) {
                        return { _id: imageId, imageUrl: 'https://placehold.co/100' };
                    }

                    // Look for the image details in the project data
                    const imageDetails = projectData.project.data.images.find(image => image._id === imageId);

                    if (imageDetails) {
                        // Try to get the image from IndexedDB first
                        let cachedImage = await getImage(projectId, imageDetails.name);

                        if (cachedImage) {
                            // If cached, return it
                            return { ...imageDetails, imageUrl: cachedImage };
                        } else {
                            // Otherwise, fetch from the network
                            try {
                                const response = await fetch(`${BASE_URL}/project/${projectData.project._id}/image/${imageDetails.name}`, {
                                    headers: {
                                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    },
                                });

                                const data = await response.json();

                                // Store the image in IndexedDB for future use
                                await setImage(projectId, imageDetails.name, data.imageUrl);

                                // Return the fetched image
                                return { ...imageDetails, imageUrl: data.imageUrl };
                            } catch (error) {
                                console.error('Error fetching image:', error);
                                return null;
                            }
                        }
                    } else {
                        console.error(`Image with id ${imageId} not found`);
                        return null;
                    }
                })
            );

            // Filter out any null results and set state
            setImages(fetchedImages.filter(image => image !== null));
        };

        fetchImages();
    }, [choice.images, projectData, projectId, BASE_URL]);

    const handleSelect = () => {
        onChoiceSelect(choice._id);
    };

    return (
        <div
            ref={(node) => ref(drop(node))}
            className={`choice-item ${isSelected ? 'selected' : ''} ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleSelect}
        >
            <div style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100px'
            }}>{choice.name}</div>
            <div className="choice-images">
                {images.length === 0 && (
                    <div className="placeholder"></div>
                )}
                {images.map((image, idx) => (
                    <img key={idx} src={image.imageUrl} alt={`Choice Image ${idx}`} className="choice-image" />
                ))}
            </div>
            <button onClick={() => onDelete(choice._id)} className="delete-choice-btn">X</button>
        </div>
    );
};

export default DraggableChoice;
