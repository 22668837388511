import React from 'react';

const modalStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '20px',
    zIndex: 1000,
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
};

const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
};

const Modal = ({ message, onClose }) => {
    return (
        <>
            <div style={overlayStyles} />
            <div style={modalStyles}>
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </>
    );
};

export default Modal;