import React, { useContext, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import '../../css/Navbar.css';
import logo from '../../assets/mookimoo_logo_transparent.png';
import { useAuth } from '../../utils/AuthContext';
import '../../css/Navbar.css';
import { ThemeContext } from '../../utils/ThemeProvider';

const Navbar = () => {
    const navigate = useNavigate();
    const { user, logout, bugCount } = useAuth();
    const { theme, setTheme } = useContext(ThemeContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleThemeChange = (e) => {
        setTheme(e.target.value);
        localStorage.setItem('theme-choice', e.target.value);
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <Link to="/" className="navbar-brand-link">
                    <img src={logo} alt="MookiMoo Logo" className="navbar-logo" />
                    {!user && <span className="navbar-title">MookiMoo</span>}
                    {user && <span className="navbar-title">MookiMoo Manager</span>}
                </Link>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
                </div>
            </div>
            <div className={`navbar-links ${menuOpen ? 'open' : ''}`}>
                {user && (
                    <div className="theme-group">
                        <select value={theme} onChange={handleThemeChange} className="theme-selector">
                            <option value="app-style">App Style</option>
                            <option value="earthy-tones">Earthy Tones</option>
                            <option value="forest-calm">Forest Calm</option>
                            <option value="ocean-breeze">Ocean Breeze</option>
                            <option value="sunset-glow">Sunset Glow</option>
                            <option value="citrus-fresh">Citrus Fresh</option>
                            <option value="cool-mint">Cool Mint</option>

                        </select>
                    </div>
                )}
                {user && user.role === 'admin' && (
                    <>
                        <NavLink to="/home" className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>
                        <NavLink to="/users" className={({ isActive }) => isActive ? 'active' : ''}>Users</NavLink>
                        <NavLink to="/library-manager" className={({ isActive }) => isActive ? 'active' : ''}>Library Manager</NavLink>
                        <NavLink to="/newsletter" className={({ isActive }) => isActive ? 'active' : ''}>Newsletter</NavLink>
                        <NavLink to="/reports" className={({ isActive }) => isActive ? 'active' : ''}>Reports</NavLink>
                        <NavLink to="/bugs" className={({ isActive }) => isActive ? 'active' : ''}>Bugs</NavLink>
                    </>
                )}
                {user && user.role === 'user' && (
                    <>
                        <NavLink to="/home" className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>
                        <NavLink to="/dash" className={({ isActive }) => isActive ? 'active' : ''}>Dashboard</NavLink>
                        <NavLink to="/reports" className={({ isActive }) => isActive ? 'active' : ''}>Reports</NavLink>
                        <NavLink to="/bugs" className={({ isActive }) => isActive ? 'active' : ''}>Bugs</NavLink>
                    </>
                )}

                {user ? (

                    <button onClick={handleLogout} className="navbar-logout-button">Logout</button>
                ) : (
                    <>
                        {/*<NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>About</NavLink>*/}
                        {/*<NavLink to="/info" className={({ isActive }) => isActive ? 'active' : ''}>Info</NavLink>*/}
                        <NavLink to="/login" className={({ isActive }) => isActive ? 'active' : ''}>Login</NavLink>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
