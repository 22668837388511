import React, { useEffect, useState } from 'react';
import '../../css/UserList.css';
import UserDetailsModal from '../../modals/UserDetailsModal';
import LoginHistoryModal from '../../modals/LoginHistoryModal';
import { IonIcon } from '@ionic/react';
import { timeOutline } from 'ionicons/icons';

const BASE_URL = 'https://www.mookimoo.com/mookmanage';

const UsersList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const handleHistoryClick = (user) => {
        setSelectedUser(user);
        setIsHistoryModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
    };

    const closeHistoryModal = () => {
        setIsHistoryModalOpen(false);
        setSelectedUser(null);
    };

    const fetchAndSortUsers = () => {
        setIsLoading(true);
        fetch(`${BASE_URL}/api/users`)
            .then(response => response.json())
            .then(data => {
                if (sortConfig.key) {
                    data.sort((a, b) => {
                        if (a[sortConfig.key] < b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? -1 : 1;
                        }
                        if (a[sortConfig.key] > b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? 1 : -1;
                        }
                        return 0;
                    });
                }
                setUsers(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    };

    useEffect(fetchAndSortUsers, [sortConfig]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div className="users-list-container">
            <button onClick={fetchAndSortUsers} className="refresh-button">
                Refresh
            </button>

            <div className="users-list">
                <div className="user-header">
                    <button onClick={() => requestSort('name')}>Name</button>
                    <button onClick={() => requestSort('email')}>Email</button>
                    <button onClick={() => requestSort('startDate')}>Start Date</button>
                    <button onClick={() => requestSort('lastLogin')}>Last Login</button>
                    <button onClick={() => requestSort('loginCount')}>Login Count</button>
                    <button>History</button>
                </div>
                {users.map(user => (
                    <div key={user._id} className="user-row" onClick={() => handleUserClick(user)}>
                        <div>{user.name}</div>
                        <div>{user.email}</div>
                        <div>{new Date(user.startDate).toLocaleDateString()}</div>
                        <div>{user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'Never'}</div>
                        <div>{user.loginCount ? user.loginCount : 0}</div>
                        <div onClick={(e) => { e.stopPropagation(); handleHistoryClick(user); }}>
                            <IonIcon icon={timeOutline} />
                        </div>
                    </div>
                ))}
            </div>
            {isModalOpen && selectedUser && (
                <UserDetailsModal className="user-details-modal" user={selectedUser} onClose={closeModal} />
            )}
            {isHistoryModalOpen && selectedUser && (
                <LoginHistoryModal user={selectedUser} onClose={closeHistoryModal} />
            )}
        </div>
    );
};

export default UsersList;
