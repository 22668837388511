import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    BarElement,
    ArcElement,
} from 'chart.js';
import {useParams} from "react-router-dom";
import '../../css/SessionChart.css'
import {useProject} from "../../utils/ProjectContext";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ArcElement
);



const SessionChart = () => {
    const {BASE_URL} = useProject();
    const { projectId } = useParams();
    const token = localStorage.getItem('token');
    const [sessionData, setSessionData] = useState([]);
    const [sessionChartData, setSessionChartData] = useState({ datasets: [] });
    const [timeUnit, setTimeUnit] = useState('day');
    const [currentStart, setCurrentStart] = useState(new Date());
    const [currentEnd, setCurrentEnd] = useState(new Date() + 1);
    const [sessionChartOptions, setSessionChartOptions] = useState({
        scales: {
            x: {
                barPercentage: 9,
                type: 'time',
                time: {
                    unit: timeUnit,
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const durationInMilliseconds = tooltipItem.yLabel * 60000;
                    const minutes = Math.floor(durationInMilliseconds / 60000);
                    const seconds = Math.floor((durationInMilliseconds % 60000) / 1000)
                        .toString()
                        .padStart(2, '0');
                    return `Duration: ${minutes}:${seconds}`;
                },
            },
        },
    });

    const updateTimeScale = (newTimeUnit) => {
        setTimeUnit(newTimeUnit);
        let min, max;
        const now = new Date();

        switch (newTimeUnit) {
            case 'hour':
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
                max = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1);
                break;
            case 'day':
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                max = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
                break;
            case 'week':
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                max = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
                break;
            case 'month':
                min = new Date(now.getFullYear(), now.getMonth(), 1);
                max = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                break;
            case 'year':
                min = new Date(now.getFullYear(), 0, 1);
                max = new Date(now.getFullYear() + 1, 0, 1);
                break;
            default:
                break;
        }

        setCurrentStart(min);
        setCurrentEnd(max);

        setSessionChartOptions((prevOptions) => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                x: {
                    ...prevOptions.scales.x,
                    min: min,
                    max: max,
                },
            },
        }));
    };

    const fetchReportData = (projectId) => {
        const endpoint = `${BASE_URL}/api/reports/${projectId}/sessions`;

        return fetch(endpoint, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log('Data from server:', data);

                if (!data) {
                    throw new Error('Data format is incorrect, expected array of sessions');
                }

                try {
                    const transformedData = data.map((session) => {
                        console.log('Processing session:', session);
                        const sessionStart = new Date(session.start);
                        const durationInMilliseconds = new Date(session.end) - new Date(session.start);
                        const durationInMinutes = durationInMilliseconds / 60000;
                        return { x: sessionStart, y: durationInMinutes };
                    });

                    console.log('Transformed data:', transformedData);
                    return transformedData;
                } catch (transformationError) {
                    console.error('Error during data transformation:', transformationError);
                    throw transformationError;
                }
            })
            .catch((error) => {
                console.error('Error fetching session data:', error);
                throw error;
            });
    };
    const fetchSessionData = async (projectId, timeFrame = 'day') => {
        try {
            const transformedData = await fetchReportData(projectId);
            // Assuming setSessionData is a state-setting function from a React hook
            setSessionData(transformedData);
        } catch (error) {
            console.error('Error fetching and transforming session data for SessionChart:', error);
        }
    };



    useEffect(() => {
        if (sessionData.length > 0) {
            const transformedData = sessionData.map((session) => ({
                x: new Date(session.x),
                y: session.y,
            }));
            setSessionChartData({
                datasets: [
                    {
                        label: 'Session Duration',
                        data: transformedData,
                        backgroundColor: 'rgba(80,117,28, 0.5)',
                        borderColor: 'rgba(80,117,28, 1)',
                        barThickness: '16',
                    },
                ],
            });
        }
    }, [sessionData]);

    useEffect(() => {
        fetchSessionData(projectId, timeUnit);
    }, [projectId, timeUnit]);

    const handleTimeScaleChange = (newTimeUnit) => {
        setTimeUnit(newTimeUnit);
        updateTimeScale(newTimeUnit);
    };

    const updateCurrentPeriod = (direction, currentTimeUnit) => {
        let newStart = new Date(currentStart);
        let newEnd = new Date(currentEnd);

        switch (currentTimeUnit) {
            case 'hour':
                newStart.setHours(newStart.getHours() + direction);
                newEnd.setHours(newEnd.getHours() + direction);
                break;
            case 'day':
                newStart.setDate(newStart.getDate() + direction);
                newEnd.setDate(newEnd.getDate() + direction);
                break;
            case 'week':
                newStart.setDate(newStart.getDate() + 7 * direction);
                newEnd.setDate(newEnd.getDate() + 7 * direction);
                break;
            case 'month':
                newStart.setMonth(newStart.getMonth() + direction);
                newEnd.setMonth(newEnd.getMonth() + direction);
                break;
            case 'year':
                newStart.setFullYear(newStart.getFullYear() + direction);
                newEnd.setFullYear(newEnd.getFullYear() + direction);
                break;
            default:
                break;
        }

        setCurrentStart(newStart);
        setCurrentEnd(newEnd);

        setSessionChartOptions((prevOptions) => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                x: {
                    ...prevOptions.scales.x,
                    min: newStart,
                    max: newEnd,
                },
            },
        }));
    };

    const handlePrevious = () => {
        updateCurrentPeriod(-1, timeUnit);
    };

    const handleNext = () => {
        updateCurrentPeriod(1, timeUnit);
    };

    return (
        <div className='session-chart-container'>
            <div>
                <button onClick={handlePrevious}>Previous</button>
                <button onClick={() => handleTimeScaleChange('hour')}>Hour</button>
                <button onClick={() => handleTimeScaleChange('day')}>Day</button>
                <button onClick={() => handleTimeScaleChange('week')}>Week</button>
                <button onClick={() => handleTimeScaleChange('month')}>Month</button>
                <button onClick={() => handleTimeScaleChange('year')}>Year</button>
                <button onClick={handleNext}>Next</button>
            </div>
            <div>
                <Bar data={sessionChartData} options={sessionChartOptions} />
            </div>
        </div>
    );
};

export default SessionChart;
