import React, { useEffect, useState } from 'react';
import '../css/UserDetailsModal.css';

const BASE_URL = 'https://www.mookimoo.com/mookmanage';

const UserDetailsModal = ({ user, onClose }) => {
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        fetch(`${BASE_URL}/api/users/${user._id}`)
            .then(response => response.json())
            .then(data => {
                setUserDetails(data.user);
            })
            .catch(error => console.error('Error fetching user details:', error));
    }, [user._id]);

    if (!userDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="user-details-modal">
            <div className="modal-content">
                <h2>User Details</h2>
                <p>Name: {userDetails.name}</p>
                <p>Email: {userDetails.email}</p>
                <p>Start Date: {new Date(userDetails.startDate).toLocaleDateString()}</p>
                <p>Last Login: {userDetails.lastLogin ? new Date(userDetails.lastLogin).toLocaleString() : 'Never'}</p>
                <p>Login Count: {userDetails.loginCount ? userDetails.loginCount.number : 0}</p>
                <p>Number of Children: {userDetails.ownedProjects.length}</p>
                <ul>
                    {userDetails.ownedProjects.map((project, index) => (
                        <li key={index}>
                            {project.name}
                            {/*{project.imageUrl && (*/}
                            {/*    <img*/}
                            {/*        src={project.imageUrl}*/}
                            {/*        alt={project.name}*/}
                            {/*        style={{ width: '50px', height: '50px' }} // Thumbnail size*/}
                            {/*    />*/}
                            {/*)}*/}
                        </li>
                    ))}
                </ul>
                <button className="modal-close-btn" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default UserDetailsModal;
