import React, { useState, useCallback, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useProject } from "../../utils/ProjectContext";
import '../../css/ActivityEditorModal.css';
import { getImage, setImage } from '../../utils/idbHelpers';
import ImageSelectionModal from '../../modals/ImageSelectionModal';

const COLOR_OPTIONS = [
    '#FF7F50',      // Bright and visible, complements primary
    '#8FBC8F',       // Natural green that works with background
    '#DDA0DD',       // Soft purple that contrasts with greens
    '#DAA520',  // Rich yellow that complements accent
    '#87CEEB',    // Light blue for contrast
    '#E2725B', // Earthier version of primary
    '#E6E6FA',   // Light purple for subtle items
    '#40808C'        // Deep blue-green that works with secondary
]

// Default random color function if none is provided
const defaultRandomColor = () => {
    return COLOR_OPTIONS[Math.floor(Math.random() * COLOR_OPTIONS.length)];
};
const DraggableActivityImage = ({
                                    imageId,
                                    imageUrl,
                                    index,
                                    moveImage,
                                    removeImage,
                                    onDescriptionChange,
                                    onMetadataChange,
                                    color,
                                    description = '',
                                    metadata = '',
                                    showInputs
                                }) => {
    const [, drag, preview] = useDrag({
        type: 'activity-image',
        item: () => ({ imageId, index })
    });

    const [, drop] = useDrop({
        accept: 'activity-image',
        hover(item, monitor) {
            if (item.index !== index) {
                moveImage(item.index, index);
                item.index = index;
            }
        }
    });

    return (
        <div
            ref={(node) => drop(preview(node))}
            className="selected-image-item"
            style={{ backgroundColor: color }}
        >
            <div ref={drag} className="image-thumbnail-wrapper">
                <div className="activity-image-inputs-container">
                    <input
                        type="text"
                        value={description}
                        onChange={(e) => onDescriptionChange(imageId, e.target.value)}
                        placeholder="Enter step Name"
                        className="step-description-input"
                    />

                    {showInputs && (
                        <div className="step-inputs">
                        <textarea
                            type="text"
                            value={metadata}
                            onChange={(e) => onMetadataChange(imageId, e.target.value)}
                            placeholder="Enter step Instruction"
                            className="step-metadata-input"
                        />
                        </div>
                    )}
                </div>
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt={`Step ${index + 1}`}
                        className="selected-thumbnail"
                    />
                ) : (
                    <div className="loading-placeholder">Loading...</div>
                )}
            </div>

        </div>
    );
};


const ActivityEditorModal = ({
                                 plan,
                                 isVisible,
                                 onClose,
                                 activity,
                                 selectedImage,
                                 onSave,
                                 randomColor = defaultRandomColor,
                                 handleRemoveImage,

                             }) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [stepDescriptions, setStepDescriptions] = useState({});
    const [imageSteps, setImageSteps] = useState([]);
    const { projectData, selectedProject, setProjectData, downloadedImages, BASE_URL, fetchObjectId, showInputs, setShowInputs } = useProject();
    const [isImageSourceModalVisible, setIsImageSourceModalVisible] = useState(false);
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [activityName, setActivityName] = useState('');
    const [description, setDescription] = useState('');
    const [duration, setDuration] = useState('0');
    const [steps, setSteps] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [imageUrls, setImageUrls] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [activities, setActivities] = useState();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [initialState, setInitialState] = useState(null);
    const [activityColor, setActivityColor] = useState(() =>
        activity ? activity.color : randomColor()
    );
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [stepMetadata, setStepMetadata] = useState({});
    const handleStepMetadataChange = (imageId, newMetadata) => {
        setStepMetadata(prev => {
            const newState = {
                ...prev,
                [imageId]: newMetadata
            };
            checkForUnsavedChanges(newState);
            return newState;
        });
    };
    const handleSelectImage = async (imageId) => {
        const newImages = [...selectedImages, imageId];
        setSelectedImages(newImages);
        setIsImageModalOpen(false); // Close the modal after selection
        setHasUnsavedChanges(true);
    };

    useEffect(() => {
        localStorage.setItem('activityEditorShowInputs', JSON.stringify(showInputs));
    }, [showInputs]);

    useEffect(() => {
        if (isVisible && activity) {
            // For existing activities, always use the activity's color
            const initialStateData = {
                name: activity.name || '',
                color: activity.color, // Remove the randomColor fallback
                description: activity.description || '',
                duration: (activity.duration || 0).toString(),
                images: activity.images || [],
                stepDescriptions: {}
            };

            // Initialize step descriptions from activity steps
            activity.steps?.forEach(step => {
                if (step.imageId) {
                    initialStateData.stepDescriptions[step.imageId] = step.description || '';
                }
            });

            console.group('Setting Initial State');
            console.log('Activity Data:', activity);
            console.log('Initial State Data:', initialStateData);
            console.groupEnd();

            setInitialState(initialStateData);
            setActivityName(initialStateData.name);
            setActivityColor(initialStateData.color); // Will use activity's color
            setDescription(initialStateData.description);
            setDuration(initialStateData.duration);
            setSelectedImages(initialStateData.images);
            setStepDescriptions(initialStateData.stepDescriptions);
            setIsCreatingNew(false);
            setHasUnsavedChanges(false);
        } else if (!isVisible) {
            console.log('Resetting form - modal not visible');
            resetForm();
        }
    }, [isVisible, activity]);

    useEffect(() => {
        if (isVisible && activity) {
            setActivityName(activity.name || '');
            setActivityColor(activity.color || randomColor());
            setDescription(activity.description || '');
            setDuration(activity.duration?.toString() || '0');
            setSteps(activity.steps || []);
            setSelectedImages(activity.images || []);

            // Initialize step descriptions
            const descriptions = {};
            activity.steps?.forEach(step => {
                if (step.imageId) {
                    descriptions[step.imageId] = step.description || '';
                }
            });
            setStepDescriptions(descriptions);

            setIsCreatingNew(false);
        } else if (!isVisible) {
            resetForm();
        }
    }, [isVisible, activity]);

    useEffect(() => {
        if (activity?.steps) {
            const descriptions = {};
            activity.steps.forEach(step => {
                if (step.imageId) {
                    descriptions[step.imageId] = step.description || '';
                }
            });
            setStepDescriptions(descriptions);
        }
    }, [activity]);

    useEffect(() => {
        console.log('images', projectData.project.data.activities)
    }, []);

    useEffect(() => {
        const loadImages = async () => {
            if (!activity?.images?.length) return;

            const token = localStorage.getItem('token');
            const projectId = projectData.project._id;
            const loadedUrls = {};

            await Promise.all(activity.images.map(async (imageId) => {
                const imageDetails = projectData.project.data.images.find(img => img._id === imageId);
                if (!imageDetails) return;

                // Try to get cached image first
                let cachedImage = await getImage(projectId, imageDetails.name);

                if (cachedImage) {
                    loadedUrls[imageId] = cachedImage;
                } else {
                    try {
                        const response = await fetch(
                            `${BASE_URL}/project/${projectId}/image/${imageDetails.name}`,
                            {
                                headers: { 'Authorization': `Bearer ${token}` },
                            }
                        );
                        const data = await response.json();
                        await setImage(projectId, imageDetails.name, data.imageUrl);
                        loadedUrls[imageId] = data.imageUrl;
                    } catch (error) {
                        console.error('Error fetching image:', error);
                    }
                }
            }));

            setImageUrls(loadedUrls);
        };

        if (isVisible && activity) {
            loadImages();
        }
    }, [isVisible, activity, activity?.images, projectData, BASE_URL]);

    const handleStepDescriptionChange = (imageId, newDescription) => {
        setStepDescriptions(prev => {
            const newState = {
                ...prev,
                [imageId]: newDescription
            };
            checkForUnsavedChanges(newState);
            return newState;
        });
    };

    const checkForUnsavedChanges = (newStepDescriptions = stepDescriptions) => {
        if (!initialState) return false;

        // Convert current duration to string for comparison
        const currentDuration = duration.toString();

        // Deep compare images arrays
        const imagesEqual = JSON.stringify(selectedImages) === JSON.stringify(initialState.images);

        // Deep compare step descriptions
        const descriptionsEqual = Object.keys(newStepDescriptions).length === Object.keys(initialState.stepDescriptions).length &&
            Object.keys(newStepDescriptions).every(key =>
                newStepDescriptions[key] === initialState.stepDescriptions[key]
            );

        console.group('Change Detection Check');
        console.log('Initial State:', initialState);
        console.log('Current Values:', {
            name: activityName,
            color: activityColor,
            description,
            duration: currentDuration,
            images: selectedImages,
            stepDescriptions: newStepDescriptions
        });

        const changes = {
            name: activityName !== initialState.name,
            color: activityColor !== initialState.color,
            description: description !== initialState.description,
            duration: currentDuration !== initialState.duration,
            images: !imagesEqual,
            descriptions: !descriptionsEqual
        };

        console.log('Changes Detected:', changes);

        if (changes.name) console.log('Name changed:', { initial: initialState.name, current: activityName });
        if (changes.color) console.log('Color changed:', { initial: initialState.color, current: activityColor });
        if (changes.description) console.log('Description changed:', { initial: initialState.description, current: description });
        if (changes.duration) console.log('Duration changed:', { initial: initialState.duration, current: currentDuration });
        if (changes.images) console.log('Images changed:', { initial: initialState.images, current: selectedImages });
        if (changes.descriptions) console.log('Descriptions changed:', {
            initial: initialState.stepDescriptions,
            current: newStepDescriptions
        });

        const hasChanges = Object.values(changes).some(Boolean);
        console.log('Has Unsaved Changes:', hasChanges);
        console.groupEnd();

        setHasUnsavedChanges(hasChanges);
        return hasChanges;
    };

    useEffect(() => {
        if (isVisible && activity) {
            const initialStateData = {
                name: activity.name || '',
                color: activity.color,
                description: activity.description || '',
                duration: (activity.duration || 0).toString(),
                images: activity.images || [],
                stepDescriptions: {},
                stepMetadata: {}
            };

            // Initialize step descriptions and metadata from activity steps
            activity.steps?.forEach(step => {
                if (step.imageId) {
                    initialStateData.stepDescriptions[step.imageId] = step.description || '';
                    initialStateData.stepMetadata[step.imageId] = step.instruction || '';  // Add this line
                }
            });

            setInitialState(initialStateData);
            setActivityName(initialStateData.name);
            setActivityColor(initialStateData.color);
            setDescription(initialStateData.description);
            setDuration(initialStateData.duration);
            setSelectedImages(initialStateData.images);
            setStepDescriptions(initialStateData.stepDescriptions);
            setStepMetadata(initialStateData.stepMetadata);  // Add this line
            setIsCreatingNew(false);
            setHasUnsavedChanges(false);
        }
    }, [isVisible, activity]);


    const handleClose = () => {
        console.group('Handle Close');
        const hasChanges = checkForUnsavedChanges();
        console.log('Checked for changes:', hasChanges);

        if (hasChanges === true) {
            const userChoice = window.confirm(
                "You have unsaved changes. Are you sure you want to close without saving?"
            );
            console.log('User choice on close:', userChoice);
            if (userChoice) {
                console.log('User confirmed close, resetting form');
                resetForm();
                onClose();
            } else {
                console.log('User cancelled close, keeping form state');
            }
        } else {
            console.log('No changes detected, closing directly');
            resetForm();
            onClose();
        }
        console.groupEnd();
    };


    const handleDeleteActivity = (activityToDelete) => {
        // Check if activity is used in any plans
        const isActivityUsed = projectData.plans?.some(plan =>
            plan.images?.some(image =>
                typeof image === 'object' && image.activityId === activityToDelete._id
            )
        );

        if (isActivityUsed) {
            alert("This activity is currently being used in one or more schedules. Please remove it from all schedules before deleting.");
            return;
        }

        if (window.confirm(`Are you sure you want to delete "${activityToDelete.name}"? This cannot be undone.`)) {
            try {
                // Remove activity from activities list
                const updatedActivities = activities.filter(
                    act => act._id !== activityToDelete._id
                );

                // Update project data
                const updatedProjectData = {
                    ...projectData,
                    activities: updatedActivities
                };

                // Update states
                setActivities(updatedActivities);
                setProjectData(updatedProjectData);
                onClose();
                alert("Activity deleted successfully");
            } catch (error) {
                console.error('Error deleting activity:', error);
                alert("Failed to delete activity. Please try again.");
            }
        }
    };

    const handleRemoveActivityFromSchedule = () => {
        if (window.confirm("This will remove the activity from this schedule item but won't delete the activity itself. Continue?")) {
            try {
                const updatedProjectData = {
                    ...projectData,
                    project: {
                        ...projectData.project,
                        data: {
                            ...projectData.project.data,
                            plans: projectData.project.data.plans.map(plan => ({
                                ...plan,
                                images: plan.images.map(img => {
                                    if (img.imageId === selectedImage._id) {
                                        return {
                                            ...img,
                                            activityId: null
                                        };
                                    }
                                    return img;
                                })
                            }))
                        }
                    }
                };

                setProjectData(updatedProjectData);
                onClose(); // Only close here because we're removing the activity
            } catch (error) {
                console.error('Error removing activity from schedule:', error);
                alert('Failed to remove activity from schedule. Please try again.');
            }
        }
    };


    const moveImage = (fromIndex, toIndex) => {
        const newImages = [...selectedImages];
        const [movedImage] = newImages.splice(fromIndex, 1);
        newImages.splice(toIndex, 0, movedImage);
        setSelectedImages(newImages);
    };
    const updateOrCreateActivity = async () => {
        if (!activityName.trim()) {
            alert('Please enter a name for the activity');
            return;
        }

        setIsLoading(true);

        try {
            const newId = activity?._id || await fetchObjectId();

            const finalSteps = selectedImages.map((imageId, index) => {
                const imageDetails = projectData.project.data.images.find(img => img._id === imageId);
                const defaultDescription = imageDetails ? removeImageExtension(imageDetails.name) : '';

                return {
                    order: index,
                    description: stepDescriptions[imageId] || '',
                    instruction: stepMetadata[imageId] || '',  // Add this line
                    duration: 0,
                    imageId: imageId
                };
            });

            const updatedActivity = {
                _id: newId,
                name: activityName,
                color: activityColor,
                description,
                instruction: stepMetadata,
                duration: parseInt(duration) || 0,
                steps: finalSteps,
                images: selectedImages,
                isTemplate: activity?.isTemplate || false,
                category: activity?.category || '',
                places: activity?.places || [],
                lastModified: new Date().toISOString(),
                created: activity?.created || new Date().toISOString()
            };

            const currentActivities = Array.isArray(projectData.activities)
                ? projectData.activities
                : [];

            const existingIndex = currentActivities.findIndex(a => a._id === newId);
            const newActivities = [...currentActivities];

            if (existingIndex >= 0) {
                newActivities[existingIndex] = updatedActivity;
            } else {
                newActivities.push(updatedActivity);
            }

            // Update project data
            setProjectData({
                ...projectData,
                activities: newActivities
            });

            // Update activities state
            setActivities(newActivities);

            // Call onSave but pass a flag to indicate we don't want to close
            onSave(updatedActivity, { keepOpen: true });

            // Update initial state to match the saved state
            setInitialState({
                name: updatedActivity.name,
                color: updatedActivity.color,
                description: updatedActivity.description,
                duration: updatedActivity.duration.toString(),
                steps: updatedActivity.steps,
                images: updatedActivity.images,
                stepDescriptions: { ...stepDescriptions }
            });

            setHasUnsavedChanges(false);
            alert('Activity saved successfully!');

        } catch (error) {
            console.error('Error saving activity:', error);
            alert('Failed to save activity changes. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };


    const selectExistingActivity = (selectedActivity) => {
        try {
            // Update project data to link the activity to the plan item
            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        plans: projectData.project.data.plans.map(p => {
                            // If this isn't the current plan, return it unchanged
                            if (p._id !== plan._id) return p;

                            // Update the current plan's images
                            return {
                                ...p,
                                images: p.images.map(img => {
                                    // If this isn't the selected image, return it unchanged
                                    if (typeof img === 'object' ? img.imageId !== selectedImage._id : img !== selectedImage._id) {
                                        return img;
                                    }

                                    // Update the selected image with the activity
                                    return {
                                        imageId: typeof img === 'object' ? img.imageId : img,
                                        name: typeof img === 'object' ? img.name : removeImageExtension(selectedImage.name),
                                        activityId: selectedActivity._id
                                    };
                                })
                            };
                        })
                    }
                }
            };

            // Update the project data
            setProjectData(updatedProjectData);

            // Close the modal
            onClose();

        } catch (error) {
            console.error('Error selecting activity:', error);
            alert('Failed to select activity. Please try again.');
        }
    };

    function removeImageExtension(imageName) {
        return imageName.replace(/\.(jpg|jpeg|png|gif|bmp)$/i, '');
    }

    const resetForm = () => {
        console.log('Resetting form state');
        setActivityName('');
        setActivityColor(activity ? activity.color : randomColor());
        setDescription('');
        setDuration('0');
        setSteps([]);
        setSelectedImages([]);
        setStepDescriptions({});
        setIsCreatingNew(false);
        setHasUnsavedChanges(false);
        setInitialState(null);
    };

    if (!isVisible) return null;

    const colorOptions = [
        '#FF7F50',      // Bright and visible, complements primary
        '#8FBC8F',       // Natural green that works with background
        '#DDA0DD',       // Soft purple that contrasts with greens
        '#DAA520',  // Rich yellow that complements accent
        '#87CEEB',    // Light blue for contrast
        '#E2725B', // Earthier version of primary
        '#E6E6FA',   // Light purple for subtle items
        '#40808C'        // Deep blue-green that works with secondary;
    ]
    const handleActivityNameChange = (e) => {
        const newValue = e.target.value;
        setActivityName(newValue);
        setHasUnsavedChanges(checkForUnsavedChanges());
    };

    const handleImageRemoval = (imageId) => {
        if (window.confirm("Are you sure you want to remove this image?")) {
            setSelectedImages(prev => prev.filter(id => id !== imageId));
            setHasUnsavedChanges(true);
        }
    };


    const handleDescriptionChange = (e) => {
        const newValue = e.target.value;
        setDescription(newValue);
        setHasUnsavedChanges(checkForUnsavedChanges());
    };

    const handleDurationChange = (e) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        setDuration(newValue);
        setHasUnsavedChanges(checkForUnsavedChanges());
    };

    const handleColorChange = (color) => {
        setActivityColor(color);
        setHasUnsavedChanges(checkForUnsavedChanges());
    };


    return (
        <div className="activity-modal">
            <div className="activity-editor">
                <div className="modalContent">
                    <div className="modalHeader">
                        <h2>
                            {activity ? `Edit ${activity.name}` : (isCreatingNew ? 'New Activity' : 'Select Activity')}
                        </h2>
                        <button className="closeButton" onClick={handleClose}>×</button>
                    </div>
                <div className="modalBody">
                    {!isCreatingNew && !activity ? (
                        // Activity Selection Screen
                        <div className="selectionContainer">
                            <div className="searchContainer">
                                <input
                                    type="text"
                                    className="searchInput"
                                    placeholder="Search activities..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>

                            <button
                                className="createNewButton"
                                onClick={() => setIsCreatingNew(true)}
                            >
                                Create New Activity
                            </button>

                            <div className="activitiesList">
                                {projectData.project.data.activities?.filter(act =>
                                    act.name.toLowerCase().includes(searchQuery.toLowerCase())
                                ).map(act => (
                                    <div
                                        key={act._id}
                                        className="activityListItem"
                                        style={{ backgroundColor: act.color || '#F9E2BC' }}
                                    >
                                        <div className="activityListItemContent">
                                            <h3 className="activityListItemTitle">{act.name}</h3>
                                            {act.description && (
                                                <p className="activityListItemDescription">
                                                    {act.description}
                                                </p>
                                            )}
                                            <div className="activityListItemMetadata">
                                                {act.duration > 0 && (
                                                    <span className="metadataItem">
                            <span className="metadataIcon">⏱️</span>
                                                        {act.duration} min
                        </span>
                                                )}
                                                <span className="metadataItem">
                        <span className="metadataIcon">🖼️</span>
                                                    {act.images?.length || 0} images
                    </span>
                                            </div>
                                        </div>
                                        <div className="activityListItemActions">
                                            <button
                                                className="selectActivityButton"
                                                onClick={() => selectExistingActivity(act)}
                                            >
                                                Select
                                            </button>
                                            <button
                                                className="deleteActivityButton"
                                                onClick={() => handleDeleteActivity(act)}
                                            >
                                                ×
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        // Activity Form
                        <div className="formContainer" style={{ backgroundColor: activityColor }}>
                            {!activity && (
                                <button
                                    className="backButton"
                                    onClick={() => {
                                        setIsCreatingNew(false);
                                        resetForm();
                                    }}
                                >
                                    ← Back to Activities List
                                </button>
                            )}

                            <div className="formSection">
                                <div className="toggleContainer">
                                    <label className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            checked={showInputs}
                                            onChange={() => setShowInputs(prev => !prev)}
                                        />
                                        <span className="toggle-slider"></span>
                                        <span className="toggle-label">{showInputs ? 'Hide Details' : 'Show Details'}</span>
                                    </label>
                                </div>

                                <label>Activity Name</label>
                                <input
                                    type="text"
                                    value={activityName}
                                    onChange={handleActivityNameChange}
                                    placeholder="Enter activity name"
                                    className="activity-name-input"
                                />

                                {showInputs && (
                                    <>
                                        <label>Description</label>
                                        <textarea
                                            value={description}
                                            onChange={handleDescriptionChange}
                                            placeholder="Enter description"
                                            className="textArea"
                                            rows={3}
                                        />

                                        <label>Duration (minutes)</label>
                                        <input
                                            type="number"
                                            value={duration}
                                            onChange={handleDurationChange}
                                            placeholder="Enter duration in minutes"
                                            className="activity-input"
                                            min="0"
                                        />
                                    </>
                                )}

                                <label>Color</label>
                                <div className="colorPicker">
                                    {colorOptions.map(color => (
                                        <button
                                            key={color}
                                            className={`colorOption ${activityColor === color ? 'selected' : ''}`}
                                            style={{ backgroundColor: color }}
                                            onClick={() => handleColorChange(color)}
                                        />
                                    ))}
                                </div>

                                <div className="sectionHeader">
                                    <label>Images ({selectedImages.length})</label>
                                    <button
                                        className="addButton"
                                        onClick={() => setIsImageModalOpen(true)}
                                    >
                                        Add Images
                                    </button>
                                </div>

                                <DndProvider backend={HTML5Backend}>
                                    <div className="imageListSection">
                                        {selectedImages.map((imageId, index) => (
                                            <DraggableActivityImage
                                                key={imageId}
                                                imageId={imageId}
                                                imageUrl={imageUrls[imageId]}
                                                index={index}
                                                moveImage={moveImage}
                                                removeImage={handleImageRemoval}
                                                onDescriptionChange={handleStepDescriptionChange}
                                                onMetadataChange={handleStepMetadataChange}
                                                color={activityColor}
                                                description={stepDescriptions[imageId] || ''}
                                                metadata={stepMetadata[imageId] || ''}
                                                showInputs={showInputs}
                                            />
                                        ))}
                                    </div>
                                </DndProvider>
                            </div>

                            <div className="buttonContainer">
                                <button
                                    className="saveButton"
                                    onClick={updateOrCreateActivity}
                                    disabled={isLoading}
                                >
                                    {activity ? 'Update Activity' : 'Create Activity'}
                                </button>

                                {activity && (
                                    <button
                                        className="removeActivityButton"
                                        onClick={handleRemoveActivityFromSchedule}
                                    >
                                        Remove Activity
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                    <ImageSelectionModal
                        isOpen={isImageModalOpen}
                        onRequestClose={() => setIsImageModalOpen(false)}
                        onSelectImage={handleSelectImage}
                        projectId={projectData.project._id}
                        token={localStorage.getItem('token')}
                        onClose={() => setIsImageModalOpen(false)}
                    />
            </div>
        </div>
        </div>
    );
};

export default ActivityEditorModal;