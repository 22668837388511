import React, { useEffect, useState } from 'react';
import '../../css/ProjectImages.css';
import { useParams } from 'react-router-dom';
import ImagePreviewModal from '../../modals/ImagePreviewModal';
import { getImage, setImage, clearImageCache } from '../../utils/idbHelpers';
import {useProject} from "../../utils/ProjectContext";


const ProjectImages = () => {
    const {projectName, BASE_URL} = useProject();
    const { projectId } = useParams();
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [filter, setFilter] = useState('all');
    const token = localStorage.getItem('token');
    const [progress, setProgress] = useState(0);

    const handleClearCache = async () => {
        let storeName = `${projectId}-images`;
        setIsLoading(true);  // Start showing the progress bar
        setProgress(0);      // Reset the progress to 0
        await clearImageCache(storeName);
        await fetchProjectImages();  // Re-fetch images after clearing cache
    };

    const fetchProjectImages = async () => {
        try {
            const response = await fetch(`${BASE_URL}/project/${projectId}/images?filter=${filter}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();

            const totalImages = data.length;
            let loadedImages = 0;

            const fetchedImages = await Promise.all(
                data.map(async (image) => {
                    let cachedImage = await getImage(projectId, image.name);
                    if (cachedImage) {
                        loadedImages++;
                        setProgress(Math.round((loadedImages / totalImages) * 100));
                        return { ...image, imageUrl: cachedImage };
                    } else {
                        try {
                            const response = await fetch(`${BASE_URL}/project/${projectId}/image/${image.name}`, {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                },
                            });
                            const data = await response.json();
                            await setImage(projectId, image.name, data.imageUrl);
                            loadedImages++;
                            setProgress(Math.round((loadedImages / totalImages) * 100));
                            return { ...image, imageUrl: data.imageUrl };
                        } catch (error) {
                            console.error('Error fetching image:', error);
                            return null;
                        }
                    }
                })
            );
            setImages(fetchedImages.filter(image => image !== null));
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching project images:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProjectImages();
    }, [projectId, token, filter]);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };



    return (
        <div className="project-images">
            <h2>{projectName}'s Project Images</h2>
            {isLoading && (
                <div className="progress-bar">
                    <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
                    <span>{progress}%</span>
                </div>
            )}

            <div className="filter-button-container">

                <div className="filter-dropdown">
                    <select onChange={handleFilterChange} value={filter}>
                        <option value="">All</option>
                        <option value="Not Used">Not Used</option>
                        <option value="Used">Used</option>
                        <option value="Choice">Choices</option>
                        <option value="ThisThen">ThisThen</option>
                        <option value="Plan">Schedules</option>
                        <option value="Social Story">Social Stories</option>
                    </select>
                </div>
                <div className="clear-cache-button">
                    <button onClick={handleClearCache}>Clear & Re-download</button>
                </div>
            </div>
            <div className="images-grid">
                {images.map((image, index) => (
                    <div key={index} className="image-container">
                        <img
                            src={image.imageUrl}
                            alt={image.name}
                            onClick={() => handleImageClick(image.imageUrl)}
                            className="project-image"
                        />
                        <div className="image-metadata">
                            <p>{image.name}</p>
                            {image.contexts && image.contexts.length > 0 && (
                                <ul>
                                    {image.contexts.map((context, idx) => (
                                        <li key={idx}>{context}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <ImagePreviewModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                imageUrl={selectedImage}
            />
        </div>
    );
};

export default ProjectImages;