import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../../css/ThisThenEditor.css';
import { useProject } from '../../utils/ProjectContext';
import DraggableTopPlan from './DraggableTopPlan';
import ImageSelectionModal from '../../modals/ImageSelectionModal';
import { getImage, setImage } from '../../utils/idbHelpers';
import DraggableThisThenItem from './DraggableThisThenItem';
import Spinner from "../../utils/Spinner";
import BottomNavBar from "../navigation/BottomNavBar"; // Import the DraggableThisThenItem



const ThisThenEditor = () => {
    const {BASE_URL, projectName} = useProject();
    const { projectId } = useParams();
    const { fetchProjectDetails, projectData, setProjectData } = useProject();
    const [thisThenList, setThisThenList] = useState([]);
    const [selectedThisThenId, setSelectedThisThenId] = useState(null);
    const [currentThisThen, setCurrentThisThen] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isThis, setIsThis] = useState(true); // To keep track of which part (this/then) is being edited
    const token = localStorage.getItem('token');
    const [thisThenName, setThisThenName] = useState();
    const MAX_IMAGES_PER_SECTION = 2;

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const data = await fetchProjectDetails(projectId);
                setProjectData(data);
                setThisThenList(data.project.data.thisThen || []);
                setIsLoading(false);

                // Select the first item in the list
                if (data.project.data.thisThen && data.project.data.thisThen.length > 0) {
                    const firstThisThenId = data.project.data.thisThen[0]._id;
                    setSelectedThisThenId(firstThisThenId);
                    const selectedThisThen = data.project.data.thisThen.find(item => item._id === firstThisThenId);
                    const thisImages = await fetchImagesForPart(selectedThisThen.this.images);
                    const thenImages = await fetchImagesForPart(selectedThisThen.then.images);
                    setCurrentThisThen({
                        ...selectedThisThen,
                        this: { ...selectedThisThen.this, images: thisImages },
                        then: { ...selectedThisThen.then, images: thenImages },
                    });
                }
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchProjectData();
    }, []);

    useEffect(() => {
        if (selectedThisThenId) {
            const selectedThisThen = thisThenList.find((item) => item._id === selectedThisThenId);
            if (selectedThisThen) {
                const fetchImages = async () => {
                    const thisImages = await fetchImagesForPart(selectedThisThen.this.images);
                    const thenImages = await fetchImagesForPart(selectedThisThen.then.images);
                    setCurrentThisThen({
                        ...selectedThisThen,
                        this: { ...selectedThisThen.this, images: thisImages },
                        then: { ...selectedThisThen.then, images: thenImages },
                    });
                };
                fetchImages();
            }
        }
    }, [selectedThisThenId, thisThenList]);

    const fetchImagesForPart = async (images) => {
        console.log('FETCH FOR PART', images);
        return await Promise.all(
            images.map(async (imageObj) => {
                // Get the image ID from either string format or object format
                const imageId = typeof imageObj === 'string' ? imageObj : imageObj.id;
                const imageDetails = projectData.project.data.images.find(image => image._id === imageId);

                if (imageDetails) {
                    let cachedImage = await getImage(projectId, imageDetails.name);
                    if (cachedImage) {
                        return {
                            ...imageDetails,
                            imageUrl: cachedImage,
                            id: imageId,
                            altName: typeof imageObj === 'object' ? imageObj.altName : imageDetails.name // Preserve existing altName
                        };
                    } else {
                        try {
                            const response = await fetch(`${BASE_URL}/project/${projectData.project._id}/image/${imageDetails.name}`, {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                },
                            });
                            const data = await response.json();
                            await setImage(projectId, imageDetails.name, data.imageUrl);
                            return {
                                ...imageDetails,
                                imageUrl: data.imageUrl,
                                id: imageId,
                                altName: typeof imageObj === 'object' ? imageObj.altName : imageDetails.name
                            };
                        } catch (error) {
                            console.error('Error fetching image:', error);
                            return null;
                        }
                    }
                }
                return null;
            })
        );
    };


    const handleThisThenSelect = (thisThenId) => {
        setSelectedThisThenId(thisThenId);
    };

    const moveThisThenItem = (fromIndex, toIndex) => {
        const updatedList = [...thisThenList];
        const [movedItem] = updatedList.splice(fromIndex, 1);
        updatedList.splice(toIndex, 0, movedItem);
        setThisThenList(updatedList);
    };

    const moveImage = (fromIndex, toIndex, fromThisPart, toThisPart) => {
        const fromImages = fromThisPart ? [...currentThisThen.this.images] : [...currentThisThen.then.images];
        const toImages = toThisPart ? [...currentThisThen.this.images] : [...currentThisThen.then.images];

        const [movedImage] = fromImages.splice(fromIndex, 1);
        toImages.splice(toIndex, 0, movedImage);

        const updatedThisThen = {
            ...currentThisThen,
            this: fromThisPart ? { images: fromImages } : toThisPart ? { images: toImages } : currentThisThen.this,
            then: fromThisPart ? currentThisThen.then : toThisPart ? currentThisThen.then : { images: toImages }
        };

        setCurrentThisThen(updatedThisThen);
        setThisThenList(thisThenList.map(item => item._id === currentThisThen._id ? updatedThisThen : item));
    };



    const handleDeleteImage = (index, isThisPart) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const updatedImages = isThisPart ? [...currentThisThen.this.images] : [...currentThisThen.then.images];
            updatedImages.splice(index, 1);

            const updatedThisThen = {
                ...currentThisThen,
                this: isThisPart ? { images: updatedImages } : currentThisThen.this,
                then: isThisPart ? currentThisThen.then : { images: updatedImages }
            };

            setCurrentThisThen(updatedThisThen);
            const updatedThisThenList = thisThenList.map(item => item._id === currentThisThen._id ? updatedThisThen : item);
            setThisThenList(updatedThisThenList);

            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        thisThen: updatedThisThenList
                    }
                }
            };

            setProjectData(updatedProjectData);
        }

    };

    const handleDeleteThisThen = async (thisThenId) => {
        const updatedThisThenList = thisThenList.filter(item => item._id !== thisThenId);
        setThisThenList(updatedThisThenList);
        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    thisThen: updatedThisThenList
                }
            }
        };
        setProjectData(updatedProjectData);

        await setProjectData(updatedProjectData);
        if (thisThenId === selectedThisThenId) {
            setSelectedThisThenId(updatedThisThenList.length > 0 ? updatedThisThenList[0]._id : null);
            setCurrentThisThen(updatedThisThenList.length > 0 ? updatedThisThenList[0] : null);
        }
    };

    const handleImageNameChange = async (imageId, newName, isThisPart) => {
        const section = isThisPart ? 'this' : 'then';
        const currentImages = currentThisThen[section].images;

        // Update the image altName in the current images array
        const updatedImages = currentImages.map(img => {
            if (img.id === imageId) {
                return { ...img, altName: newName }; // Change name to altName
            }
            return img;
        });

        // Create updated ThisThen object
        const updatedThisThen = {
            ...currentThisThen,
            [section]: {
                ...currentThisThen[section],
                images: updatedImages
            }
        };

        // Update the current ThisThen
        setCurrentThisThen(updatedThisThen);

        // Update the ThisThen in the list
        const updatedThisThenList = thisThenList.map(item =>
            item._id === currentThisThen._id ? updatedThisThen : item
        );

        // Update state and project data
        setThisThenList(updatedThisThenList);
        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    thisThen: updatedThisThenList
                }
            }
        };
        setProjectData(updatedProjectData);
    };

    const handleImageClick = (imageUrl) => {
        // Handle image click (e.g., show in modal)
    };

    const openModal = (isThisPart) => {
        setIsThis(isThisPart);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const fetchUpdatedProjectData = async () => {
        try {
            const data = await fetchProjectDetails(projectId);
            setProjectData(data);
            setThisThenList(data.project.data.thisThen || []);
        } catch (error) {
            console.error('Error fetching updated project data:', error);
        }
    };

    const handleSelectImage = async (imageId) => {
        const data = await fetchProjectDetails(projectId);
        setProjectData(data);
        setThisThenList(data.project.data.thisThen || []);

        const foundImage = data.project.data.images.find(image => image._id === imageId);

        if (foundImage) {
            // Create new image object with the required format
            const newImageObj = {
                id: imageId,
                name: foundImage.name,
                altName: foundImage.name // Set initial altName to the image name
            };

            const updatedImages = isThis
                ? [...currentThisThen.this.images, newImageObj]
                : [...currentThisThen.then.images, newImageObj];

            const updatedThisThen = {
                ...currentThisThen,
                this: isThis ? { images: updatedImages } : currentThisThen.this,
                then: isThis ? currentThisThen.then : { images: updatedImages }
            };

            setCurrentThisThen(updatedThisThen);
            const updatedThisThenList = thisThenList.map(item =>
                item._id === currentThisThen._id ? updatedThisThen : item
            );
            setThisThenList(updatedThisThenList);

            const updatedProjectData = {
                ...data,
                project: {
                    ...data.project,
                    data: {
                        ...data.project.data,
                        thisThen: updatedThisThenList
                    }
                }
            };

            setProjectData(updatedProjectData);
            closeModal();
        }
    };

    const handleCreateNewThisThen = async () => {
        const name = prompt('Enter the name for the new ThisThen:');
        if (name) {
            const response = await fetch(`${BASE_URL}/generate-objectid`);
            const data = await response.json();
            let newId = data.objectId;

            const newThisThen = {
                _id: newId,
                name: name,
                this: { images: [] },
                then: { images: [] }
            };

            const updatedThisThenList = [...thisThenList, newThisThen];
            setThisThenList(updatedThisThenList);
            const updatedProjectData = {
                ...projectData,
                project: {
                    ...projectData.project,
                    data: {
                        ...projectData.project.data,
                        thisThen: updatedThisThenList
                    }
                }
            };
            setProjectData(updatedProjectData);
            setSelectedThisThenId(newThisThen._id);
            setCurrentThisThen(newThisThen);
        }
    };

    const handleImageAltNameChange = (imageId, newAltName, isThisPart) => {
        const updatedImages = isThisPart
            ? currentThisThen.this.images.map(img =>
                img.id === imageId ? { ...img, altName: newAltName } : img
            )
            : currentThisThen.then.images.map(img =>
                img.id === imageId ? { ...img, altName: newAltName } : img
            );

        const updatedThisThen = {
            ...currentThisThen,
            this: isThisPart ? { images: updatedImages } : currentThisThen.this,
            then: isThisPart ? currentThisThen.then : { images: updatedImages }
        };

        setCurrentThisThen(updatedThisThen);
        const updatedThisThenList = thisThenList.map(item =>
            item._id === currentThisThen._id ? updatedThisThen : item
        );
        setThisThenList(updatedThisThenList);

        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    thisThen: updatedThisThenList
                }
            }
        };
        setProjectData(updatedProjectData);
    };

    const handleNameChange = (e) => {
        setThisThenName(e.target.value);

        // Update the current ThisThen with the new name
        const updatedCurrentThisThen = { ...currentThisThen, name: e.target.value };
        setCurrentThisThen(updatedCurrentThisThen);

        // Update the ThisThen list
        const updatedThisThenList = thisThenList.map(thisThen =>
            thisThen._id === currentThisThen._id ? updatedCurrentThisThen : thisThen
        );
        setThisThenList(updatedThisThenList);

        // Update the project data
        const updatedProjectData = {
            ...projectData,
            project: {
                ...projectData.project,
                data: {
                    ...projectData.project.data,
                    thisThen: updatedThisThenList
                }
            }
        };
        setProjectData(updatedProjectData);
    };


    if (isLoading) {
        return (
            <div className="spinner-wrapper">
                <Spinner />
            </div>
        );
    }

    return (
        <div className={'title-container'}>
            <h2>{projectName}'s First/Thens</h2>
            <DndProvider backend={HTML5Backend}>
                <div className="this-then-editor">
                    <div className="this-then-list">
                        <button
                            className="create-firstthen-button this-then-item"
                            onClick={handleCreateNewThisThen}
                        >
                            New
                        </button>
                        {thisThenList.map((item, index) => (
                            <DraggableThisThenItem
                                projectId={projectId}
                                onDelete={() => handleDeleteThisThen(item._id)}
                                key={item._id}
                                index={index}
                                item={item}
                                moveItem={moveThisThenItem}
                                onSelect={handleThisThenSelect}
                                isSelected={item._id === selectedThisThenId}
                                projectData={projectData}
                                BASE_URL={BASE_URL}
                                token={token}
                                setImage={setImage}
                            />
                        ))}
                    </div>
                    {currentThisThen && (
                        <div className="this-then-details">
                            <h2>Currently Editing: <span style={{ fontFamily: 'sans-serif' }}>{currentThisThen.name}</span></h2>
                            <div className="editor-field">
                                <label>Edit Name:</label>
                                <input
                                    type="text"
                                    value={currentThisThen.name}
                                    onChange={handleNameChange}
                                />
                            </div>
                            <div className="this-then-section-container">
                                <div className="this-then-section">
                                    <h2>First</h2>
                                    <div className="images-container">
                                        <button
                                            onClick={() => openModal(true)}
                                            disabled={currentThisThen.this.images.length >= MAX_IMAGES_PER_SECTION}
                                        >
                                            {currentThisThen.this.images.length >= MAX_IMAGES_PER_SECTION ? 'Max Images Reached' : 'Add Image'}
                                        </button>
                                        {currentThisThen.this.images.map((image, index) => (
                                            image && (
                                                <div key={index} className="image-item">
                                                    <img
                                                        src={image.imageUrl}
                                                        alt={image.altName || image.name}
                                                        className="this-then-image"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={image.altName || image.name || ""}
                                                        onChange={(e) => handleImageNameChange(image.id, e.target.value, true)}
                                                        className="image-name-input"
                                                        placeholder="Enter image name"
                                                    />
                                                    <button
                                                        onClick={() => handleDeleteImage(index, true)}
                                                        className="delete-btn"
                                                    >
                                                        ×
                                                    </button>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                                <div className="this-then-section">
                                    <h2>Then</h2>
                                    <div className="images-container">
                                        <button
                                            onClick={() => openModal(false)}
                                            disabled={currentThisThen.then.images.length >= MAX_IMAGES_PER_SECTION}
                                        >
                                            {currentThisThen.then.images.length >= MAX_IMAGES_PER_SECTION ? 'Max Images Reached' : 'Add Image'}
                                        </button>
                                        {currentThisThen.then.images.map((image, index) => (
                                            image && (
                                                <div key={index} className="image-item">
                                                    <img
                                                        src={image.imageUrl}
                                                        alt={image.altName || image.name}
                                                        className="this-then-image"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={image.altName || image.name || ""}
                                                        onChange={(e) => handleImageNameChange(image.id, e.target.value, false)}
                                                        className="image-name-input"
                                                        placeholder="Enter image name"
                                                    />
                                                    <button
                                                        onClick={() => handleDeleteImage(index, false)}
                                                        className="delete-btn"
                                                    >
                                                        ×
                                                    </button>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <ImageSelectionModal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        onSelectImage={handleSelectImage}
                        projectId={projectId}
                        token={token}
                        onClose= {() => setIsModalOpen(false)}
                    />
                </div>
            </DndProvider>
            <BottomNavBar
                projectId={projectId}
            />
        </div>
    );
};

export default ThisThenEditor;
