import React, { useEffect, useState } from 'react';
import '../../css/Dashboard.css';
import { useNavigate } from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale, ArcElement, BarElement,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { getImage, setImage } from '../../utils/idbHelpers';
import { Line, Pie, Bar } from 'react-chartjs-2';
import { useAuth } from '../../utils/AuthContext';
import { useProject } from '../../utils/ProjectContext';
import ProjectFormModal from '../../modals/ProjectFormModal';
import ConfirmationModal from '../../modals/ConfirmationModal';
import BottomNavBar from "../navigation/BottomNavBar";
import {ThemeContext} from "../../utils/ThemeProvider";


ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, ArcElement);

const Dashboard = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject]  = useState(null);
    const [projectStats, setProjectStats] = useState({});
    const [timeScale, setTimeScale] = useState('day');
    const [sessionData, setSessionData] = useState([]);
    const [projectStatus, setProjectStatus] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [chartData, setChartData] = useState({ datasets: [] });
    const [sessionChartData, setSessionChartData] = useState({ datasets: [] });
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [timeUnit, setTimeUnit] = useState('day');
    const [currentStart, setCurrentStart] = useState(new Date());
    const [currentEnd, setCurrentEnd] = useState(new Date() + 1);
    const [modalAction, setModalAction] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add isLoading state
    const token = localStorage.getItem('token');
    const { isEditMode, setIsEditMode, setProjectId, setProjectData, setProjectName, BASE_URL } = useProject();
    const handleArchiveClick = () => {
        setModalAction('archive');
        setShowConfirmationModal(true);
    };

    const toggleEditMode = () => {
        setIsEditMode(prevMode => !prevMode);

    };

    const handleUnarchiveClick = () => {
        setModalAction('unarchive');
        setShowConfirmationModal(true);
    };

    const handleConfirm = () => {
        if (modalAction === 'archive') {
            archiveProject(selectedProject);
        } else if (modalAction === 'unarchive') {
            unArchiveProject(selectedProject);
        }
        setShowConfirmationModal(false);
    };

    const handleChoiceClick = () => {
        if (selectedProject) {
            navigate(`/choiceEditor/${selectedProject}`);
        }
    };

    const handlePlanClick = () => {
        if (selectedProject) {
            navigate(`/planEditor/${selectedProject}`);
        }
    };

    const handleFirstThenClick = () => {
        if (selectedProject) {
            navigate(`/firstThenEditor/${selectedProject}`);
        }
    };

    const handleImagesClick = () => {
        if (selectedProject) {
            navigate(`/projectImages/${selectedProject}`);
        }
    };

    const handleSessionsClick = () => {
        if (selectedProject) {
            navigate(`/sessionStats/${selectedProject}`);
        }
    };

    const archiveProject = async (projectId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/projects/${projectId}/archive`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: 'archived' }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error archiving project:', errorData.message);
            } else {
                const data = await response.json();
                console.log('Project archived successfully');
                setProjectStatus('archived');

                setProjects((prevProjects) =>
                    prevProjects.map((project) =>
                        project._id === projectId ? { ...project, status: 'archived' } : project
                    )
                );
            }
        } catch (error) {
            console.error('Error archiving project:', error);
        }
    };

    const unArchiveProject = async (projectId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/projects/${projectId}/archive`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: 'active' }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error unarchiving project:', errorData.message);
            } else {
                const data = await response.json();
                console.log('Project unarchived successfully');
                setProjectStatus('active');

                setProjects((prevProjects) =>
                    prevProjects.map((project) =>
                        project._id === projectId ? { ...project, status: 'active' } : project
                    )
                );
            }
        } catch (error) {
            console.error('Error unarchiving project:', error);
        }
    };

    useEffect(() => {
        if (user) {
            const fetchProjects = async () => {
                try {
                    const response = await fetch(`${BASE_URL}/api/projects/all`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    const data = await response.json();

                    if (user.role === 'admin') {
                        setProjects(data);
                    } else {
                        const ownedProjectIds = user.user.ownedProjects.map((p) => p.project);
                        const ownedProjects = data.filter((project) => ownedProjectIds.includes(project._id));
                        setProjects(ownedProjects);
                    }

                    const lastSelectedProjectId = localStorage.getItem('selectedProject');
                    if (lastSelectedProjectId && data.find((project) => project._id === lastSelectedProjectId)) {
                        setSelectedProject(lastSelectedProjectId);
                    } else if (data.length > 0) {
                        setSelectedProject(data[0]._id);
                    }
                    setIsLoading(false); // Turn off the loading spinner
                } catch (error) {
                    console.error('Error:', error);
                    setIsLoading(false); // Turn off the loading spinner
                }
            };
            fetchProjects();
        }
    }, [user]);

    const updateTimeScale = (newTimeUnit) => {
        setTimeUnit(newTimeUnit);
        let min, max;
        const now = new Date();

        switch (newTimeUnit) {
            case 'hour':
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
                max = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1);
                break;
            case 'day':
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                max = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
                break;
            case 'week':
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                max = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
                break;
            case 'month':
                min = new Date(now.getFullYear(), now.getMonth(), 1);
                max = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                break;
            case 'year':
                min = new Date(now.getFullYear(), 0, 1);
                max = new Date(now.getFullYear() + 1, 0, 1);
                break;
            default:
                break;
        }

        setCurrentStart(min);
        setCurrentEnd(max);

        setSessionChartOptions({
            ...sessionChartOptions,
            scales: {
                ...sessionChartOptions.scales,
                x: {
                    ...sessionChartOptions.scales.x,
                    min: min,
                    max: max,
                },
            },
        });
    };

    useEffect(() => {
        if (sessionData.length > 0) {
        const transformedData = sessionData.map((session) => {
            return {
                x: new Date(session.x),
                y: session.y,
            };
        });

        setSessionChartData({
            datasets: [
                {
                    label: 'Session Duration',
                    data: transformedData,
                    backgroundColor: 'rgba(80,117,28, 0.5)',
                    borderColor: 'rgba(80,117,28, 1)',
                    barThickness: '16',
                },
            ],
        })};
    }, [sessionData]);

    const [sessionChartOptions, setSessionChartOptions] = useState({
        scales: {
            x: {
                barPercentage: 9,
                type: 'time',
                time: {
                    unit: timeUnit,
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const durationInMilliseconds = tooltipItem.yLabel * 60000;
                    const minutes = Math.floor(durationInMilliseconds / 60000);
                    const seconds = Math.floor((durationInMilliseconds % 60000) / 1000)
                        .toString()
                        .padStart(2, '0');
                    return `Duration: ${minutes}:${seconds}`;
                },
            },
        },
    });

    const handleTimeScaleChange = (newTimeUnit) => {
        setTimeUnit(newTimeUnit);
        updateTimeScale(newTimeUnit);
    };

    const fetchSessionData = (projectId, timeFrame = 'day') => {
        fetch(`${BASE_URL}/api/logs/${projectId}/user-sessions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Check if data is not empty and has the correct structure
                if (!data || data.length === 0) {
                    console.error('No session data found:', data);
                    return;
                }

                // Extract and transform session data
                // const transformedData = data.flatMap(dayData => {
                //     if (Array.isArray(dayData.sessions)) {
                //         return dayData.sessions.flatMap(session => {
                //             const sessionStart = new Date(session.start);
                //             const durationInMinutes = session.duration / 60000;
                //             return { x: sessionStart, y: durationInMinutes };
                //         });
                //     } else {
                //         console.warn('No sessions found for day:', dayData.day);
                //         return []; // Return an empty array if no sessions are found
                //     }
                // });

                // Update sessionData state
                setSessionData(data);
            })
            .catch((error) => console.error('Error fetching session data:', error));
    };


    useEffect(() => {
        selectAProject(selectedProject);
    }, []);

    useEffect(() => {
        if (selectedProject) {
            setProjectId(selectedProject)
            fetchProjectDetails(selectedProject);
            fetchSessionData(selectedProject, timeScale);
            fetchFeedbackStats(selectedProject);
        }
    }, [selectedProject, timeScale]);

    useEffect(() => {
        if (user) {
            const fetchProjects = async () => {
                try {
                    const response = await fetch(`${BASE_URL}/api/projects/all`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    const data = await response.json();
                    if (user.role === 'admin') {
                        setProjects(data);
                    } else {
                        const ownedProjectIds = user.user.ownedProjects.map((p) => p.project);
                        const ownedProjects = data.filter((project) => ownedProjectIds.includes(project._id));
                        setProjects(ownedProjects);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            };
            fetchProjects();
        }
    }, [user]);


    const updateCurrentPeriod = (direction, currentTimeUnit) => {
        let newStart = new Date(currentStart);
        let newEnd = new Date(currentEnd);

        switch (currentTimeUnit) {
            case 'hour':
                newStart.setHours(newStart.getHours() + direction);
                newEnd.setHours(newEnd.getHours() + direction);
                break;
            case 'day':
                newStart.setDate(newStart.getDate() + direction);
                newEnd.setDate(newEnd.getDate() + direction);
                break;
            case 'week':
                newStart.setDate(newStart.getDate() + 7 * direction);
                newEnd.setDate(newEnd.getDate() + 7 * direction);
                break;
            case 'month':
                newStart.setMonth(newStart.getMonth() + direction);
                newEnd.setMonth(newEnd.getMonth() + direction);
                break;
            case 'year':
                newStart.setFullYear(newStart.getFullYear() + direction);
                newEnd.setFullYear(newEnd.getFullYear() + direction);
                break;
            default:
                break;
        }

        setCurrentStart(newStart);
        setCurrentEnd(newEnd);

        setSessionChartOptions({
            ...sessionChartOptions,
            scales: {
                ...sessionChartOptions.scales,
                x: {
                    ...sessionChartOptions.scales.x,
                    min: newStart,
                    max: newEnd,
                },
            },
        });
    };

    const handlePrevious = () => {
        updateCurrentPeriod(-1, timeUnit);
    };

    const handleNext = () => {
        updateCurrentPeriod(1, timeUnit);
    };

    const fetchProjectDetails = (projectId) => {
        if (!projectId) {
            return
        }
        fetch(`${BASE_URL}/api/projects/${projectId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((project) => {
                setProjectName(project.project.name)
                const stats = {
                    activitiesCount: project.stats.activitiesCount,
                    stepsCount: project.stats.stepsCount,
                    placesCount: project.stats.placesCount,
                    plansCount: project.stats.plansCount,
                    choicesCount: project.stats.choicesCount,
                    imagesCount: project.stats.imagesCount,
                    firstThenCount: project.stats.firstThenCount,
                    lastUpdated: project.project.data.lastUpdated,
                    name: project.project.name,
                    startDate: project.project.date,
                    profileImage: project.project.profileImage,
                };
                setProjectStats((prevStats) => ({ ...prevStats, ...stats }));
                setProjectStatus(project.project.status);
                setSelectedProject(project.project._id);

                if (project.project.profileImage) {
                    const profileImage = project.project.data.images.find((image) => image._id === project.project.profileImage);
                    if (profileImage) {
                        fetchProfileImage(project.project._id, profileImage.name);
                    }
                }

                // Store the selected project ID in localStorage
                localStorage.setItem('selectedProject', project.project._id);
                setIsLoading(false); // Turn off the loading spinner
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false); // Turn off the loading spinner
            });
    };


    const fetchProfileImage = async (projectId, imageName) => {
        try {
            let cachedImage = await getImage(projectId, imageName);
            if (cachedImage) {
                setProfileImageUrl(cachedImage);
            } else {
                const response = await fetch(`${BASE_URL}/project/${projectId}/image/${imageName}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.imageUrl) {
                    const image = new Image();
                    image.onload = async () => {
                        try {
                            await setImage(projectId, imageName, data.imageUrl);
                            setProfileImageUrl(data.imageUrl);
                        } catch (error) {
                            console.error('Error storing image in IndexedDB:', error);
                        }
                    };
                    image.onerror = () => {
                        console.error('Invalid image data');
                    };
                    image.src = data.imageUrl;
                } else {
                    throw new Error('Invalid image data');
                }
            }
        } catch (error) {
            console.error('Error fetching profile image:', error);
        }
    };

    const chartOptions = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        size: 14,
                    },
                    boxWidth: 20,
                    padding: 20,
                    color: '#333333',
                },
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                titleFont: {
                    size: 16,
                },
                bodyFont: {
                    size: 14,
                },
                borderColor: '#dddddd',
                borderWidth: 1,
            },
        },
        width: '100%',
    };

    const getProjectOptionClass = (status) => {
        switch (status) {
            case 'active':
                return 'project-option-active';
            case 'archived':
                return 'project-option-archived';
            default:
                return 'project-option-other-status';
        }
    };

    const handleProjectSelect = (event) => {
        const projectId = event.target.value;
        setSelectedProject(projectId);
        setIsLoading(true); // Turn on the loading spinner
        fetchProjectDetails(projectId);
    };

    const selectAProject = (event) => {
        fetchProjectDetails(selectedProject);
    };

    const feedbackChartData = {
        labels: ['Positive', 'Negative', 'Neutral'],
        datasets: [
            {
                label: 'Feedback Types',
                data: [projectStats?.positiveFeedback, projectStats?.negativeFeedback, projectStats?.neutralFeedback],
                backgroundColor: ['rgba(119,190,99,0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(255, 206, 86, 0.6)'],
                borderWidth: 1,
            },
        ],
    };

    const handleProjectSubmit = async (e, projectData) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', projectData.name);
            formData.append('age', projectData.age);
            formData.append('userId', user.user._id);
            if (projectData.projectPic) {
                formData.append('projectPic', projectData.projectPic);
            }
            const response = await fetch(`${BASE_URL}/api/projects/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                setProjects([...projects, data.project]);
                setShowProjectModal(false);
            } else {
                console.error('Error creating project:', data.message);
            }
        } catch (error) {
            console.error('Error creating project:', error);
        }
    };

    const fetchFeedbackStats = (projectId) => {
        fetch(`${BASE_URL}/api/projects/${projectId}/feedback-stats`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setProjectStats((prevStats) => ({
                    ...prevStats,
                    totalSessions: data.aggregatedFeedback.totalSessions,
                    positiveFeedback: data.aggregatedFeedback.positiveCount,
                    neutralFeedback: data.aggregatedFeedback.neutralCount,
                    negativeFeedback: data.aggregatedFeedback.negativeCount,
                }));
            })
            .catch((error) => console.error('Error fetching feedback stats:', error));
    };

    const formatDate = (dateString) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const date = new Date(dateString);
        const dayName = days[date.getDay()];
        const monthName = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const suffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        };

        return `${dayName}, ${monthName} ${day}${suffix(day)} ${year} ${hours % 12 === 0 ? 12 : hours % 12}:${minutes < 10 ? '0' + minutes : minutes} ${
            hours >= 12 ? 'pm' : 'am'
        }`;
    };

    return (
        <div className="dashboard">
            {/*{isLoading && <Spinner />}*/}
            <h2>Your Project Dashboard ({projects.length} Projects)</h2>
            <div className="controls-container">
                {profileImageUrl && <img src={profileImageUrl} alt="Profile Image" className="profile-image" />}
                {Array.isArray(projects) && (
                    <select onChange={handleProjectSelect} value={selectedProject || ''}>
                        <option value="">Select a project</option>
                        {projects.map((project) => (
                            <option key={project._id} value={project._id} className={getProjectOptionClass(project.status)}>
                                {project.name}
                            </option>
                        ))}
                    </select>
                )}
                {/*<button*/}
                {/*    onClick={toggleEditMode}*/}
                {/*    className={`edit-mode-button ${isEditMode ? 'edit' : 'view'}`}*/}
                {/*>*/}
                {/*    {isEditMode ? 'Switch to View Mode' : 'Switch to Edit Mode'}*/}
                {/*</button>*/}
                <div className='create-archive-container'>
                    <button onClick={() => setShowProjectModal(true)} className="create-project-button">
                        Create New Kid
                    </button>
                    {projectStatus === 'active' && (
                        <button onClick={handleArchiveClick} className="archive-project-button">
                            Archive Project
                        </button>
                    )}
                    {projectStatus === 'archived' && (
                        <button onClick={handleUnarchiveClick} className="archive-project-button">
                            Unarchive Project
                        </button>
                    )}
                </div>
            </div>
            {selectedProject && (
                <div>
                    <div className="project-details">
                        <p>
                            <strong>Start Date:</strong> {new Date(projectStats.startDate).toLocaleDateString()}
                        </p>
                        <p>
                            <strong>Last Updated:</strong> {formatDate(projectStats.lastUpdated)}
                        </p>
                    </div>
                    <div className={`main-content ${projectStatus === 'archived' ? 'dashboard-archived' : ''}`}>
                        <div className="stats-container">
                            <div className="stat-box" onClick={handleFirstThenClick}>
                                <h2>First/Then's</h2>
                                <p>{projectStats.firstThenCount}</p>
                            </div>
                            <div className="stat-box" onClick={handleChoiceClick}>
                                <h2>Choices</h2>
                                <p>{projectStats.choicesCount}</p>
                            </div>
                            <div className="stat-box" onClick={handlePlanClick}>
                                <h2>Schedules</h2>
                                <p>{projectStats.plansCount}</p>
                            </div>

                            <div className="stat-box" onClick={handleImagesClick}>
                                <h2>Images</h2>
                                <p>{projectStats.imagesCount}</p>
                            </div>
                            <div className="chart-container">
                                <Pie data={feedbackChartData} options={chartOptions} />
                            </div>
                            <div className="stat-box" onClick={handleSessionsClick}>
                                <h2>Total Sessions</h2>
                                <p>{projectStats.totalSessions}</p>
                            </div>
                            <div className="stat-box">
                                <h2>Positive Feedback</h2>
                                <p>{projectStats.positiveFeedback}</p>
                            </div>
                            <div className="stat-box">
                                <h2>Neutral Feedback</h2>
                                <p>{projectStats.neutralFeedback}</p>
                            </div>
                            <div className="stat-box">
                                <h2>Negative Feedback</h2>
                                <p>{projectStats.negativeFeedback}</p>
                            </div>
                        </div>
                        {/*<div>*/}
                        {/*    <button onClick={handlePrevious}>Previous</button>*/}
                        {/*    <button onClick={() => handleTimeScaleChange('hour')}>Hour</button>*/}
                        {/*    <button onClick={() => handleTimeScaleChange('day')}>Day</button>*/}
                        {/*    <button onClick={() => handleTimeScaleChange('week')}>Week</button>*/}
                        {/*    <button onClick={() => handleTimeScaleChange('month')}>Month</button>*/}
                        {/*    <button onClick={() => handleTimeScaleChange('year')}>Year</button>*/}
                        {/*    <button onClick={handleNext}>Next</button>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <Bar data={sessionChartData} options={sessionChartOptions} />*/}
                        {/*</div>*/}
                    </div>
                    <BottomNavBar
                        projectId={selectedProject}
                    />
                </div>
            )}
            {showProjectModal && <ProjectFormModal onSubmit={handleProjectSubmit} onClose={() => setShowProjectModal(false)} />}
            <ConfirmationModal
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={handleConfirm}
                message={`Are you sure you want to ${modalAction} this project?`}
            />
        </div>
    );
};

export default Dashboard;
