import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../../css/ChoiceEditor.css';
import { useProject } from '../../utils/ProjectContext';
import ChoiceBox from './ChoiceBox';
import ChoiceEditorBox from './ChoiceEditorBox';
import Spinner from "../../utils/Spinner";
import BottomNavBar from "../navigation/BottomNavBar";

const ChoiceEditor = () => {
    const { projectId } = useParams();
    const { fetchProjectDetails, BASE_URL, choices, setChoices, isEditMode } = useProject();
    const [selectedChoiceId, setSelectedChoiceId] = useState(null);
    const [currentChoice, setCurrentChoice] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const data = await fetchProjectDetails(projectId);
                setProjectData(data);
                setChoices(data.project.data.choices);

                if (data.project.data.choices && data.project.data.choices.length > 0) {
                    const firstChoiceId = data.project.data.choices[0]._id;
                    setSelectedChoiceId(firstChoiceId);
                    setCurrentChoice(data.project.data.choices[0]);
                }


                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchProjectData();
    }, [projectId, setChoices]);



    const handleDeleteImage = () => {
        console.log('DELETE IMAGE');
    }

    const handleChoiceSelect = (choiceId) => {
        setSelectedChoiceId(choiceId);
        const selectedChoice = choices.find((choice) => choice._id === choiceId);
        setCurrentChoice(selectedChoice);
    };

    const handleDeleteChoice = async (choiceId, choiceName) => {
        // Add your delete logic here
    };

    if (isLoading) {
        return (
            <div className="spinner-wrapper">
                <Spinner />
            </div>
        );
    }



    return (
        <DndProvider backend={HTML5Backend}>
            <div className="choice-editor">
                <ChoiceBox
                    BASE_URL={BASE_URL}
                    projectData={projectData}
                    selectedChoiceId={selectedChoiceId}
                    setSelectedChoiceId={setSelectedChoiceId}
                    onChoiceSelect={handleChoiceSelect}
                />
                {currentChoice && (
                    <ChoiceEditorBox
                        projectId={projectId}
                        onDelete={() => handleDeleteImage()}
                        currentChoice={currentChoice}
                        setCurrentChoice={setCurrentChoice}
                        projectData={projectData}
                        setProjectData={setProjectData}
                    />
                )}
            </div>
            <BottomNavBar
                projectId={projectId}
            />
        </DndProvider>

    );
};

export default ChoiceEditor;
