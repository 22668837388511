import React, { useEffect } from 'react';
import StarRatings from 'react-star-ratings';
import { useAuth } from '../AuthContext';

const defaultBodyStyles = {
    padding: '10px',
    fontSize: '14px',
    display: 'block !important'
}

const defaultMessageStyles = {
    padding: '10px 10px 0 10px',
    display: 'flex',
    justifyContent: 'center',
}

const defaultMessageBoxStyles = {
    boxSizing: 'border-box',
    padding: '10px 10px 0 10px',
    overflow: 'hidden',
    width: '100%',
    height: '224px',
    fontFamily: 'Arial, Helvetica, sans-serif',
}

const inlineDisplayStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '600px',
    padding: '10px',
    boxSizing: 'border-box',
}

const inlineItemStyles = {
    flex: '1',
    padding: '10px',
    borderRadius: '4px',
    marginRight: '10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}

const checkboxContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    boxSizing: 'border-box',
}

const checkboxLabelStyles = {
    marginLeft: '5px',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
}

const Body = ({
                  bodyText,
                  bodyStyles,
                  messageInput,
                  ratingInput,
                  handleMessageInput,
                  showNameInput,
                  showMessageInput,
                  showRatingInput,
                  showEmailInput,
                  numberOfStars,
                  handleCheckboxChange
              }) => {
    const { user } = useAuth();

    useEffect(() => {
        handleMessageInput('name', user?.user?.name || '')
        handleMessageInput('email', user?.user?.email || '')
    }, [user]);

    return (
        <div style={bodyStyles}>
            <div style={defaultMessageStyles}>
                <p>{bodyText}</p>
            </div>
            {(showNameInput || showEmailInput) && (
                <div style={inlineDisplayStyles}>
                    {showNameInput && (
                        <div style={inlineItemStyles}>
                            Hi <strong>{user?.user?.name || 'N/A'}</strong>, Got Some Feedback?
                        </div>
                    )}
                    <div style={checkboxContainerStyles}>
                        <label style={checkboxLabelStyles}>
                            <input
                                type="checkbox"
                                name="bug"
                                onChange={e => handleCheckboxChange('isBug', e.target.checked)}
                            />
                            &nbsp;Bug
                        </label>
                        <label style={checkboxLabelStyles}>
                            <input
                                type="checkbox"
                                name="suggestion"
                                onChange={e => handleCheckboxChange('isSuggestion', e.target.checked)}
                            />
                            &nbsp;Suggestion
                        </label>
                    </div>
                </div>
            )}
            {showMessageInput &&
                <div>
                    <textarea
                        rows="5"
                        value={messageInput}
                        placeholder="Enter Your Feedback"
                        onChange={e => handleMessageInput('message', e.target.value)}
                        style={defaultMessageBoxStyles}
                    />
                </div>
            }
            {showRatingInput &&
                <div style={defaultMessageStyles}>
                    <StarRatings
                        rating={ratingInput}
                        starRatedColor="blue"
                        changeRating={newRating => handleMessageInput('rating', newRating)}
                        numberOfStars={numberOfStars}
                        name='rating'
                        starDimension="35px"
                        starSpacing="10px"
                    />
                </div>
            }
        </div>
    );
}

Body.defaultProps = {
    bodyText: '',
    bodyStyles: defaultBodyStyles,
    showEmailInput: true,
    showRatingInput: true,
    showMessageInput: true,
    showNameInput: true,
    numberOfStars: 5
}

export default Body;