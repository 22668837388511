import React, {useEffect, useState} from 'react';
import UserCreatedModal from '../../modals/UserCreatedModal';
import UserList from './UserList';
import '../../css/Users.css';
import UserFormModal from '../../modals/UserFormModal';
import LoadingSpinner from '../../utils/LoadingSpinner';


const BASE_URL = 'https://www.mookimoo.com/mookmanage';

const Users = () => {
    const [isUserFormModalOpen, setIsUserFormModalOpen] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        projectName: '',
        projectAge: '',
        profileImage: '',
    });
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [modalData, setModalData] = useState({ show: false, content: null });
    // const handleUserSubmit = (e, userData) => {
    //     e.preventDefault();
    //     // Submit user data logic
    //     setIsUserFormModalOpen(false);
    // };
    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        // Reset userData to its initial state
        setUserData({
            name: '',
            email: '',
            password: '',
            projectName: '',
            projectAge: '',
            profileImage: '',
        });
    };

    useEffect(() => {
        fetch(`${BASE_URL}/api/users`)
            .then(response => response.json())
            .then(data => setUsersList(data))
            .catch(error => console.error('Error fetching users:', error));
    }, []);



    const handleUserSubmit = async (e, userData) => {
        e.preventDefault();
        setIsLoading(true);

        // Create a copy of userData excluding images
        const userDataWithoutImages = {
            ...userData,
            projects: userData.projects.map(({ name, age }) => ({ name, age }))
        };

        try {
            // Step 1: Create the user and projects (excluding images)
            const createUserResponse = await fetch(`${BASE_URL}/api/users/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userDataWithoutImages),
            });

            const createUserResult = await createUserResponse.json();
            if (!createUserResponse.ok) {
                throw new Error(createUserResult.message || 'User creation failed');
            }

            // Step 2: After receiving project IDs, upload images
            for (let i = 0; i < userData.projects.length; i++) {
                const project = userData.projects[i];
                if (project.projectPic) {
                    const projectId = createUserResult.projectIds[i];
                    await uploadProjectImage(projectId, project.projectPic);
                }
            }

            // Handle success
            setModalData({ show: true, content: userData });
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error:', error);
            setModalData({ show: true, content: { error: error.message } });
            setIsModalOpen(true);
        } finally {
            setIsLoading(false);
            setIsUserFormModalOpen(false);
        }
    };

    const uploadProjectImage = async (projectId, imageFile) => {
        const formData = new FormData();
        formData.append('file', imageFile);

        await fetch(`${BASE_URL}/upload/${projectId}`, {
            method: 'POST',
            body: formData,
        });
        // Handle the response as needed
    };



    return (
        <div className="users-page">
            {isLoading && (
                <LoadingSpinner>
                    <p className={'loading-text'}>Creating Database And Images, please wait...</p>
                    {/* You can add more JSX elements here if needed */}
                </LoadingSpinner>
            )}
            <button
                className="create-user-button"
                onClick={() => setIsUserFormModalOpen(true)}
                disabled={isLoading} // Disable button when loading
            >
                Create New User
            </button>
            {isUserFormModalOpen && (
                <UserFormModal
                    setUploadedFileName={setUploadedFileName}
                    onSubmit={handleUserSubmit}
                    onClose={() => setIsUserFormModalOpen(false)}
                />
            )}
            {isModalOpen && (
                <UserCreatedModal userData={modalData.content} onClose={handleModalClose}  />
            )}
            {/* User List */}
            <div className="user-list">
                <h2>User List</h2>
                {usersList.length > 0 ? (
                    <UserList></UserList>
                ) : (
                    <p>No users found.</p>
                )}
            </div>
        </div>
    );
}

export default Users;
