import React, { useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import '../../css/NewsletterEditor.css';
import { debounce } from "chart.js/helpers";

const defaultTemplate = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>MookiMoo App Update</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f0f0f0;
        }
        .container {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
            overflow: hidden;
        }
        .header {
            background-color: tomato;
            color: #ffffff;
            padding: 20px;
            text-align: center;
            border-radius: 8px;
        }
        .content {
            padding: 20px;
        }
        .content h2 {
            color: #333333;
        }
        .content p {
            color: #666666;
            line-height: 1.5;
        }
        .content .roadmap {
            margin-top: 20px;
            padding: 20px;
            background-color: #f9f9f9;
            border-radius: 5px;
        }
        .content .roadmap h3 {
            margin-top: 0;
        }
        .footer {
            background-color: #eeeeee;
            color: #999999;
            text-align: center;
            padding: 10px;
        }
        .subtext {
            font-size: 14px;
            color: #777777;
        }
        .button {
            width: 20%;
            display: inline-block;
            padding: 10px 20px;
            margin: 5px;
            font-size: 16px;
            color: #ffffff;
            background-color: #B2C58F;
            border: none;
            border-radius: 5px;
            text-decoration: none;
            text-align: center;
        }
        .anon {
            font-size: smaller;
            color: #aaa
        }
    </style>
</head>
<body>
<div class="container">
<div class="content">
    <div class="header">
        <h1>MookiMoo App Update</h1>
    </div>
    <div>
        <h2>Hello MookiMoo Beta Testers,</h2>
        <p>We are excited to bring you the latest updates to the MookiMoo app. Your feedback has been invaluable in helping us improve the app. Here are some of the new features and improvements in this update:</p>
                <ul>
                    <li>Update 1</li>
                    <p class="subtext">Description</p>
                </ul>

            <ul>
                <li>Update 2</li>
                <p class="subtext">Description</p>
            </ul>
    <div class="roadmap">
        <h3>What's Coming Up</h3>
        <p>We are continuously working on new features and improvements. Here's a sneak peek at what's on our roadmap:</p>
        <ul>
            <li>Item 1</li>
            <p class="subtext">Description.</p>
            <li>Item 2</li>
            <p class="subtext">Description.</p>
            <li>Item 3</li>
            <p class="subtext">Description.</p>
            <div>
                <p>What do you think of this idea? <span class="anon">All Voting is Anaonymous</span></p>
                <a href="https://www.mookimoo.com/webform/vote?reason=Social Storys&vote=like" class="button">Like</a>
                <a href="https://www.mookimoo.com/webform/vote?reason=Social Storys&vote=neutral" class="button">Neutral</a>
                <a href="https://www.mookimoo.com/webform/vote?reason=Social Storys&vote=dislike" class="button">Not Needed</a>
            </div>
        </ul>
    </div>
    <p>Thank you for your continued support and feedback. Together, we are making MookiMoo better every day.</p>
    <p>Best regards,<br>The MookiMoo Team</p>
</div>
<div class="footer">
    <p>&copy; 2024 MookiMoo. All rights reserved.</p>
    <p>Questions? Visit our <a href="https://www.mookimoo.com">site</a>.</p>
</div>

</div>
</body>
</html>`;

const NewsletterEditor = () => {
    const editorRef = useRef(null);
    const [content, setContent] = useState(defaultTemplate);
    const [version, setVersion] = useState('');
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [customTag, setCustomTag] = useState('');

    const predefinedTags = ['Newsletter', 'Update', 'General', 'Voting'];

    const fetchVersions = async () => {
        try {
            const response = await axios.get('https://www.mookimoo.com/mookmanage/newsletter/versions');
            if (response.data) {
                setVersions(response.data);
            }
        } catch (error) {
            console.error('Error fetching newsletter versions:', error);
        }
    };

    useEffect(() => {
        fetchVersions();
    }, []);

    const saveAndSendNewsletter = async () => {
        const savedNewsletter = await saveNewsletter(); // Save the newsletter first
        if (savedNewsletter) {
            try {
                const editedContent = editorRef.current.getContent();
                const fullContent = defaultTemplate.replace(
                    /<body>[\s\S]*<\/body>/,
                    `<body>${editedContent}</body>`
                );
                await axios.post('https://www.mookimoo.com/mookmanage/send-newsletter', {
                    content: fullContent,
                    tags: selectedTags
                });
                alert('Newsletter saved and sent successfully!');
            } catch (error) {
                console.error('Error sending newsletter:', error);
                alert('Failed to send newsletter.');
            }
        }
    };

    const debouncedSetContent = useRef(debounce((newContent) => {
        setContent(newContent);
    }, 300)).current;

    const saveNewsletter = async () => {
        if (!version) {
            alert('Please enter a version name.');
            return null;
        }

        const editedContent = editorRef.current.getContent();
        const fullContent = defaultTemplate.replace(
            /<body>[\s\S]*<\/body>/,
            `<body>${editedContent}</body>`
        );

        try {
            const response = await axios.post('https://www.mookimoo.com/mookmanage/newsletter/save', {
                content: fullContent,
                version,
                tags: selectedTags
            });
            alert('Newsletter saved successfully!');
            await fetchVersions();

            return response.data;
        } catch (error) {
            console.error('Error saving newsletter:', error);
            alert('Failed to save newsletter.');
            return null;
        }
    };

    const loadVersion = async (version) => {
        try {
            const response = await axios.get(`https://www.mookimoo.com/mookmanage/newsletter/${version}`);
            if (response.data && response.data.content) {
                setContent(response.data.content);
                setSelectedVersion(version);
                setSelectedTags(response.data.tags || []);
            }
        } catch (error) {
            console.error('Error loading newsletter version:', error);
        }
    };

    const resetToDefaultTemplate = () => {
        setContent(defaultTemplate);
        if (editorRef.current) {
            editorRef.current.setContent(defaultTemplate);
        }
        setSelectedTags([]);
    };

    const sendNewsletter = async () => {
        try {
            const editedContent = editorRef.current.getContent();
            const fullContent = defaultTemplate.replace(
                /<body>[\s\S]*<\/body>/,
                `<body>${editedContent}</body>`
            );
            await axios.post('https://www.mookimoo.com/mookmanage/send-newsletter', {
                content: fullContent,
                tags: selectedTags
            });
            alert('Newsletter sent successfully!');
        } catch (error) {
            console.error('Error sending newsletter:', error);
            alert('Failed to send newsletter.');
        }
    };

    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    const getFullContent = () => {
        const editedContent = editorRef.current.getContent();
        return defaultTemplate.replace(
            /<body>[\s\S]*<\/body>/,
            `<body>${editedContent}</body>`
        );
    };

    const handleTagSelection = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(t => t !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const handleCustomTagInput = (e) => {
        setCustomTag(e.target.value);
    };

    const addCustomTag = () => {
        if (customTag && !selectedTags.includes(customTag)) {
            setSelectedTags([...selectedTags, customTag]);
            setCustomTag('');
        }
    };

    return (
        <div className="newsletter-editor">
            <div className="editor-header">
                <div className="version-control">
                    <input
                        type="text"
                        placeholder="Version"
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                    />
                    <select
                        value={selectedVersion}
                        onChange={(e) => loadVersion(e.target.value)}
                    >
                        <option value="">Select a version</option>
                        {versions.map((v) => (
                            <option key={v.version} value={v.version}>
                                {v.version} - {new Date(v.date).toLocaleDateString()}
                            </option>
                        ))}
                    </select>
                    <button onClick={resetToDefaultTemplate}>New</button>
                </div>
                <div className="tag-selection">
                    {predefinedTags.map(tag => (
                        <button
                            key={tag}
                            onClick={() => handleTagSelection(tag)}
                            className={selectedTags.includes(tag) ? 'selected' : ''}
                        >
                            {tag}
                        </button>
                    ))}
                    <input
                        type="text"
                        value={customTag}
                        onChange={handleCustomTagInput}
                        placeholder="Custom tag"
                        onKeyPress={(e) => e.key === 'Enter' && addCustomTag()}
                    />
                    <button onClick={addCustomTag}>+</button>
                </div>
            </div>
            <div className="selected-tags">
                Tags: {selectedTags.join(', ') || 'None'}
            </div>
            <Editor
                apiKey="yh20ny8f8qvf3jaxps91tkvidq3gp23fs4a7vabco31ptnv1"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={content}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                }}
                onEditorChange={(newContent) => debouncedSetContent(newContent)}
            />
            <div className="editor-footer">
                <button onClick={saveNewsletter}>Save</button>
                <button onClick={sendNewsletter}>Send</button>
                <button onClick={saveAndSendNewsletter}>Save & Send</button>
                <button onClick={togglePreview}>Preview</button>
            </div>
            {showPreview && (
                <div className="preview-modal">
                    <div className="preview-content" dangerouslySetInnerHTML={{ __html: getFullContent() }} />
                    <button className="close-button" onClick={togglePreview}>Close Preview</button>
                </div>
            )}
        </div>
    );
};

export default NewsletterEditor;
