import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useProject } from '../utils/ProjectContext';
import '../css/ImageCropperModal.css'

const ImageCropperModal = ({ isOpen, onClose, imageUrl, onCrop }) => {
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [imageDimensions, setImageDimensions] = useState(null);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [newFileName, setNewFileName] = useState('');
    const [preparedCropData, setPreparedCropData] = useState(null);

    const cropperAreaRef = useRef(null);
    const { setCropInfo, uploadImageWithCrop, projectName, projectData, updateProjectDataInDB, setProjectData } = useProject();
    const translationX = 0;
    const translationY = 0;

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const cropAreaSize = Math.min(windowWidth, 500);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (imageUrl && cropAreaSize > 0) {
            const img = new Image();
            img.onload = () => {
                const maxWidth = img.naturalWidth;
                const aspectRatio = img.naturalWidth / img.naturalHeight;

                let scaledWidth = maxWidth;
                let scaledHeight = maxWidth / aspectRatio;

                setImageDimensions({
                    width: scaledWidth,
                    height: scaledHeight
                });

                const cropAreaAspectRatio = 1;
                let renderedWidth, renderedHeight;

                if (aspectRatio > cropAreaAspectRatio) {
                    renderedWidth = cropAreaSize;
                    renderedHeight = cropAreaSize / aspectRatio;
                } else {
                    renderedHeight = cropAreaSize;
                    renderedWidth = cropAreaSize * aspectRatio;
                }

                const initialScale = renderedWidth / scaledWidth;
                setScale(initialScale);

                setPosition({
                    x: (cropAreaSize - renderedWidth) / 2,
                    y: (cropAreaSize - renderedHeight) / 2
                });
            };
            img.src = imageUrl;
        }
    }, [imageUrl, cropAreaSize]);

    const calculateRenderedImageSize = (imageWidth, imageHeight, cropAreaSize) => {
        const imageAspectRatio = imageWidth / imageHeight;
        const cropAreaAspectRatio = cropAreaSize / cropAreaSize;

        let renderedImageWidth, renderedImageHeight;

        if (imageAspectRatio > cropAreaAspectRatio) {
            renderedImageWidth = cropAreaSize;
            renderedImageHeight = cropAreaSize / imageAspectRatio;
        } else {
            renderedImageHeight = cropAreaSize;
            renderedImageWidth = cropAreaSize * imageAspectRatio;
        }

        return { renderedImageWidth, renderedImageHeight };
    };

    const calculateCropData = async () => {
        if (!imageDimensions) return null;

        const { width: originalWidth, height: originalHeight } = imageDimensions;
        const { renderedImageWidth, renderedImageHeight } = calculateRenderedImageSize(
            originalWidth,
            originalHeight,
            cropAreaSize
        );

        const currentScale = scale;
        const scaledWidth = originalWidth * currentScale;
        const scaledHeight = originalHeight * currentScale;

        const translationOffsetX = (scaledWidth - originalWidth) / 2;
        const translationOffsetY = (scaledHeight - originalHeight) / 2;

        const totalTranslationX = (translationX * (originalWidth / windowWidth)) - translationOffsetX;
        const totalTranslationY = (translationY * (originalHeight / windowHeight)) - translationOffsetY;

        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

        return {
            file,
            cropData: {
                imageWidth: Math.round(originalWidth),
                imageHeight: Math.round(originalHeight),
                scale: currentScale,
                translationX: Math.round(totalTranslationX),
                translationY: Math.round(totalTranslationY),
                displaySize: { width: 1200, height: 1200 },
            }
        };
    };

    const handleReset = useCallback(() => {
        if (imageDimensions && cropAreaSize > 0) {
            const fitScale = Math.max(
                cropAreaSize / imageDimensions.width,
                cropAreaSize / imageDimensions.height
            );
            setScale(fitScale);

            const scaledWidth = imageDimensions.width * fitScale;
            const scaledHeight = imageDimensions.height * fitScale;

            setPosition({
                x: (cropAreaSize - scaledWidth) / 2,
                y: (cropAreaSize - scaledHeight) / 2
            });
        }
    }, [imageDimensions, cropAreaSize]);

    const handleMouseDown = useCallback((e) => {
        e.preventDefault();
        setIsDragging(true);
        setDragStart({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    }, [position]);

    const handleMouseMove = useCallback((e) => {
        e.preventDefault();
        if (isDragging) {
            const newX = e.clientX - dragStart.x;
            const newY = e.clientY - dragStart.y;
            setPosition({
                x: newX,
                y: newY
            });
        }
    }, [isDragging, dragStart]);

    const handleMouseUp = useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const handleTouchStart = useCallback((e) => {
        e.preventDefault();
        const touch = e.touches[0];
        setIsDragging(true);
        setDragStart({
            x: touch.clientX - position.x,
            y: touch.clientY - position.y
        });
    }, [position]);

    const handleTouchMove = useCallback((e) => {
        e.preventDefault();
        if (isDragging) {
            const touch = e.touches[0];
            const newX = touch.clientX - dragStart.x;
            const newY = touch.clientY - dragStart.y;
            setPosition({
                x: newX,
                y: newY
            });
        }
    }, [isDragging, dragStart]);

    const handleTouchEnd = useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);




    const handleStartRename = async () => {
        // Just open the rename modal - don't calculate crop data yet
        setShowRenameModal(true);
    };

    const handleRenameCancel = () => {
        setShowRenameModal(false);
        setNewFileName('');
    };

    const handleRenameConfirm = async () => {
        if (!newFileName) return;

        try {
            const prepared = await calculateCropData();
            if (!prepared) {
                console.error('Failed to prepare crop data');
                return;
            }

            const { file, cropData } = prepared;
            const fileName = newFileName.toLowerCase().endsWith('.jpg') ? newFileName : `${newFileName}.jpg`;
            const renamedFile = new File([file], fileName, { type: 'image/jpeg' });

            // Close rename modal first
            setShowRenameModal(false);
            setNewFileName('');

            // Upload and wait for response with ID and URL
            const uploadResponse = await uploadImageWithCrop(renamedFile, cropData, projectName);

            if (uploadResponse && uploadResponse.s3Url && uploadResponse.imageId) {
                setCropInfo(cropData);
                // Pass complete info back
                onCrop({
                    s3Url: uploadResponse.s3Url,
                    imageId: uploadResponse.imageId,
                    imageName: fileName
                });
                const newImage = {
                    _id: uploadResponse.imageId,
                    name: uploadResponse.imageName,
                    url: uploadResponse.s3Url
                };

                // Update project data with new image
                const updatedProjectData = {
                    ...projectData,
                    project: {
                        ...projectData.project,
                        data: {
                            ...projectData.project.data,
                            images: [...projectData.project.data.images, newImage]
                        }
                    }
                };
                console.log('UPDATED????', updatedProjectData)
                // Update project context
                setProjectData(updatedProjectData);
                updateProjectDataInDB(updatedProjectData);
            } else {
                console.error('Incomplete upload response');
            }
        } catch (error) {
            console.error('Error processing image:', error);
        }
    };

// Rename modal component remains the same but with better error handling
    const RenameModal = () => (
        <div className="rename-modal-overlay" onClick={(e) => e.stopPropagation()}>
            <div className="rename-modal" onClick={(e) => e.stopPropagation()}>
                <h3>Enter file name</h3>
                <input
                    type="text"
                    value={newFileName}
                    onChange={(e) => {
                        e.stopPropagation();
                        setNewFileName(e.target.value);
                    }}
                    placeholder="Enter file name"
                    className="rename-input"
                    autoFocus
                />
                <div className="rename-buttons">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRenameCancel();
                        }}
                        className="rename-button cancel"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRenameConfirm();
                        }}
                        className="rename-button confirm"
                        disabled={!newFileName}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );



    if (!isOpen) return null;

    return (
        <div className="cropper-modal-overlay">
            <div className="cropper-modal-container">
                <div className="cropper-header">
                    <button className="cropper-button" onClick={onClose}>
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <button className="cropper-button" onClick={handleStartRename}>
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                    </button>
                </div>

                <div className="cropper-content">
                    <div className="cropper-area" ref={cropperAreaRef}>
                        <div className="crop-guide-square"></div>
                        <div
                            className="cropper-image-container"
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseUp}
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                            onDragStart={(e) => e.preventDefault()}
                        >
                            <div
                                className="cropper-background"
                                style={{ backgroundImage: `url(${imageUrl})` }}
                            />
                            <img
                                src={imageUrl}
                                alt="Crop preview"
                                className="cropper-image"
                                draggable="false"
                                style={{
                                    transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="cropper-controls">
                    <div className="control-group">
                        <div className="scale-control-header">
                            <label className="cropper-slider-label">Scale</label>
                            <span className="scale-value">{(scale * 100).toFixed(0)}%</span>
                        </div>
                        <input
                            type="range"
                            min="0.1"
                            max="3.0"
                            step="0.05"
                            value={scale}
                            onChange={(e) => setScale(parseFloat(e.target.value))}
                            className="cropper-slider"
                        />
                    </div>

                    <div className="position-controls">
                        <div className="position-control-item">
                            <label className="position-label">X Offset</label>
                            <span className="control-value">{Math.round(position.x)}px</span>
                        </div>
                        <div className="position-control-item">
                            <label className="position-label">Y Offset</label>
                            <span className="control-value">{Math.round(position.y)}px</span>
                        </div>
                    </div>
                </div>
                {showRenameModal && <RenameModal />}
            </div>
        </div>
    );
};

export default ImageCropperModal;