import React, { useCallback, useEffect } from 'react';
import '../../css/LibraryManager.css';
import { useDropzone } from 'react-dropzone';

const BASE_URL = 'https://www.mookimoo.com/mookmanage'

const LibraryManager = () => {
    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);

        fetch(`${BASE_URL}/api/upload`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                // Handle response here. E.g., show a message or refresh the gallery.
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const ImageGallery = () => {
        const [images, setImages] = React.useState([]);

        useEffect(() => {
            fetch(`${BASE_URL}/api/images`)
                .then(response => response.json())
                .then(data => {
                    const imagePairs = data.reduce((pairs, image) => {
                        // Extract base filename without extension
                        const baseName = image.name.replace(/\.[^/.]+$/, '');
                        const displayName = image.name.replace(/\.[^/.]+$/, '').split('/').pop();

                        // Skip if it's a thumbnail
                        if (baseName.endsWith('-thumb')) {
                            return pairs;
                        }

                        // Find the thumbnail image
                        const thumbnailName = `${baseName}-thumb`;
                        const thumbnailImage = data.find(img => img.name.startsWith(thumbnailName));

                        pairs.push({
                            name: displayName,
                            full: image.url,
                            thumb: thumbnailImage ? thumbnailImage.url : null
                        });

                        return pairs;
                    }, []);

                    setImages(imagePairs);
                })
                .catch(error => console.error('Error:', error));
        }, []);

        return (


            <div className="image-gallery">
                {images.map((imagePair, index) => (
                    <div key={index} className="image-pair">
                        <div className="image-frame">
                            <img src={imagePair.thumb} alt={imagePair.name} className="full-image" />
                            {imagePair.thumb && <img src={imagePair.thumb} alt="Thumbnail" className="thumbnail" />}
                        </div>
                        <div className="image-name">{imagePair.name}</div>
                    </div>
                ))}
            </div>
        );
    };




    return (
        <div className="library-manager">
            <h1>Library Manager</h1>
            <ImageGallery></ImageGallery>
            <div {...getRootProps()} className="dropzoner">
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>

        </div>
    );
};


export default LibraryManager;
