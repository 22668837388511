import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import '../css/ImageSelectionModal.css';
import { useProject } from "../utils/ProjectContext";
import { getImage, setImage } from '../utils/idbHelpers';
import Resizer from 'react-image-file-resizer';
import ImageCropperModal from "./ImageCropperModal";


const ImageSelectionModal = ({ isOpen, onClose, onSelectImage, projectId, token }) => {
    const [projectImages, setProjectImages] = useState([]);
    const [imageLibrary, setImageLibrary] = useState([]);
    const [selectedTab, setSelectedTab] = useState('project');
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { fetchProjectDetails, BASE_URL} = useProject();
    const [isCropperOpen, setIsCropperOpen] = useState(false);
    const [imageToProcess, setImageToProcess] = useState(null);
    const { setProjectData, projectData } = useProject();


    const handleStartCropping = () => {
        console.log('Start cropping clicked');
        console.log('Selected image:', selectedImage);
        if (selectedImage) {
            console.log('Setting image to process');
            setImageToProcess(selectedImage);
            setIsCropperOpen(true);
        }
    };

    useEffect(() => {
        console.log('isCropperOpen changed:', isCropperOpen);
        console.log('imageToProcess:', imageToProcess);
    }, [isCropperOpen, imageToProcess]);

    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
                setImageToProcess({ imageUrl: reader.result, file });
                // Don't open cropper immediately, let user confirm the upload first
                setSelectedImage({ imageUrl: reader.result, file });
            };
            reader.readAsDataURL(file);
        }
    }, [token]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    useEffect(() => {
        if (!isOpen) return;

        const fetchProjectImages = async () => {
            try {
                if (projectData && !projectData.project) {
                    console.log('Proj D Then', projectData)
                }
                const imageDetails = projectData.project.data.images;

                const fetchedImages = await Promise.all(
                    imageDetails.map(async (image) => {
                        let cachedImage = await getImage(projectId, image.name);
                        if (cachedImage) {
                            return { ...image, imageUrl: cachedImage };
                        } else {
                            try {
                                const response = await fetch(`${BASE_URL}/project/${projectId}/image/${image.name}`, {
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                    },
                                });
                                const data = await response.json();
                                await setImage(projectId, image.name, data.imageUrl);
                                return { ...image, imageUrl: data.imageUrl };
                            } catch (error) {
                                console.error('Error fetching image:', error);
                                return null;
                            }
                        }
                    })
                );
                setProjectImages([...fetchedImages].reverse().filter(image => image!== null));
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching project images:', error);
                setIsLoading(false);
            }
        };

        const fetchImageLibrary = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/images`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                const imagePairs = data.reduce((pairs, image) => {
                    const baseName = image.name.replace(/\.[^/.]+$/, '');
                    const displayName = image.name.replace(/\.[^/.]+$/, '').split('/').pop();

                    if (baseName.endsWith('-thumb')) {
                        return pairs;
                    }

                    const thumbnailName = `${baseName}-thumb`;
                    const thumbnailImage = data.find(img => img.name.startsWith(thumbnailName));

                    pairs.push({
                        name: displayName,
                        full: image.url,
                        thumb: thumbnailImage ? thumbnailImage.url : null
                    });

                    return pairs;
                }, []);

                setImageLibrary(imagePairs);
            } catch (error) {
                console.error('Error fetching image library:', error);
            }
        };

        fetchProjectImages();
        fetchImageLibrary();
    }, [projectId, token, isOpen]);

    const handleImageClick = (image) => {
        if (selectedTab === 'upload') {
            setSelectedImage(image);
        } else {
            // For library and project images, just select without cropping
            setSelectedImage(selectedImage === image ? null : image);
        }
    };

    const handleAddImage = async () => {
        if (selectedImage) {
            let imageId = null;

            if (selectedTab === 'library') {
                try {
                    // Extract image name from the URL
                    const imageName = selectedImage.full.split('/').pop().split('?')[0];

                    // Copy image from library to project S3 folder
                    const response = await fetch(`${BASE_URL}/copyImageToProject/${projectId}/${imageName}`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    const data = await response.json();

                    if (data.message) {
                        imageId = data.image._id;
                    } else {
                        console.error('Error copying image:', data.error);
                    }
                } catch (error) {
                    console.error('Error copying image:', error);
                }
            } else if (selectedTab === 'upload') {

            } else {
                imageId = selectedImage._id;
            }

            if (imageId) {
                // Pass the image ID to the parent component
                await onSelectImage(imageId);
            }
            onClose();
        }
    };

    const handleCloseCropper = () => {
        setIsCropperOpen(false);
        setImageToProcess(null);
    };

    const handleCropSuccess = async (uploadResponse) => {
        if (uploadResponse && uploadResponse.s3Url && uploadResponse.imageId) {
            try {
                // Get current project data
                const data = projectData;

                // Create new image entry matching project structure
                // const newImage = {
                //     _id: uploadResponse.imageId,
                //     name: uploadResponse.imageName,
                //     url: uploadResponse.s3Url
                // };
                //
                // // Update project data with new image
                // const updatedProjectData = {
                //     ...data,
                //     project: {
                //         ...data.project,
                //         data: {
                //             ...data.project.data,
                //             images: [...data.project.data.images, newImage]
                //         }
                //     }
                // };
                //
                // // Update project context
                // await setProjectData(updatedProjectData);

                // Close modals
                setIsCropperOpen(false);
                setImageToProcess(null);
                onClose();

                // Pass the ID up to parent
                if (onSelectImage) {
                    await onSelectImage(uploadResponse.imageId);
                }
            } catch (error) {
                console.error('Error updating project data:', error);
            }
        }
    };


    const handleCropCancel = () => {
        // User cancelled the crop operation
        setIsCropperOpen(false);
        setImageToProcess(null);
        // Don't close the selection modal - let them pick something else
    };


    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    if (!isOpen) return null;


    return (
        <>
            <div className="modal-overlay">
                <div className="image-selection-modal">
                    <div className="modal-content">
                        <span className="close-button" onClick={onClose}>&times;</span>
                        <h2>Select Image</h2>
                        <div className="image-selection-tabs">
                            <button
                                className={selectedTab === 'project' ? 'active' : ''}
                                onClick={() => setSelectedTab('project')}
                            >
                                Project Images
                            </button>
                            <button
                                className={selectedTab === 'library' ? 'active' : ''}
                                onClick={() => setSelectedTab('library')}
                            >
                                Image Library
                            </button>
                            <button
                                className={selectedTab === 'upload' ? 'active' : ''}
                                onClick={() => setSelectedTab('upload')}
                            >
                                Upload New Image
                            </button>
                        </div>
                        <div className="image-selection-grid">
                            {isLoading ? (
                                <p>Loading images...</p>
                            ) : selectedTab === 'upload' ? (
                                <div className="upload-section">
                                    <div {...getRootProps()} className={`dropzone ${previewImage ? 'hidden' : ''}`}>
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <p>Drop the image here...</p>
                                        ) : (
                                            <p>Drag and drop an image here, or click to select</p>
                                        )}
                                    </div>
                                    {previewImage && (
                                        <div className="upload-image-preview">
                                            <img src={previewImage} alt="Preview" className="preview-image" />
                                            <div className="preview-controls">
                                                <button onClick={() => {
                                                    setPreviewImage(null);
                                                    setSelectedImage(null);
                                                    setImageToProcess(null);
                                                }} className="image-remove-button">
                                                    X
                                                </button>
                                                <button onClick={handleStartCropping} className="crop-button">
                                                    Accept
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                // Project and Library views
                                <div className="image-grid">
                                    {selectedTab === 'project' ? (
                                        projectImages.map((image, index) => (
                                            <div key={index} className="image-container">
                                                <img
                                                    src={image.imageUrl}
                                                    alt={`Project Image ${index}`}
                                                    className={`selection-image ${selectedImage === image ? 'selected' : ''}`}
                                                    onClick={() => handleImageClick(image)}
                                                />
                                                {selectedImage === image && (
                                                    <button onClick={handleAddImage} className="add-image-button">
                                                        Add Image
                                                    </button>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        imageLibrary.map((image, index) => (
                                            <div key={index} className="image-container">
                                                <img
                                                    src={image.thumb}
                                                    alt={`Library Image ${index}`}
                                                    className={`selection-image ${selectedImage === image ? 'selected' : ''}`}
                                                    onClick={() => handleImageClick(image)}
                                                />
                                                {selectedImage === image && (
                                                    <button onClick={handleAddImage} className="add-image-button">
                                                        Add Image
                                                    </button>
                                                )}
                                            </div>
                                        ))
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {isCropperOpen && (
                <div className="cropper-container">
                    <ImageCropperModal
                        isOpen={true}
                        onClose={handleCropCancel}
                        imageUrl={imageToProcess?.imageUrl || imageToProcess?.full || imageToProcess?.imageUrl}
                        onCrop={handleCropSuccess}
                        projectId={projectId}
                    />
                </div>
            )}
        </>
    );
};


export default ImageSelectionModal;
