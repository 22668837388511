import React from 'react';
import '../css/UserCreatedModal.css';

const UserCreatedModal = ({ userData, onClose }) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <h2>User Creation</h2>
                {userData && Object.entries(userData).map(([key, value]) => {
                    if (key === 'projects' && Array.isArray(value)) {
                        return value.map((project, index) => (
                            <div key={index}>
                                <h3>Project {index + 1}</h3>
                                <p>Name: {project.name}</p>
                                <p>Age: {project.age}</p>
                                {project.projectPic && typeof project.projectPic === 'string' && (
                                    <img src={project.projectPic} alt={`Project ${index + 1}`} />
                                )}
                            </div>
                        ));
                    } else {

                        return <p key={key}><strong>{key}:</strong> {value}</p>;
                    }
                })}
                <button className="modal-close-btn" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default UserCreatedModal;
