import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/dash/Dashboard';
import LibraryManager from './components/admin/LibraryManager';
import Users from './components/admin/Users';
import Navbar from './components/navigation/Navbar';
import NewsletterEditor from './components/admin/NewsletterEditor';
import ProtectedRoute from './utils/ProtectedRoute';
import { AuthProvider, useAuth } from './utils/AuthContext';
import { ProjectProvider } from './utils/ProjectContext';
import Login from './components/login/Login';
import ChoiceEditor from './components/choices/ChoiceEditor';
import ProjectImages from './components/projectImages/ProjectImages';
import PlanEditor from "./components/plans/PlannerEditor";
import './App.css';
import ThisThenEditor from "./components/thisThen/ThisThenEditor";
import SessionChart from "./components/admin/SessionChart";
import SocialStoriesEditor from "./components/social/SocialStoriesEditor";
import LandingPage from "./components/landing/LandingPage";
import { ThemeProvider } from './utils/ThemeProvider';
import Register from './components/login/Register';
import ContactForm from "./components/login/ContactForm";
import ReportsPage from './components/reports/ReportsPage';
import AboutUs from './components/login/AboutUs';
import Info from './components/login/Info';
import FeedBack from './utils/feedback/Feedback';
import ComingSoon from './components/landing/CominSoon';
import Bugs from "./components/bugs/Bugs";
import Home from "./components/home/Home";

// Create a new component that combines AuthProvider and ProjectProvider
const AuthenticatedApp = () => {
    const { user } = useAuth();

    return (
        <ProjectProvider user={user}>
            <Router basename="/">
                <div>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<ComingSoon />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/contact" element={<ContactForm />} />
                        <Route path="/register" element={<Register />} />
                        {/*<Route path="/info" element={<Info />} />*/}
                        {/*<Route path="/about" element={<AboutUs />} />*/}

                        <Route element={<ProtectedRoute allowedRoles={['admin', 'user']} />}>
                            <Route path="/home" element={<Home />} />
                            <Route path="/dash" element={<Dashboard />} />
                            <Route path="/choiceEditor/:projectId" element={<ChoiceEditor />} />
                            <Route path="/projectImages/:projectId" element={<ProjectImages />} />
                            <Route path="/socialstories/:projectId" element={<SocialStoriesEditor />} />
                            <Route path="/planEditor/:projectId" element={<PlanEditor />} />
                            <Route path="/firstThenEditor/:projectId" element={<ThisThenEditor />} />
                            <Route path="/sessionStats/:projectId" element={<SessionChart />} />
                            <Route path="/reports" element={<ReportsPage />} />
                            <Route path="/bugs" element={<Bugs />} />
                        </Route>
                        <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
                            <Route path="/library-manager" element={<LibraryManager />} />
                            <Route path="/users" element={<Users />} />
                            <Route path="/newsletter" element={<NewsletterEditor />} />
                        </Route>
                    </Routes>
                    {user && <FeedBack />}
                </div>
            </Router>
        </ProjectProvider>
    );
};

function App() {
    return (
        <ThemeProvider>
            <AuthProvider>
                <AuthenticatedApp />
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;