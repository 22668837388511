import React from 'react';
import { createPortal } from 'react-dom';

const Modal = ({ children, onClose }) => {
    return createPortal(
        <>
            {/* Inline styles for modal-overlay */}
            <div
                className="modal-overlay"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'red', // Dark background
                    zIndex: 999
                }}
                onClick={onClose}
            ></div>

            {/* Inline styles for modal-content */}
            <div
                className="modal-content"
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',

                    zIndex: 1000,
                    maxWidth: '600px',
                    width: '90%',
                    maxHeight: '80vh',
                    overflowY: 'auto'
                }}
            >
                {children}
            </div>
        </>,
        document.body
    );
};

export default Modal;
